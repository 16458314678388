import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IconButton, AppBar, Toolbar, Button, ListItem, Paper, Link, List, DialogActions, Typography, DialogContent, ListItemText, Switch, ListSubheader, Avatar, colors, Badge, DialogTitle, ListItemButton, Fab } from '@mui/material';
import ShoppingBag2LineIcon from 'remixicon-react/ShoppingBag2LineIcon';
import AddCircleLineIcon from 'remixicon-react/AddCircleLineIcon';
import IndeterminateCircleLineIcon from 'remixicon-react/IndeterminateCircleLineIcon';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import EmptyBag from "../../assets/empty bag.png";
import { isMobile } from 'react-device-detect';
import { itemaction } from '../../_actions/item.action';
import { useNavigate } from 'react-router-dom';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const CartSidebar = (props) => {
    
    const EmptyCart = props.total_qty > 0 ? false :true


  const navigate = useNavigate();

  const addRemoveItemCart = (event, type, data) => {
    props.dispatch(itemaction.add_remove_item_cart(type, data));
  }

  const clearCart = (event) => {
    event.preventDefault();
    props.dispatch(itemaction.add_remove_item_cart('REMOVEALL', {}));
  }

  const handleClickOpen = () => {
    props.dispatch(itemaction.open_cart_view(true));
  };

  const handleClose = () => {
    props.dispatch(itemaction.open_cart_view(false));
  };

  const onClickProcessedToPay = (event) => {
    navigate('/cart');
    handleClose()
  }

    return (
        <div>
            {isMobile ? <Fab color="" aria-label="add">
                <AddCircleLineIcon />
            </Fab>
                :
                <IconButton type="button" sx={{}} aria-label="search" onClick={handleClickOpen}>
                    <Badge badgeContent={props.total_qty} color="error" slotProps={{ style: { top: 8, right: 8 } }}>
                        <ShoppingBag2LineIcon className='text-dark' />
                    </Badge>
                </IconButton>
            }

            <Dialog
                scroll="paper"
                PaperProps={{
                    style: {
                        position: 'fixed',
                        top: 0,
                        right: 0,
                        margin: 0,
                        bottom: 0,
                        maxHeight: "100%",
                        borderRadius: 0
                    },
                }}

                TransitionComponent={Transition}
                open={props.is_open_cart_view}
                // onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                fullWidth
                maxWidth={'xs'}
            >
                <DialogTitle className='p-0'>
                    <AppBar className='w-100 p-0' sx={{ position: 'relative' }} elevation={0}>
                        <Toolbar>
                            <Typography sx={{ flex: 1, }} className='fw-normal' variant="h6" component="div">
                                My Bag
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                </DialogTitle>

                <DialogContent className='position-relative' dividers>
                    <List
                        sx={{ width: '100%', bgcolor: 'background.paper' }}

                    >
                    </List>
                    {EmptyCart ? <div className='text-center h-100 overflow-hidden d-flex align-items-center justify-content-center flex-column'>
                        <img src={EmptyBag} className='w-50 mx-auto my-2' />
                        <p className=''>Looks like your bag is empty. Needs a Dose of Goodness! 💊</p>
                    </div>

                        :
                        <>
                            {props.cart && props.cart.map((item) => (
                                <div className='cursor-pointer d-flex align-items-start justify-content-start flex-row rounded-0 border-0 border-bottom py-2 card'>
                                    <img src={item.feature_image} className='rounded-2 border' style={{ width: 100 }} />
                                    <div className='flex-grow-1 align-items-start justify-content-between flex-column d-flex p-2 h-100'>
                                        <h6 className='two-line mb-1'>{item.item_name}</h6>
                                        <p className='two-line mb-1 text-muted'>{"₹ "+item.mrp}</p>
                                        <div className='d-flex flex-row align-items-center'>
                                            <IconButton aria-label="delete">
                                                <IndeterminateCircleLineIcon onClick={(event) => addRemoveItemCart(event, 'REMOVE', item)}  />
                                            </IconButton>
                                            <p className='mb-0 mx-2'>{item.qty}</p>
                                            <IconButton aria-label="remove">
                                                <AddCircleLineIcon onClick={(event) => addRemoveItemCart(event, 'ADD', item)} />
                                            </IconButton>
                                        </div>
                                    </div>

                                    <div className='flex-grow-1 align-items-end justify-content-between flex-column d-flex p-2 h-100'>
                                        <p className='text-muted mb-3'>{item.value+" "+item.unit}</p>
                                        <Button variant="text" color='error' className='' onClick={(event) => addRemoveItemCart(event, 'DELETE', item)} disableElevation>Remove</Button>
                                    </div>
                                </div>
                            ))}
                        </>
                    }


                </DialogContent>


                <DialogActions className='d-flex flex-column'>
                    {EmptyCart ? null
                        :
                        <div className='w-100 p-3'>
                            <h6 className='fw-semibold'>Cart Summary</h6>
                            <div className='d-flex justify-content-between text-muted mb-1'><span className='text-start'>Subtotal</span> <span className='text-end'>₹ {props.total_amount}</span></div>
                            <div className='d-flex justify-content-between text-muted mb-1'><span className='text-start'>Shipping Charges</span> <span className='text-end'>₹ 00.00</span></div>
                            <div className='d-flex justify-content-between fw-semibold mb-1'><h6 className='text-start'>Total</h6> <h6 className='text-end'>₹ {props.total_amount}</h6></div>

                        </div>
                    }
                    <div className='d-flex w-100'>
                        
                        {EmptyCart ? null : <Button variant="contained" sx={{ p: 2 }} className='m-1 w-100 fs-6 fw-light' onClick={(event) => { onClickProcessedToPay(event)}} disableElevation>Proceed to pay</Button>}

                        <Button variant="outlined" sx={{ p: 2 }} className='m-1 w-100 fs-6 fw-light' onClick={handleClose} disableElevation>Continue Shopping</Button>
                    </div>
                </DialogActions>

            </Dialog>
        </div>
    );

}

const mapStateToProps = (state, props) => {
  return {
    cart: state.item.cart,
    total_qty: state.item.total_qty,
    amount: state.item.amount,
    delivery_charge: state.item.delivery_charge,
    total_amount: state.item.total_amount,
    free_shipping_order_amount: state.item.free_shipping_order_amount,
    wishlist_item: state.item.wishlist_item,
    pin_cart: state.item.pin_cart,
    customer_access_token: state.authentication.customer_access_token,
    is_special_instruction: state.item.is_special_instruction,
    is_cart_announcement: state.item.is_cart_announcement,
    cart_announcement: state.item.cart_announcement,
    is_open_cart_view: state.item.is_open_cart_view,
  };
}

export default connect(mapStateToProps, null)(CartSidebar);
