import { PaymentConstants } from '../_constants/payment.constants';
import { API_URL, showErrorMessage } from './utility.action'

export const paymentaction = {
    select_delivery_address, change_delivery_address, get_cart_payment,
    select_delivery_timeslot, change_delivery_timeslot, select_store, get_delivery_time_slot,
    change_order_type, get_order_store_list, get_paylater_order_detail,
    get_order_customer_wallet_balance, select_unselect_customer_wallet_balance,
    authenticate_wallet_otp,apply_coupen_code,remove_coupen_code,get_new_delivery_time_slot,select_store_type,special_instructions
};


function get_cart_payment() {

    var access_token = localStorage.getItem('access_token')

    var cart = JSON.parse(localStorage.getItem('cart'));

    const request_data = { "access_token": access_token, "cart": cart }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/order_service/get_cart_payment`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: PaymentConstants.GET_CART_PAYMENT_REQUEST } }
    function success(payload) { return { type: PaymentConstants.GET_CART_PAYMENT_SUCCESS, payload } }
    function failure(payload) { return { type: PaymentConstants.GET_CART_PAYMENT_FAILURE, payload } }
}

function get_delivery_time_slot(order_type) {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token, "order_type": order_type }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/order_service/get_delivery_time_slot`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: PaymentConstants.GET_DELIVERY_TIME_SLOT_REQUEST } }
    function success(payload) { return { type: PaymentConstants.GET_DELIVERY_TIME_SLOT_SUCCESS, payload } }
    function failure(payload) { return { type: PaymentConstants.GET_DELIVERY_TIME_SLOT_FAILURE, payload } }
}

function get_new_delivery_time_slot(order_type) {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token, "order_type": order_type }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/app_order_service/get_delivery_time_slot`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: PaymentConstants.GET_NEW_DELIVERY_TIME_SLOT_REQUEST } }
    function success(payload) { return { type: PaymentConstants.GET_NEW_DELIVERY_TIME_SLOT_SUCCESS, payload } }
    function failure(payload) { return { type: PaymentConstants.GET_NEW_DELIVERY_TIME_SLOT_FAILURE, payload } }
}

function change_order_type(order_type) {
    return {
        type: PaymentConstants.CHANGE_ORDER_TYPE,
        data: order_type
    };
}

function select_delivery_address(data) {
    return {
        type: PaymentConstants.SELECT_DELIVERY_ADDRESS,
        data: data
    };
}

function select_store(data) {
    return {
        type: PaymentConstants.SELECT_STORE,
        data: data
    };
}
function select_store_type(data) {
    return {
        type: PaymentConstants.SELECT_STORE_TYPE,
        data: data
    };
}


function change_delivery_address() {
    return {
        type: PaymentConstants.CHANGE_DELIVERY_ADDRESS,
    };
}

function select_delivery_timeslot(data,is_delivery_rightaway=false,is_delivery_anytime=false) {
    return {
        type: PaymentConstants.SELECT_DELIVERY_TIMESLOT,
        data: data,is_delivery_rightaway,is_delivery_anytime
    };
}

function change_delivery_timeslot() {
    return {
        type: PaymentConstants.CHANGE_DELIVERY_TIMESLOT,
    };
}

function get_order_store_list() {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/order_service/get_order_store_list`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: PaymentConstants.GET_ORDER_STORE_LIST_REQUEST } }
    function success(payload) { return { type: PaymentConstants.GET_ORDER_STORE_LIST_SUCCESS, payload } }
    function failure(payload) { return { type: PaymentConstants.GET_ORDER_STORE_LIST_FAILURE, payload } }
}

function get_order_customer_wallet_balance() {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/wallet_service/get_order_customer_wallet_balance`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: PaymentConstants.GET_ORDER_CUSTOMER_WALLET_BALANCE_REQUEST } }
    function success(payload) { return { type: PaymentConstants.GET_ORDER_CUSTOMER_WALLET_BALANCE_SUCCESS, payload } }
    function failure(payload) { return { type: PaymentConstants.GET_ORDER_CUSTOMER_WALLET_BALANCE_FAILURE, payload } }
}

function select_unselect_customer_wallet_balance() {
    return {
        type: PaymentConstants.SELECT_UNSELECT_CUSTOMER_WALLET_BALANCE,
    };
}

function authenticate_wallet_otp(formdata) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token, "data": formdata }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/authentication_service/authenticate_otp`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    showErrorMessage(response.error_description)
                    dispatch(failure(response))
                }
                else {
                    dispatch(success1(response))
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: PaymentConstants.AUTHENTICATE_WALLET_OTP_REQUEST } }
    function success(payload) { return { type: PaymentConstants.AUTHENTICATE_WALLET_OTP_SUCCESS, payload } }
    function success1(payload) { return { type: PaymentConstants.SELECT_UNSELECT_CUSTOMER_WALLET_BALANCE, payload } }
    function failure(payload) { return { type: PaymentConstants.AUTHENTICATE_WALLET_OTP_FAILURE, payload } }
}

function get_paylater_order_detail(order_id) {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token, "order_id": order_id }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/order_service/get_paylater_order_detail`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: PaymentConstants.GET_PAYLATER_ORDER_DETAIL_REQUEST } }
    function success(payload) { return { type: PaymentConstants.GET_PAYLATER_ORDER_DETAIL_SUCCESS, payload } }
    function failure(payload) { return { type: PaymentConstants.GET_PAYLATER_ORDER_DETAIL_FAILURE, payload } }
}


function apply_coupen_code(discount_code,cart) {

    var access_token = localStorage.getItem('access_token')
    var data ={
        "discount_code": discount_code,"item_list":cart,engine_type:"Web"
    }

    const request_data = { "access_token": access_token,data:data}

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/app_product_service/apply_coupon_code`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: PaymentConstants.APPLY_COUPEN_CODE_REQUEST } }
    function success(payload) { return { type: PaymentConstants.APPLY_COUPEN_CODE_SUCCESS, payload } }
    function failure(payload) { return { type: PaymentConstants.APPLY_COUPEN_CODE_FAILURE, payload } }
}


function remove_coupen_code(){
    return {
        type: PaymentConstants.REMOVE_COUPEN_CODE,
    };
}

function special_instructions(data){
    return {
        type: PaymentConstants.SPECIAL_INSTRUCTIONS,
        data:data
    };
}