import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar, Typography, Button, Chip, Divider, InputBase, Paper, Badge, Card, CardMedia, CardContent, CardActionArea, Link } from '@mui/material';
import TestVideo from "../assets/test_video.mp4"
import FullBanner from "../assets/banner_full.jpg"
import MainHeader from '../components/General/MainHeader';
import TopHeader from '../components/General/TopHeader';
import BannersHome from '../components/LandingPage/BannersHome';
import TopProductsSection from '../components/LandingPage/TopProductsSection';
import AllCategories from '../components/LandingPage/AllCategories';
import BlogSection from '../components/LandingPage/BlogSection';
import Footer from '../components/General/Footer';
import { isMobile } from 'react-device-detect';

class PrivacyPolicy extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (
      <section className={`py-5 ${isMobile ? "pt-0" : null}`}>
        <div className='container'>
          <h2>Privacy Policy</h2>
          <hr></hr>
          <p className="small">Zoetic Ayurvedics knows that you care how information about you is used and shared, and we appreciate your trust that we will do so carefully and sensibly. This notice describes our Privacy Policy. By visiting or using the websites, mobile applications, and other online platforms, tools, and programs we provide (the “Site”), you agree to the practices described in this Privacy Policy. If you do not agree to the practices described in this Privacy Policy, you may not access or use the Site. Please review this Privacy Policy and our Terms of Use Agreement prior to accessing or using the Site.</p>
          <p className="small">In particular, this Privacy Policy contains information about Zoetic Ayurvedics (collectively with our subsidiaries and affiliates, “Zen Wholesale Hub,” “we,” “us” or “our”) commitment to your privacy, including:</p>
          <ul className='small'>
            <li>The information we collect and how we use and disclose it.</li>
            <li>Your rights to access or update your personal information.</li>
          </ul>
          <p className="small">Our reasonable security measures.</p>
          <p className="small">Our Site may include links to other websites. This Privacy Policy does not apply to the websites of other companies, individuals or organizations which may be linked to or from our Site. You should consult those other websites’ privacy policies.</p>

          <h5 className='fw-semibold text-primary text-uppercase'>INFORMATION COLLECTION</h5>
          <p className="small">Zoetic Ayurvedics collects, receives, and stores information that you have provided through the Site. We do so in the following ways:</p>
          <p className="small">When You Sign Up To Receive Texts, Emails or Newsletters: You may supply Zoetic Ayurvedics with your information when you sign up through the Site to receive electronic communications from us. This information may include your name, address, telephone, birth month, email addresses, and web addresses.</p>
          <p className="small">When You Create An Account On The Site: You may supply Zoetic Ayurvedics with your information when you create an account on the Site. This information may include your name, address, telephone numbers, email addresses, birthday, gender, preferred store location, product preferences and price alert information, and social media accounts.</p>
          <p className="small">When You Order From The Site: You may supply Zoetic Ayurvedics with your name, a shipping address, a billing address, a telephone number, an email address, and payment card information when you place orders on the Site. In conjunction with such orders, we may collect your shopping list and order history information.</p>
          <p className="small">Customer Feedback: If you submit feedback, ratings, questions or requests for information about Zoetic Ayurvedics products and services through the Site, we may request your name, mailing address or geographic region, telephone number and email address.</p>
          <p className="small">Surveys and Promotions: From time to time, we may request information from you via surveys or online promotions such as contests and sweepstakes. Participation is completely voluntary; you have a choice whether or not to disclose any information. Information requested may include personal contact information such as your name, date of birth, address, phone number and email address, and information on shopping habits and preferences.</p>

          <p className="small"><span className='fw-semibold text-primary'>Job Applications:</span> If you complete and submit a job application through the Site, we may collect a resume and any other information you submit as part of that application, including your name, email address, desired job title, and location.</p>
          <p className="small">Automatically-Collected Information: We collect information about your use of the Site, including the equipment and software you use to access the Site, information about your internet connection, your IP address, mobile device ID, non-personal information (such as demographic information) and other information collected through cookies, web beacons, referrers, or similar tracking technologies.</p>
          <p className="small"><span className='fw-semibold text-primary'>Cookies :</span> A cookie is a piece of data placed on your hard drive when you visit our Site. Cookies help us track traffic to our Site, which also help us identify what parts of our Site interest visitors most. For example, when someone visits a page within the Sites, a cookie is placed on the user's machine (if the user accepts cookies) or is read if the user has visited the Sites previously. You may set most browsers to notify you if you receive a cookie, or you may choose to block cookies with your browser, but if you do, you may not be able to take full advantage of the Site if you do so. Some of the cookies we use may be flash cookies or Adobe cookies.</p>
          <p className="small"><span className='fw-semibold text-primary'>Web Beacons :</span> Web Beacons (also known as "clear gifs,""web bugs" or "pixel tags") are tiny graphics with a unique identifier, similar in function to cookies, and are used to allow us to count users who have visited certain pages of the Sites and to help determine the effectiveness of promotional or advertising campaigns. In contrast to cookies, which are stored on a user's computer hard drive, web beacons are embedded invisibly on web pages.</p>
          <p className="small">Referrers, Mobile Device ID’s, IP Addresses and Environmental Variables: Zoetic Ayurvedics may also collect information through “referrers,” mobile device IDs, IP addresses and various environmental variables. A “referrer” is information the web browser passes along to Zoetic Ayurvedics web server that references the URL from which you came. A “mobile device ID” is a number associated with an individual smartphone or similar handheld device which is used to identify and communicate with that device. An “IP Address” is a number used by computers on a network to identify your computer so that data can be transmitted to you. An “environmental variable” may include, among other things, the domain from which you access the Internet, the time you accessed our Site, the type of web browser and operating system or platform used, the Internet address or the website you left to visit our Site, the pages you visit while at our Site and the Internet address of the website you then visit.</p>
          <p className="small"><span className='fw-semibold text-primary'>Information From Third Parties :</span> We may also collect any of the above information from third parties, including from our service providers who help us administer some or all of the Site. We may also obtain lists of potential customers from such sources. We also maintain an active presence on social media platforms (including Facebook, Instagram, Twitter, and others), where you are free to post comments and other content about our products and services. We reserve the right to post any such comments and content on our Site and/or on our other social media pages.</p>


          <h5 className='fw-semibold text-primary text-uppercase'>INFORMATION USE AND DISCLOSURE</h5>
          <p className="small">When you supply information to us through the Site, we may use it for any of the following purposes:</p>
          <ul className='small'>
            <li>The express purpose for which it was collected.</li>
            <li>To send you information and/or offers, newsletters, product information, advertisements, or other information about our work or other topics which we believe may be of interest to you, or to invite you to participate in surveys.</li>
            <li>To present the Site and its contents to you.</li>
            <li>To improve the Site or any of our services.</li>
            <li>To obtain demographic information about our users.</li>
            <li>To carry out our obligations and enforce our rights arising from any contracts entered into between you and us.</li>
            <li>To notify you about changes to our Site or any of our services.</li>
            <li>For any other purpose with your consent.</li>
          </ul>
          <p className="small">Zoetic Ayurvedics may disclose your information in the following ways:</p>
          <ul className='small'>
            <li>To third party service providers who support our activities, perform customer research, help us administer the Site, newsletters or databases, or to fulfill and ship your orders. We may also provide information to social media providers to allow delivery of messages about Zoetic Ayurvedics to you via those platforms.</li>
            <li>To gather additional information about our users through commercially-available sources such as data brokers.</li>
            <li>To our subsidiaries and affiliates.</li>
            <li>To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our users is among the assets transferred.</li>
            <li>To carry out authentication with third party websites.</li>
            <li>To fulfill the purpose for which you provide it.</li>
            <li>For any other purpose disclosed by us when you provide the information.</li>
            <li>With your consent.</li>
            <li>If Zoetic Ayurvedics reasonably believes it is required to do so by law.</li>
            <li>If we believe disclosure is necessary or appropriate to protect the rights, property or safety of Zoetic Ayurvedics, our customers or others.</li>
            <li>To enforce or apply our Terms of Use and other agreements.</li>
            <li>To reduce risk of credit or other kind of fraud.</li>
            <li>To comply with a court order, law, or legal process, including to respond to any government or regulatory request.</li>
          </ul>

          <h5 className='fw-semibold text-primary text-uppercase'>CHOICE</h5>
          <p className="small">Zoetic Ayurvedics provides you with the following choices regarding the use of your information:</p>
          <p className="small">We will provide you with reasonable access to your personal information and will take reasonable steps to correct or amend personal information that you demonstrate to be incorrect or incomplete. If you would like to change or modify personal information you have previously provided us, please send an email to <a className='text-decoration-underline' href=''>customerservice@zenwholesalehub.com</a>.</p>
          <p className="small">We may not accommodate a request to change information if we believe the change would violate any law or legal requirement or cause the information to be incorrect.</p>
          <p className="small"><span className='fw-semibold text-primary'>Do-Not-Track Signals :</span> Your browser may allow you to send “do-not-track” signals to websites you visit. The Site does not respond to do-not-track signals.</p>
          <p className="small">If you have any questions about this Privacy Policy or the practices of our Site, please contact <a className='text-decoration-underline' href=''>customerservice@zenwholesalehub.com</a></p>


          <h5 className='fw-semibold text-primary text-uppercase'>NOTIFICATION OF CHANGES</h5>
          <p className="small">We reserve the right to modify this Privacy Policy at any time. If we decide to do so, we will post those changes here and will update the “Last Updated” date at the top of this Privacy Policy. The changes will be effective as of the date of such posting. By using or accessing the Site thereafter, you are deemed to have agreed to and accepted the revised Privacy Policy. If at any point we decide to use Personal Information in a manner materially different from that stated at the time it was collected, we will notify you by way of email or by updating this page. You are responsible for periodically visiting the Site and this Privacy Policy to check for any changes.</p>


          <h5 className='fw-semibold text-primary text-uppercase'>SECURITY</h5>
          <p className="small">No data transmissions over the Internet can be guaranteed to be 100% secure. Consequently, we cannot ensure or warrant the security of any information you transmit to us and you understand that any information that you transfer to Zoetic Ayurvedics is done at your own risk. However, we endeavour to have reasonable security procedures to protect the loss, misuse or alteration of information under our control.</p>


          <h5 className='fw-semibold text-primary text-uppercase'>COMMENTS OR QUESTIONS?</h5>
          <p className="small">If you have questions or comments about our Privacy Policy, please contact us at <a className='text-decoration-underline' href=''>customerservice@zenwholesalehub.com</a></p>
        </div>
      </section>

    );
  }
}

export default PrivacyPolicy;