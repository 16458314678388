import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar, Typography, Button, Chip, Divider, InputBase, Paper, Badge, Card, CardMedia, CardContent, CardActionArea, Link } from '@mui/material';
import RndBanner from "../assets/rnd.jpg"
import { itemaction } from '../_actions/item.action';
import { connect } from 'react-redux';
import ProductsSection from '../components/LandingPage/ProductsSection';

class CategoryPage extends Component {

  constructor(props) {
    super(props);
    let department_id = window.location.pathname.split("/").slice(-1)[0]
    this.state = {
      department_id:department_id,
      type:"department"
    };
  }
  componentDidMount() {
    this.props.dispatch(itemaction.get_item_list(this.props.type, this.state.department_id))
}
  render() {
    return (
      <>
        <section className="" style={{ background: "url(" + RndBanner + ")" }}>
          <div className="container" style={{ paddingTop: 80, paddingBottom: 80 }}>
            <h1 className='display-4 fw-semibold'>{this.props.department_name}</h1>
          </div>
        </section>
        <ProductsSection department_name={this.props.department_name}/>
      </>

    );
  }
}



function mapStateToProps(state) {
  let breadcrumb = state.item.breadcrumb
  let department_name = ""
 
  if(breadcrumb && breadcrumb.slice(-1)[0]){
    department_name = breadcrumb.slice(-1)[0] && breadcrumb.slice(-1)[0].name
  }
  return {
    breadcrumb: state.item.breadcrumb,
    department_name:department_name,
  };
}

export default connect(mapStateToProps, null)(CategoryPage);
