import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar, Typography, Button, Chip, Divider, InputBase, Paper, Badge, Card, CardMedia, CardContent, CardActionArea, Link } from '@mui/material';
import TestVideo from "../assets/test_video.mp4"
import FullBanner from "../assets/banner_full.jpg"
import MainHeader from '../components/General/MainHeader';
import TopHeader from '../components/General/TopHeader';
import BannersHome from '../components/LandingPage/BannersHome';
import TopProductsSection from '../components/LandingPage/TopProductsSection';
import AllCategories from '../components/LandingPage/AllCategories';
import BlogSection from '../components/LandingPage/BlogSection';
import Footer from '../components/General/Footer';
import { isMobile } from 'react-device-detect';
import RndBanner from "../assets/rnd.jpg"

class AboutUs extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (
      <>
        <section className="" style={{ background: "url(" + RndBanner + ")" }}>
          <div className="container" style={{ paddingTop: 80, paddingBottom: 80 }}>
            <h1 className='display-4 fw-semibold'>About Us</h1>
          </div>
        </section>

        <section className='py-5'>
          <div className='container'>
            <p>Passionate about Ayurveda, we are an innovative company at the forefront of bridging ancient wisdom with cutting-edge science. With our own dedicated research and development wing and a state-of-the-art GMP manufacturing facility, we bring you over 50 meticulously crafted Ayurvedic preparations.</p> 
            <p>At Zoetic, we believe in preserving the authenticity of Ayurveda while harnessing the power of modern manufacturing techniques.</p>
            <p> Our team of expert researchers and Ayurvedic practitioners work tirelessly to develop formulations that seamlessly integrate traditional knowledge with the latest scientific advancements. We take great pride in our commitment to quality and purity. Our manufacturing facility adheres to stringent Good Manufacturing Practices (GMP) to ensure that each product meets the highest standards of safety, efficacy, and consistency. Every step of our production process is carefully monitored and tested to guarantee the integrity of our Ayurvedic preparations. Driven by a vision to promote holistic well-being, our diverse range of products encompasses various aspects of health and wellness. From herbal supplements to skincare solutions, each formulation is crafted with utmost care to provide you with the best Ayurvedic experience. Join us on a journey of rediscovering the wisdom of Ayurveda in a modern context. Together, let's embrace the power of nature and science for a balanced and harmonious life.</p>
          </div>
        </section>
      </>

    );
  }
}

export default AboutUs;