import { GeneralConstants } from '../_constants/general.constants';
import { API_URL, showErrorMessage } from './utility.action'
import { history } from '../_helpers/history';

export const generalaction = {
    get_access_token_byhost, hide_show_department_header,
    get_long_url
};

function get_access_token_byhost(host) {

    const request_data = { "host": host }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };
    return dispatch => {
        try {
        dispatch(request());
        fetch(`${API_URL}/general_service/get_access_token_byhost`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    localStorage.setItem('is_enable_infinite_item_add_to_cart', response.data.is_enable_infinite_item_add_to_cart);
                    localStorage.setItem('access_token', response.data.access_token);
                    dispatch(success(response))
                }
            });
        }catch (error) {
                console.error('Fetch error:', error);
            }
    };
    function request() { return { type: GeneralConstants.GET_ACCESS_TOKEN_BYSUBDOMAIN_REQUEST } }
    function success(payload) { return { type: GeneralConstants.GET_ACCESS_TOKEN_BYSUBDOMAIN_SUCCESS, payload } }
    function failure(payload) { return { type: GeneralConstants.GET_ACCESS_TOKEN_BYSUBDOMAIN_FAILURE, payload } }
}

function hide_show_department_header(type) {
    return {
        type: GeneralConstants.HIDE_SHOW_DEPARTMENT_HEADER,
        data: type
    };
}

function get_long_url(short_code) {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token, "short_code": short_code }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/general_service/get_long_url`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response) {
                    if (response.error_code === "0") {
                        dispatch(failure(response))
                    }
                    else {
                        dispatch(success(response))
                    }
                }
            });
    };
    function request() { return { type: GeneralConstants.GET_LONGURL_REQUEST } }
    function success(payload) { return { type: GeneralConstants.GET_LONGURL_SUCCESS, payload } }
    function failure(payload) { return { type: GeneralConstants.GET_LONGURL_FAILURE, payload } }
}

