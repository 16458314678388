import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { orderaction } from '../../_actions/order.action';
import { IconButton, AppBar, Toolbar, Button, Link } from '@mui/material';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const Orderhistory = ({ order_list, dispatch }) => {
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(orderaction.get_customer_order());
  }, [dispatch]);

  const getOrderDetails = (order_id) => {
    navigate('/orderstatus/'+order_id); 
  }

  return (
    <>
      <div className="container pt-3">
        <div className="row pt-3">
          <div className="col-12 pb-3">My Order</div>
          {order_list && order_list.length > 0 ? (
            order_list.map((data, index) => (
              <div className="col-12 pb-3" key={index}>
                <div className="card shadow-sm">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-4">
                        <small className="text-muted">Order Number</small>
                        <h6>{data.order_no}</h6>
                        <small className="text-muted">Order Date</small>
                        <h6>{moment(data.order_date).format("DD MMM,YYYY h:mm A")}</h6>
                      </div>
                      <div className="col-md-4">
                        <div className="row">
                          <div className="col-6">
                            <small className="text-muted">Total Items</small>
                            <h6>{data.total_qty}</h6>
                            <small className="text-muted">Total Amount</small>
                            <h6>₹ {data.netamount ? data.netamount.toFixed(2) : 0.00}</h6>
                          </div>
                          <div className="col-6">
                            <small className="text-muted">Order Status</small>
                            <h6>{data.order_status}</h6>
                            <small className="text-muted">Payment Status</small>
                            <h6>{data.payment_status}</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3">
                        <div className="row">
                          <div className="col-6">
                            <small className="text-muted">Order Type</small>
                            <h6> {"Door Delivery"}</h6>
                          </div>
                          <div className="col-12 text-end pt-2">
                            <Button
                              onClick={() => { getOrderDetails(data._id) }}
                              variant="outlined"
                              color="primary"
                              style={{ textTransform: "none" }}
                            >
                              View Order Detail
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="col-12 pt-3">
              <h6>
                You have no active orders with us. Time to kick start our relation.{' '}
                <Link to="/">Shop Now</Link>
              </h6>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  order_list: state.order.order_list,
  breadcrumb: state.order.breadcrumb,
});

export default connect(mapStateToProps)(Orderhistory);
