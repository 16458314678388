import { SupportConstants } from '../_constants/support.constants';

export function support(state = {}, action) {
    switch (action.type) {
        default:
            return state
    }
}






