

import { GeneralConstants } from '../_constants/general.constants';


export function general(state = {}, action) {
    switch (action.type) {
        case GeneralConstants.GET_ACCESS_TOKEN_BYSUBDOMAIN_REQUEST:
            return {
                ...state,
                is_valid: null,
            };
        case GeneralConstants.GET_ACCESS_TOKEN_BYSUBDOMAIN_SUCCESS:
            return {
                ...state,
                general_data: action.payload.data,
                live_department_header: action.payload.data.department_header,
                department_header: action.payload.data.department_header,
                is_valid: true,
            };
        case GeneralConstants.GET_ACCESS_TOKEN_BYSUBDOMAIN_FAILURE:
            return {
                ...state,
                is_valid: false,
            };
        case GeneralConstants.HIDE_SHOW_DEPARTMENT_HEADER:
            var department_header = state.live_department_header;
            if (action.data === false) {
                department_header = "none"
            }
            return {
                ...state,
                department_header: department_header
            };
        default:
            return state
    }
}


export function get_long_url(state = {}, action) {
    switch (action.type) {
        case GeneralConstants.GET_LONGURL_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case GeneralConstants.GET_LONGURL_SUCCESS:
            return {
                ...state,

                long_url: action.payload.data.long_url,
            };
        case GeneralConstants.GET_LONGURL_FAILURE:
            return {
                ...state,
                long_url: "404",
            };
        default:
            return state
    }
}




