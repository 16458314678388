import { toast } from 'react-toastify';
export const API_URL = "https://api.zenwholesalehub.in";
// process.env.REACT_APP_API_URL;
export const IMAGE_URL = "https://api.zenwholesalehub.in" + "/images/";
// export const IMAGE_URL = process.env.REACT_APP_API_URL + "/images/";
export const ENGINE_TYPE = process.env.REACT_APP_ENGINE_TYPE;
export const DEVICE_TYPE = process.env.REACT_APP_DEVICE_TYPE;
export const DEVICE_INFO = process.env.REACT_APP_DEVICE_INFO;


export const showErrorMessage = (message, timeout) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
};

export const showSuccessMessage = (message, timeout) => {
    toast.success(message, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
    });
};

