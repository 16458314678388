import {  TextField,RadioGroup,InputBase } from '@mui/material';


export const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <TextField
      input={input}
      label={label}
      error={Boolean(touched && error)}
      helperText={touched && error}
      {...input}
      {...custom}
    />
  )
};

export const renderTextInputBase = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <InputBase
      input={input}
      label={label}
      error={Boolean(touched && error)}
      helperText={touched && error}
      {...input}
      {...custom}
  />
  )
};

export const renderRadioGroup = ({ input, ...rest }) => {
  return (
    <RadioGroup
      {...input}
      {...rest}
      value={input.value}
      onChange={(event, value) => input.onChange(value)}
    />
  )
}