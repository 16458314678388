import { AuthenticationConstants } from '../_constants/authentication.constants';
import { API_URL, showErrorMessage } from './utility.action'
import { history } from '../_helpers/history';

export const authenticationaction = {
    send_mobileno_otp, authenticate_mobileno_otp, get_customer_info, user_logout, clear_authenticate_request,open_login_menu
};


function clear_authenticate_request() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: AuthenticationConstants.CLEAR_AUTHENTICATE_REQUEST } }
}

function open_login_menu(is_open_login_dialog) {
    return {
         type: AuthenticationConstants.OPEN_DIALOG_LOGIN_MENU,
         payload: is_open_login_dialog
     };
 }



function send_mobileno_otp(formdata) {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token, "data": formdata }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/authentication_service/send_mobileno_otp`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: AuthenticationConstants.SEND_MOBILENO_OTP_REQUEST } }
    function success(payload) { return { type: AuthenticationConstants.SEND_MOBILENO_OTP_SUCCESS, payload } }
    function failure(payload) { return { type: AuthenticationConstants.SEND_MOBILENO_OTP_FAILURE, payload } }
}

function authenticate_mobileno_otp(formdata) {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token, "data": formdata }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/authentication_service/authenticate_mobileno_otp`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    localStorage.setItem('customer_access_token', response.data.customer_access_token);
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: AuthenticationConstants.AUTHENTICATE_MOBILENO_OTP_REQUEST } }
    function success(payload) { return { type: AuthenticationConstants.AUTHENTICATE_MOBILENO_OTP_SUCCESS, payload } }
    function failure(payload) { return { type: AuthenticationConstants.AUTHENTICATE_MOBILENO_OTP_FAILURE, payload } }
}

function get_customer_info() {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/authentication_service/get_customer_info`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: AuthenticationConstants.AUTHENTICATE_MOBILENO_OTP_REQUEST } }
    function success(payload) { return { type: AuthenticationConstants.AUTHENTICATE_MOBILENO_OTP_SUCCESS, payload } }
    function failure(payload) { return { type: AuthenticationConstants.AUTHENTICATE_MOBILENO_OTP_FAILURE, payload } }
}

function user_logout() {
    return dispatch => {
        dispatch(request());
    };
    function request() { return { type: AuthenticationConstants.USER_LOGOUT } }
}






