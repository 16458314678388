exports.unit_rate_cal = function (master_qty, master_unit, master_rate, qty, unit) {
    let rate = master_rate;

    if (master_unit === unit) {
        rate = (qty * master_rate) / master_qty
    }
    else {
        if (master_unit === "kg" && unit === "gram") {
            rate = (qty * (master_rate / 1000))
        }
        else if (master_unit === "gram" && unit === "kg") {
            rate = ((qty * 1000) * master_rate) / master_qty
        }
    }
    return parseFloat(rate.toFixed(2))
};

exports.unit_inventory_cal = function (master_qty, master_unit, master_inventory, qty, unit) {
    let inventory = master_inventory;
    if (master_unit === unit) {
        inventory = (master_qty * master_inventory) / qty
    }
    else {
        if (master_unit === "kg" && unit === "gram") {
            inventory = ((1000 / qty) * master_inventory) / master_qty
        }
        else if (master_unit === "gram" && unit === "kg") {
            inventory = (master_qty * master_inventory) / (qty * 1000)
        }
    }
    return Math.floor(inventory)
};

exports.get_order_type = function (order_type) {
    let order_type_name = "";
    if (order_type === "delivery") {
        order_type_name = "Door Delivery";
    }
    else if (order_type === "storepickup") {
        order_type_name = "Store Pickup";
    }
    else if (order_type === "curbsidepickup") {
        order_type_name = "Roadside Car Pickup";
    }
    return order_type_name
};

exports.get_qty_count = function (item_variant) {
    let qty = 0;
    item_variant.forEach(data => {
        if (data.unit === "kg" || data.unit === "gram") {
            let value = data.value
            if (data.unit === "gram") {
                value = data.value / 1000
            }
            qty = qty + (data.qty * value)
        }
        else {
            qty = qty + data.qty
        }
    })
    return parseFloat(qty.toFixed(3))
};

exports.get_consolidated_cart = function (cart) {
    let new_cart = []
    cart.forEach(data => {
        let item_filter = cart.filter(i => i.item_id === data.item_id)
        if (data.is_masterrate === true && item_filter.length > 0) {
            let filter = new_cart.filter(i => i.item_id === data.item_id)
            if (filter.length === 0) {
                let value = this.get_qty_count(item_filter);
                let qty = item_filter.reduce((sum, { qty }) => sum += qty, 0);
                let amount = item_filter.reduce((sum, { amount }) => sum += amount, 0);

                new_cart.push({
                    "item_id": item_filter[0].item_id, "item_name": item_filter[0].item_name, "feature_image": item_filter[0].feature_image, "item_sku": item_filter[0].item_sku,
                    "variant_id": "", "variant_label": item_filter[0].variant_label, "selection_by": item_filter[0].selection_by, "variant_sku": "",
                    "value": value,
                    "unit": data.master_unit, "mrp": item_filter[0].master_mrp, "rate": item_filter[0].master_rate, "qty": qty, "amount": amount, "inventory": data.inventory,
                    "is_masterrate": item_filter[0].is_masterrate, "master_value": item_filter[0].master_value, "master_unit": item_filter[0].master_unit,
                    "master_mrp": item_filter[0].master_mrp, "master_rate": item_filter[0].master_rate, "is_custom_weight": true
                })
            }
        }
        else {
            new_cart.push(data)
        }
    })
    return new_cart
};

exports.get_wallet_reference_type = function (reference_type) {
    let reference_type_name = reference_type;
    if (reference_type === "addmoneyonline") {
        reference_type_name = "Added Money Online";
    }
    else if (reference_type === "addmoneycash") {
        reference_type_name = "Added Money Cash";
    }
    else if (reference_type === "order") {
        reference_type_name = "Paid for Order";
    }
    else if (reference_type === "withdrawmoneycash") {
        reference_type_name = "Withdraw Money Cash";
    }
    else if (reference_type === "promotioncredit") {
        reference_type_name = "Promotion Credit";
    }
    else if (reference_type === "walletactivation") {
        reference_type_name = "Wallet Activation Credit";
    }
    return reference_type_name
};
