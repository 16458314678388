import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import { Carousel } from 'react-responsive-carousel';
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const FullBanner = ({ values }) => {
    const [open, setOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState("");
    const navigate = useNavigate();


    const clickImage = (item) => {
        if (item.urlType === "department") {
            navigate("/dep/" + (item.department.department_name).replace(/[^A-Z0-9]/ig, "-").toLowerCase() + "/" + item.department._id);
        } else if (item.urlType === "items") {
            navigate("/item/" + (item.item.item_name).replace(/[^A-Z0-9]/ig, "-").toLowerCase() + "/" + item.item.item_id);
        } else if (item.urlType === "collection") {
            navigate("/col/" + (item.collection.collection_name).replace(/[^A-Z0-9]/ig, "-").toLowerCase() + "/" + item.collection.collection_id);
        } else if (item.urlType === "promo") {
            if (item.url !== "" && item.url !== undefined && item.url !== null) {
                window.open(item.url)
            }
        } else if (item.urlType === "video") {
            setOpen(true);
            setVideoUrl(item.url);
        }
    }

    const handleClose = () => {
        setOpen(false);
        setVideoUrl("");
    }

    if (!values.imgs) {
        return null;
    }

    const items = values.imgs;
    console.log(items.length)
    return (
        <section className='py-3'>
        <div className='container-fluid px-0 mx-0'>
          <Carousel
            className='Product-carousel'
            autoFocus
            showThumbs={false}
            showIndicators={false}
            infiniteLoop
            // showArrows={false}
            renderArrowPrev={(clickHandler, hasPrev, labelPrev) => {
              return (
                <div
                  className={`${hasPrev ? "position-absolute" : "position-absolute"
                    } top-50 start-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-3`}
                  onClick={clickHandler}
                >
                  <p>{hasPrev}</p>
                  <ArrowBackIosNew className="text-white" />
                </div>
              );
            }}
            renderArrowNext={(clickHandler, hasNext, labelNext) => {
              return (
                <div
                  className={`${hasNext ? "position-absolute" : "position-absolute"
                    } top-50 end-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-3`}
                  onClick={clickHandler}
                >
                  <ArrowForwardIos className="text-white" />
                </div>
              );
            }}

            showStatus={false}
            autoPlay={true}
            interval={1500}
            useKeyboardArrows={true}
            swipeScrollTolerance={0}
            emulateTouch={true}
            swipeable={true}
          // renderArrowNext={() => <IconButton className='control-arrow control-prev' aria-label="delete"><DeleteIcon /></IconButton>}
          >
            {items.map((item, index) => (
                <div style={{ cursor: "pointer" }} className={"pt-2 col-md-12"} key={index} onClick={() => clickImage(item)}>
                    <img src={item.image_url} alt={item.alterText} className='w-100'/>
                </div>
            ))}
          </Carousel>
          <Dialog
            open={open}
            aria-labelledby="item-name-settings"
            maxWidth="md"
            onClose={handleClose}
            fullWidth
        >
            <iframe height={720} frameBorder={0} src={videoUrl}></iframe>
        </Dialog>
        </div>
      </section>
      
      
    );
}

export default FullBanner;