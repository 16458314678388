import React from 'react';
import { connect } from 'react-redux';
import { Button, Link, Paper, InputBase,MenuItem} from '@mui/material';
import { Field, reduxForm, change, formValueSelector, SubmissionError } from 'redux-form'
// import { renderSelectField } from '../../_helpers/reduxFields';
import { IMAGE_URL } from '../../_actions/utility.action';

const populer_netbanking = [
    // { id: "SBIN" },
    // { id: "HDFC" },
    // { id: "ICIC" },
    // { id: "UTIB" },
    // { id: "KKBK" },
    {id:"AIRP"},
    {id:"IDFB"},
    {id:"YESB"},
    {id:"VIJB"},
    {id:"PSIB"}
]

class Razerpay_netbanking extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            NetBankingPaymentMethod: "",
        };
    }

    place_order_netbanking = (values) => {
        if (!values.netbanking) {
            throw new SubmissionError({ netbanking: 'Please select bank' })
        }
        var data = {}
        data.method = 'netbanking';
        data.bank = values.netbanking;
        this.props.place_order(data)
    }

    onNetBankingMethodClick = (netbanking) => {
        console.log(netbanking)
        this.setState({
            NetBankingPaymentMethod:netbanking
        })
        this.props.dispatch(change('payment_form', 'netbanking', netbanking));
    };

    render() {
        console.log(this.props.netbanking_list)
        const { handleSubmit } = this.props;
        return (
            <div className='row'>
                {populer_netbanking.map((val, index) => (
                    <div className='mx-2 cursor-pointer w-fit-content d-flex align-items-center flex-column' key={index} onClick={() => this.onNetBankingMethodClick(val.id)}>
                        <div
                            className={`mb-2 d-flex align-items-center justify-content-center border rounded-4 ${this.state.NetBankingPaymentMethod === val.id && "border-3 border-primary"}`}
                            style={{ height: 72, width: 72 }}
                        >
                            <img
                                src={IMAGE_URL + val.id + ".gif"}
                                className='rounded-pill'
                                width={36}
                                alt=''
                            />
                        </div>
                        <p style={{ width: 80 }} className={`small two-line text-center ${this.state.NetBankingPaymentMethod === val.id && "fw-semibold text-primary"}`}>{this.props.netbanking_list[val.id]}</p>
                    </div>
                ))}
                {this.state.NetBankingPaymentMethod !== "" && <Button variant="contained" sx={{ p: 2 }} onClick={handleSubmit(values => this.place_order_netbanking({ ...values }))} className='fw-light w-100' disableElevation>Place Order & Pay ₹{this.props.total_amount}</Button>}
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        total_amount: state.payment.total_amount,
    };
}

Razerpay_netbanking = reduxForm({
    form: 'payment_form',
    destroyOnUnmount: false,
})(Razerpay_netbanking);

const selector = formValueSelector('payment_form')
Razerpay_netbanking = connect(
    state => {
        const netbanking = selector(state, 'netbanking')
        return {
            netbanking,
        }
    }
)(Razerpay_netbanking)


export default connect(mapStateToProps, null)(Razerpay_netbanking);

