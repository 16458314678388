import React, { Component } from 'react';
import { connect } from 'react-redux';
import Category from '../General/Category';
import { dashboardaction } from '../../_actions/dashboard.action';

class Categories extends Component {

    
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        this.props.dispatch(dashboardaction.get_department_list(""))
    }

    render() {
        return (
            <section className='py-3 Categories'>
                <div className='container'>
                    <h3 className='fw-semibold mb-3'>Shop by Category</h3>

                    <div className='row seven-cols'>
                        {this.props.department_list && this.props.department_list.map((item) => (
                            <Category key={item.department_id} catName={item.department_name} department_id={item.department_id} LCategoryImg={item.department_image}/>

                        ))}
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        department_list: state.dashboard.department_list,
    };
}

export default connect(mapStateToProps)(Categories);
