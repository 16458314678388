

export const AuthenticationConstants = {
    SEND_MOBILENO_OTP_REQUEST: 'SEND_MOBILENO_OTP_REQUEST',
    SEND_MOBILENO_OTP_SUCCESS: 'SEND_MOBILENO_OTP_SUCCESS',
    SEND_MOBILENO_OTP_FAILURE: 'SEND_MOBILENO_OTP_FAILURE',
    AUTHENTICATE_MOBILENO_OTP_REQUEST: 'AUTHENTICATE_MOBILENO_OTP_REQUEST',
    AUTHENTICATE_MOBILENO_OTP_SUCCESS: 'AUTHENTICATE_MOBILENO_OTP_SUCCESS',
    AUTHENTICATE_MOBILENO_OTP_FAILURE: 'AUTHENTICATE_MOBILENO_OTP_FAILURE',
    USER_LOGOUT: 'USER_LOGOUT',
    CLEAR_AUTHENTICATE_REQUEST:'CLEAR_AUTHENTICATE_REQUEST',
    OPEN_DIALOG_LOGIN_MENU:"OPEN_DIALOG_LOGIN_MENU", 
};