import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Paper, InputBase, Button } from '@mui/material';
import { reduxForm, SubmissionError, change, Field } from 'redux-form';
import { renderTextField } from '../_helpers/CustomFields';
import { paymentaction } from '../_actions/payment.action';
import { useNavigate } from 'react-router-dom';
import { authenticationaction } from '../_actions/authentication.action';

const validate = (values) => {
    const errors = {};
    if(!values.email){
        errors.email = 'Please enter Email'
    }
    if(!values.mobileno){
        errors.mobileno ="Please enter Contact Number"
    }
    if (!values.firstname) {
        errors.firstname = 'Please enter first name'
    }
    if (!values.lastname) {
        errors.lastname = 'Please enter last name'
    }
    if (!values.address) {
        errors.address = 'Please enter address'
    }
    if (!values.landmark) {
        errors.landmark = 'Please enter landmark'
    }
    if (!values.area) {
        errors.area = 'Please enter area'
    }
    if (!values.pincode) {
        errors.pincode = 'Please enter pincode'
    }
    if (!values.city) {
        errors.city = 'Please enter city'
    }
    if (!values.state) {
        errors.state = 'Please enter state'
    }
    if (!values.other_address_type) {
        errors.other_address_type = 'Please address type'
    }

    return errors;
}

let  GuestCheckout = ({ handleSubmit,props,dispatch }) => {
    const [state, setState] = useState({});
    const navigate = useNavigate();
  
    useEffect(() => {
    }, [dispatch]);
  
    const onSubmit = (formData) => {
        dispatch(paymentaction.select_delivery_address(formData));
        navigate('/payment');
    };

    const handleClickOpen = () => {
        dispatch(authenticationaction.open_login_menu(true))
    };
  
  
    return (
        <form>
        <div className='mb-3 card rounded-4'>
            <div className='card-body'>
                <div className='d-flex justify-content-between align-items-center mb-3' onClick={() => handleClickOpen()}>
                    <h6 className='fw-semibold mb-0'>Checkout as a guest</h6>
                    <p className='small text-muted mb-0'>have an account? <a className='text-decoration-underline fw-semibold text-primary'>Login Now</a></p>
                </div>
                <Field
                    name="email"
                    id="email"
                    type="text"
                    label="Email Address"
                    placeholder="Email Address"
                    autoFocus
                    className='w-100 mb-3'
                    variant="filled"
                    component={renderTextField}
                    sx={{ flex: 1 }}
                    inputProps={{ 'aria-label': 'search google maps' }}
                />  

                 <Field
                    name="mobileno"
                    id="mobileno"
                    type="text"
                    label="Contact Number"
                    placeholder="Contact Number"
                    autoFocus
                    className='w-100 mb-3'
                    variant="filled"
                    component={renderTextField}
                    sx={{ flex: 1 }}
                    inputProps={{ 'aria-label': 'search google maps' }}
                />  
            </div>
        </div>

        <div className='card rounded-4 mb-3'>
            <div className='card-body'>
                <h6 className='fw-semibold mb-3'>Delivery Details</h6>

                <div className='row'>
                    <div className='col-md-6 mb-3'>
                        <Field
                            name="firstname"
                            id="firstname"
                            type="text"
                            label="First Name"
                            placeholder="First Name"
                            autoFocus
                            className='w-100'
                            variant="filled"
                            component={renderTextField}
                            sx={{ flex: 1 }}
                            inputProps={{ 'aria-label': 'search google maps',maxLength: 10 }}
                        />  
                    </div>
                    <div className='col-md-6 mb-3'>
                        <Field
                            name="lastname"
                            id="lastname"
                            type="text"
                            label="Last Name"
                            placeholder="Last Name"
                            autoFocus
                            className='w-100'
                            variant="filled"
                            component={renderTextField}
                            sx={{ flex: 1 }}
                            inputProps={{ 'aria-label': 'search google maps',maxLength: 10 }}
                        />  
                    </div>

                    <div className='col-md-12 mb-3'>
                        <Field
                            name="address"
                            id="address"
                            type="text"
                            label="Address"
                            placeholder="Address"
                            autoFocus
                            className='w-100'
                            variant="filled"
                            component={renderTextField}
                            sx={{ flex: 1 }}
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />  
                    </div>

                    <div className='col-md-12 mb-3'>
                        <Field
                            name="landmark"
                            id="landmark"
                            type="text"
                            label="Landmark"
                            placeholder="Landmark"
                            autoFocus
                            className='w-100'
                            variant="filled"
                            component={renderTextField}
                            sx={{ flex: 1 }}
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />
                    </div>

                    <div className='col-md-6 mb-3'>
                        <Field
                            name="area"
                            id="area"
                            type="text"
                            label="Area"
                            placeholder="Area"
                            autoFocus
                            className='w-100'
                            variant="filled"
                            component={renderTextField}
                            sx={{ flex: 1 }}
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />  
                    </div>
                    <div className='col-md-6 mb-3'>
                        <Field
                            name="city"
                            id="city"
                            type="text"
                            label="City"
                            placeholder="City"
                            autoFocus
                            className='w-100'
                            variant="filled"
                            component={renderTextField}
                            sx={{ flex: 1 }}
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />
                    </div>

                    <div className='col-md-6 mb-3'>
                        <Field
                            name="pincode"
                            id="pincode"
                            type="text"
                            label="Pincode"
                            placeholder="Pincode"
                            autoFocus
                            className='w-100'
                            variant="filled"
                            component={renderTextField}
                            sx={{ flex: 1 }}
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />  
                    </div>
                    <div className='col-md-6 pb-3'>
                        <Field
                            name="state"
                            id="state"
                            type="text"
                            label="State"
                            placeholder="State"
                            autoFocus
                            className='w-100'
                            variant="filled"
                            component={renderTextField}
                            sx={{ flex: 1 }}
                            inputProps={{ 'aria-label': 'search google maps' }}
                        />
                    </div>

                    <div className='col-md-12'>
                        <Button variant="contained" sx={{ p: 2 }} onClick={handleSubmit(values => onSubmit({ ...values }))} className='w-100 fs-6 fw-light' disableElevation>Proceed to buy</Button>
                    </div>

                </div>
            </div>
        </div>
    </form>
    )
};


function mapStateToProps(state) {
    let initialValues = {}
   
    initialValues = {
        email:"",
        mobileno: "",
        firstname: "",
        lastname: "",
        address: "",
        landmark: "",
        area: "",
        pincode: "",
        city: "Ahmedabad",
        state: "",
        country: "India",
        address_type: "",
        other_address_type: "",
    };

    return {
        initialValues,
    };
};

GuestCheckout = reduxForm({
    form: 'guestcheckoutform',
    validate,
    destroyOnUnmount: false,
  })(GuestCheckout);
  
  export default connect(mapStateToProps, null)(GuestCheckout);