import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopHeader from './TopHeader';
import MainHeader from './MainHeader';
import Footer from './Footer';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { ThemeProvider, createTheme, useTheme, withStyles } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { isMobile } from 'react-device-detect';
import LoginFlow from './LoginFlow';
import Searchbar from './Searchbar';
import { Checkbox, Divider, Fab, ListItemButton, Paper, TextField,Link } from '@mui/material';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import CartSidebar from './CartSidebar';
import ShoppingBag2LineIcon from 'remixicon-react/ShoppingBag2LineIcon';
import CloseFillIcon from 'remixicon-react/CloseFillIcon';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const SuccessAppbar = styled(AppBar)(({ theme }) => ({
    background: theme.palette.common.white,
    color: theme.palette.common.black,
}));


class Layout extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isOpenDrawer: false,
            sideBarDrawer: false,
            checkbox1: false,
            checkbox2: false,
            checkbox3: false,
            value: 0,

        };
    }

    handleChange = (event, newValue) => {
        this.setState({ value: newValue });
    };


    toggleDrawer = () => () => {
        this.setState({ isOpenDrawer: !this.state.isOpenDrawer });
    };

    toggleSidebarDrawer = () => () => {
        this.setState({ sideBarDrawer: true });
    };


    render() {
        const { children, Subtitle, Image, Category, Date } = this.props;
        const { value } = this.state;

        return (
            <div>

                {isMobile ? null : <TopHeader />}
                {isMobile ? null : <MainHeader />}
                {isMobile ? <AppBar position="sticky" elevation={1} className='text-dark border-bottom main-header' style={{ backgroundColor: '#ffffffe2' }}>
                    <Toolbar className='d-flex justify-content-between position-relative py-3'>
                        <IconButton
                            onClick={this.toggleDrawer()}
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer open={this.state.isOpenDrawer} onClose={this.toggleDrawer()} PaperProps={{ style: { width: "30vh" } }}>
                            <List className="pt-0">
                                <ListItem className='bg-primary'>
                                    <ListItemText primaryTypographyProps={{ className: "text-white text-uppercase mb-0" }} primary="Site Menu" className='' />
                                </ListItem>
                                <Divider />
                                <ListItem button>
                                    <Link href="/about-us" className='site_menu_link'>
                                        <ListItemText primary="About Us"/>
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <Link href="/about-us" className='site_menu_link'>
                                        <ListItemText primary="Research & Development" href='/rnd'/>
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <Link href='/quality-control' className='site_menu_link'>
                                        <ListItemText primary="Quality Control"/>
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <Link href='/contact-us' className='site_menu_link'>
                                        <ListItemText primary="Contact Us"/>
                                    </Link>
                                </ListItem>
                                <Divider className='my-2' />
                                <ListItem button>
                                    <ListItemText primary="Help" />
                                </ListItem>
                                {/* <ListItem button>
                                    <ListItemText primary="Track Order" />
                                </ListItem> */}
                                <ListItem button>
                                    <Link href='/orders' className='site_menu_link'>
                                        <ListItemText primary="Orders" />
                                    </Link>
                                </ListItem>
                                <ListItem button>
                                    <ListItemText primary="Offers" />
                                </ListItem>
                            </List>
                        </Drawer>
                       
                        <a href="/" className='mb-0 p-2 position-absolute start-50 translate-middle top-50' style={{ display: { xs: 'none', sm: 'block' } }}>
                            <img src='https://zoeticayurvedics.com/Zoetic/images/logo.png' height={54} />
                        </a>
                        <LoginFlow />
                        {/* <CartSidebar/> */}
                    </Toolbar>

                    <Toolbar className='' >
                        <Searchbar/>
                    </Toolbar>
                </AppBar> : null}


                {isMobile && this.props.total_qty > 0 ? <Paper className='position-fixed p-3 bg-white border-top w-100 rounded-0' style={{ bottom: 0, zIndex: 5000 }} elevation={4}>
                    <Button href='/cart' className='w-100 p-3 rounded-4' variant='contained'>
                        <div className='w-100 d-flex align-items-center justify-content-between'>
                            <div className='d-flex flex-row align-items-center'>
                                <p className='mb-0 text-white fw-medium'>{this.props.total_qty} Items | ₹ {this.props.total_amount}</p>
                                <div className='badge mb-0 bg-success-subtle fw-bold py-2 text-success mx-2'>₹ 0.00 Saved</div>
                            </div>
                            <p className='mb-0 text-white'>View Bag</p>
                        </div>
                    </Button>
                </Paper>
                    :
                    null
                }


                {/* <div style={{paddingTop: isMobile ? "120px" : null}}> */}

                {/* <Button variant="text" onClick={this.toggleSidebarDrawer()}>Button</Button> */}
                <Drawer anchor='right' open={this.state.sideBarDrawer} onClose={() => this.setState({ sideBarDrawer: false })} PaperProps={{ sx: { width: "50vh" } }}>
                    <List className="pt-0">
                        <ListItem className='bg-primary' secondaryAction={<IconButton edge="end" aria-label="delete" className='text-white'><CloseFillIcon /></IconButton>}>
                            <ListItemText primaryTypographyProps={{ className: "text-white text-uppercase mb-0" }} primary="New Role" className='' />
                        </ListItem>
                    </List>

                    <div className='p-3 w-100' >
                        <TextField className='w-100' label="Role Name" variant='outlined' />
                    </div>






                    <Tabs value={value} onChange={this.handleChange} className='border-bottom'>
                        <Tab label="Permissions" />
                        <Tab label="Menu" />
                    </Tabs>

                    <TabPanel value={value} index={0}>
                        <List>
                            <p className='text-muted ps-3 mb-0'>User</p>
                            <ListItem
                                className='w-100'
                                secondaryAction={
                                    <Checkbox
                                        checked={this.state.checkbox1}
                                        onChange={() => this.setState({ checkbox1: !this.state.checkbox1 })}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton onClick={() => this.setState({ checkbox1: !this.state.checkbox1 })}>
                                    <ListItemText primary="Role 1" />
                                </ListItemButton>
                            </ListItem>

                            <ListItem
                                className='w-100'
                                secondaryAction={
                                    <Checkbox
                                        checked={this.state.checkbox2}
                                        onChange={() => this.setState({ checkbox2: !this.state.checkbox2 })}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton onClick={() => this.setState({ checkbox2: !this.state.checkbox2 })}>
                                    <ListItemText primary="Role 2" />
                                </ListItemButton>
                            </ListItem>

                            <ListItem
                                className='w-100'
                                secondaryAction={
                                    <Checkbox
                                        checked={this.state.checkbox3}
                                        onChange={() => this.setState({ checkbox3: !this.state.checkbox3 })}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton onClick={() => this.setState({ checkbox3: !this.state.checkbox3 })}>
                                    <ListItemText primary="Role 3" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                        <List>
                            <p className='text-muted ps-3 mb-0'>Menu</p>
                            <ListItem
                                className='w-100'
                                secondaryAction={
                                    <Checkbox
                                        checked={this.state.checkbox1}
                                        onChange={() => this.setState({ checkbox1: !this.state.checkbox1 })}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton onClick={() => this.setState({ checkbox1: !this.state.checkbox1 })}>
                                    <ListItemText primary="Menu 1" />
                                </ListItemButton>
                            </ListItem>

                            <ListItem
                                className='w-100'
                                secondaryAction={
                                    <Checkbox
                                        checked={this.state.checkbox2}
                                        onChange={() => this.setState({ checkbox2: !this.state.checkbox2 })}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton onClick={() => this.setState({ checkbox2: !this.state.checkbox2 })}>
                                    <ListItemText primary="Menu 2" />
                                </ListItemButton>
                            </ListItem>

                            <ListItem
                                className='w-100'
                                secondaryAction={
                                    <Checkbox
                                        checked={this.state.checkbox3}
                                        onChange={() => this.setState({ checkbox3: !this.state.checkbox3 })}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton onClick={() => this.setState({ checkbox3: !this.state.checkbox3 })}>
                                    <ListItemText primary="Menu 3" />
                                </ListItemButton>
                            </ListItem>
                        </List>
                    </TabPanel>




                </Drawer>
                {children}
                {/* </div> */}
                <Footer />
            </div>
        );
    }
}

function TabPanel(props) {
    const { children, value, index } = props;

    return (
        <div hidden={value !== index}>
            {value === index && <Box>{children}</Box>}
        </div>
    );
}

function mapStateToProps(state) {
    return {
        total_amount:state.item.total_amount,
        total_qty:state.item.total_qty,
    };
}
export default connect(mapStateToProps, null)(Layout);

