

import { ItemConstants } from '../_constants/item.constants';
import global from '../components/global';


export function item(state = {}, action) {
    // console.log('item',action.type)
    switch (action.type) {
        case ItemConstants.GET_ITEM_LIST_REQUEST:
            return {
                ...state,
                item_list: null,
            };
        case ItemConstants.GET_ITEM_LIST_SUCCESS:
            var cart = JSON.parse(localStorage.getItem('cart'));
            if (cart === null) {
                cart = []
            }
            action.payload.data.item_list.forEach(data => {
                data.item_variant.forEach(value => {
                    let filter = cart.filter(i => i.item_id == data.item_id && i.variant_id == value.id)
                    if (filter.length > 0) {
                        if (value.is_custom_weight === true) {
                            value.value = filter[0].value
                        }
                        value.qty = filter[0].qty
                        value.amount = filter[0].amount
                    }
                    else {
                        value.qty = 0
                        value.amount = 0
                    }
                })
                data.item_variant[0].active = true;
                if (action.payload.data.favourite_item_list.includes(data.item_id)) {
                    data.is_favorite = true;
                }
                else {
                    data.is_favorite = false;
                }
            })
            return {
                ...state,
                item_list: action.payload.data.item_list,
                itemlist_type: action.payload.data.itemlist_type,
                breadcrumb: action.payload.data.breadcrumb,
            };
        case ItemConstants.DASHBOARD_GET_ITEM_LIST_SUCCESS:
            var cart = JSON.parse(localStorage.getItem('cart'));
            if (cart === null) {
                cart = []
            }
            action.payload.data.item_list.forEach(data => {
                data.item_variant && data.item_variant.forEach(value => {
                    let filter = cart.filter(i => i.item_id == data.item_id && i.variant_id == value.id)
                    if (filter.length > 0) {
                        if (value.is_custom_weight === true) {
                            value.value = filter[0].value
                        }
                        value.qty = filter[0].qty
                        value.amount = filter[0].amount
                    }
                    else {
                        value.qty = 0
                        value.amount = 0
                    }
                })
                if(data.item_variant && data.item_variant[0]){
                    data.item_variant[0].active = true;
                }
            })
            
            return {
                ...state,
                cart:cart,
                item_list: action.payload.data.item_list,
                total_qty: cart.reduce((sum, { qty }) => sum += qty, 0),
                total_amount: cart.reduce((sum, { amount }) => sum += amount, 0),
                category_wise_item_list:action.payload.data.category_wise_item_list,
                collection_item_list:action.payload.data.collection_item_list
            };
        case ItemConstants.VARIANT_CHANGE:
            state.item_list.forEach(data => {
                if (data.item_id === action.item_id) {
                    data.item_variant.forEach(value => {
                        if (value.id === action.variant_id) {
                            value.active = true
                        }
                        else {
                            value.active = false
                        }
                    })
                }
            })
            return {
                ...state,
                item_list: state.item_list,
            };
        case ItemConstants.GET_ITEM_LIST_FAILURE:
            return {
                ...state,
                item_list: [],
            };
        case ItemConstants.GET_ITEM_DETAIL_REQUEST:
            return {
                ...state,
                item_detail: null,
                item_variant: null,
                item_list: []
            };
        case ItemConstants.GET_ITEM_DETAIL_SUCCESS:
            var cart = JSON.parse(localStorage.getItem('cart'));
            if (cart === null) {
                cart = []
            }
            let item_detail = action.payload.data
            item_detail.item_variant.forEach(value => {
                let filter = cart.filter(i => i.item_id == item_detail.item_id && i.variant_id == value.variant_id)
                if (filter.length > 0) {
                    if (value.is_custom_weight === true) {
                        value.value = filter[0].value
                    }
                    value.qty = filter[0].qty
                    value.amount = filter[0].amount
                }
            })
            return {
                ...state,
                item_detail: item_detail,
                item_variant: item_detail.item_variant,
                breadcrumb: item_detail.breadcrumb,
            };
        case ItemConstants.GET_ITEM_DETAIL_FAILURE:
            return {
                ...state,
                item_detail: {},
            };
        case ItemConstants.GET_ITEM_DETAIL_INCART_EDIT_REQUEST:
            return {
                ...state,
                item_detail_incart: null,
                item_variant_incart: null,
            };
        case ItemConstants.GET_ITEM_DETAIL_INCART_EDIT_SUCCESS:
            var cart = JSON.parse(localStorage.getItem('cart'));
            if (cart === null) {
                cart = []
            }
            let item_detail_incart = action.payload.data
            item_detail_incart.item_variant.forEach(value => {
                let filter = cart.filter(i => i.item_id == item_detail_incart.item_id && i.variant_id == value.variant_id)
                if (filter.length > 0) {
                    if (value.is_custom_weight === true) {
                        value.value = filter[0].value
                    }
                    value.qty = filter[0].qty
                    value.amount = filter[0].amount
                }
            })
            return {
                ...state,
                item_detail_incart: item_detail_incart,
                item_variant_incart: item_detail_incart.item_variant,
            };
        case ItemConstants.GET_ITEM_DETAIL_INCART_EDIT_FAILURE:
            return {
                ...state,
                item_detail_incart: null,
                item_variant_incart: null,
            };
        case ItemConstants.GET_ITEM_CART:
            var cart = JSON.parse(localStorage.getItem('cart'));
            if (cart === null) {
                cart = []
            }
            return {
                ...state,
                total_qty: cart.reduce((sum, { qty }) => sum += qty, 0),
                total_amount: cart.reduce((sum, { amount }) => sum += amount, 0),
                cart: cart,
                item_search_list: [],
                pin_cart: localStorage.getItem('pin_cart') ? Boolean(localStorage.getItem('pin_cart')) : false
            };
        case ItemConstants.ADD_REMOVE_ITEM_CART:

            var data = action.data;
            var action_type = action.action_type;
            var cart = JSON.parse(localStorage.getItem('cart'));
            var is_enable_infinite_item_add_to_cart = localStorage.getItem('is_enable_infinite_item_add_to_cart')  || false;
            if (cart === null) {
                cart = []
            }

            if (action_type === "REMOVEALL") {
                cart = []
            }

            let filter = cart.filter(i => i.item_id == data.item_id && i.variant_id == data.variant_id)
            if (action_type == "DELETE" && data.is_custom_weight === true) {
                cart.forEach(value => {
                    if (value.item_id == data.item_id) {
                        value.qty = 0
                        value.amount = parseFloat((value.rate * value.qty).toFixed(2));
                    }
                })
            }
            else if (filter.length > 0) {
                cart.forEach(value => {
                    if (value.item_id == data.item_id && value.variant_id == data.variant_id) {
                        if (action_type == "ADD") {
                            if(is_enable_infinite_item_add_to_cart == true || is_enable_infinite_item_add_to_cart == "true"){
                                value.qty = value.qty + 1
                                value.amount = parseFloat((value.rate * value.qty).toFixed(2));
                            }else if (value.qty < data.inventory) {
                                value.qty = value.qty + 1
                                value.amount = parseFloat((value.rate * value.qty).toFixed(2));
                            }
                        }
                        else if (action_type == "REMOVE") {
                            value.qty = value.qty - 1
                            value.amount = parseFloat((value.rate * value.qty).toFixed(2));
                        }
                        else if (action_type == "DELETE") {
                            value.qty = 0
                            value.amount = parseFloat((value.rate * value.qty).toFixed(2));
                        }
                        else if (action_type == "CUSTOM_ADD") {
                            value.value = data.value
                            amount = global.unit_rate_cal(value.master_value, value.master_unit, value.master_rate, data.value, value.unit);
                        }
                        else if (action_type == "CUSTOM_REMOVE") {
                            value.value = ""
                            value.qty = 0
                            value.amount = 0;
                        }
                    }
                })
            }
            else {
                if (data.item_id) {

                    let amount = data.rate;
                    let selection_by = data.selection_by
                    if (action_type == "CUSTOM_ADD") {
                        amount = global.unit_rate_cal(data.master_value, data.master_unit, data.master_rate, data.value, data.unit);
                        selection_by = "qty"
                    }

                    cart.push({
                        "item_id": data.item_id, "item_name": data.item_name, "feature_image": data.feature_image, "item_sku": data.item_sku,
                        "variant_id": data.variant_id, "variant_label": data.variant_label, "selection_by": selection_by, "variant_sku": data.variant_sku, "value": data.value,
                        "unit": data.unit, "mrp": data.mrp, "rate": data.rate, "qty": 1, "amount": amount, "inventory": data.inventory,
                        "is_masterrate": data.is_masterrate, "master_value": data.master_value, "master_unit": data.master_unit,
                        "master_mrp": data.master_mrp, "master_rate": data.master_rate, "is_custom_weight": data.is_custom_weight
                    })
                }
            }

            cart = cart.filter(i => i.qty !== 0)

            if (state.item_list) {
                state.item_list.forEach(data => {
                    data.item_variant.forEach(value => {
                        let filter = cart.filter(i => i.item_id == data.item_id && i.variant_id == value.id)
                        if (filter.length > 0) {
                            if (value.is_custom_weight === true) {
                                value.value = filter[0].value
                            }
                            value.qty = filter[0].qty
                            value.amount = filter[0].amount
                        }
                        else {
                            if (value.is_custom_weight) {
                                value.value = ""
                            }
                            value.qty = 0
                            value.amount = 0
                        }
                    })
                })
            }

            if (state.item_variant) {
                state.item_variant.forEach(value => {
                    let filter = cart.filter(i => i.item_id == data.item_id && i.variant_id == value.variant_id)
                    if (filter.length > 0) {
                        if (value.is_custom_weight === true) {
                            value.value = filter[0].value
                        }
                        value.qty = filter[0].qty
                        value.amount = filter[0].amount
                    }
                    else {
                        if (value.is_custom_weight === true) {
                            value.value = ""
                        }
                        value.qty = 0
                        value.amount = 0
                    }
                })
            }

            if (state.item_search_list) {
                state.item_search_list.forEach(data => {
                    data.item_variant.forEach(value => {
                        let filter = cart.filter(i => i.item_id == data.item_id && i.variant_id == value.id)
                        if (filter.length > 0) {
                            value.qty = filter[0].qty
                            value.amount = filter[0].amount
                        }
                        else {
                            value.qty = 0
                            value.amount = 0
                        }
                    })
                })
            }
            if (state.item_variant_incart) {
                state.item_variant_incart.forEach(value => {
                    let filter = cart.filter(i => i.item_id == data.item_id && i.variant_id == value.variant_id)
                    if (filter.length > 0) {
                        if (value.is_custom_weight === true) {
                            value.value = filter[0].value
                        }
                        value.qty = filter[0].qty
                        value.amount = filter[0].amount
                    }
                    else {
                        if (value.is_custom_weight === true) {
                            value.value = ""
                        }
                        value.qty = 0
                        value.amount = 0
                    }
                })
            }

            let total_qty = 0
            let amount = 0
            let total_amount = 0
            let delivery_charge = 0

            total_qty = cart.reduce((sum, { qty }) => sum += qty, 0)
            amount = cart.reduce((sum, { amount }) => sum += amount, 0)

            /*  if (state.shipping_rate) {
                 state.shipping_rate.forEach(data => {
                     if (amount >= data.order_amount) {
                         delivery_charge = data.rate
                     }
                 })
             } */

            total_amount = amount + delivery_charge;

            localStorage.setItem('cart', JSON.stringify(cart));
            return {
                ...state,
                total_qty: total_qty,
                amount: amount,
                delivery_charge: delivery_charge,
                total_amount: total_amount,
                cart: cart,
                item_list: state.item_list,
                item_variant: state.item_variant,
                item_search_list: state.item_search_list,
            };
        case ItemConstants.GET_PRODUCT_CART_REQUEST:
            return {
                ...state,
            };
        case ItemConstants.GET_PRODUCT_CART_SUCCESS:

            cart = action.payload.data.cart
            let new_amount = cart.reduce((sum, { amount }) => sum += amount, 0)
            let new_total_amount = 0
            let new_delivery_charge = 0
            let free_shipping_order_amount = 0

            /*    action.payload.data.shipping_rate.forEach(data => {
                   if (amount >= data.order_amount) {
                       delivery_charge = data.rate
                   }
                   if (data.rate === 0) {
                       free_shipping_order_amount = data.order_amount;
                   }
               }) */

               new_total_amount = new_amount + new_delivery_charge;

            localStorage.setItem('cart', JSON.stringify(cart));
            return {
                ...state,
                shipping_rate: action.payload.data.shipping_rate,
                wishlist_item: action.payload.data.wishlist_item,
                total_qty: cart.reduce((sum, { qty }) => sum += qty, 0),
                amount: new_amount,
                delivery_charge: new_delivery_charge,
                total_amount: new_total_amount,
                free_shipping_order_amount: free_shipping_order_amount,
                cart: cart,
                is_special_instruction: action.payload.data.is_special_instruction,
                is_cart_announcement: action.payload.data.is_cart_announcement,
                cart_announcement: action.payload.data.cart_announcement,
            };
        case ItemConstants.GET_PRODUCT_CART_FAILURE:
            return {
                ...state,
            };
        case ItemConstants.GET_PRODUCT_SEARCH_REQUEST:
            return {
                ...state,
                item_search_list: null,
            };
        case ItemConstants.GET_PRODUCT_SEARCH_SUCCESS:
            var cart = JSON.parse(localStorage.getItem('cart'));
            if (cart === null) {
                cart = []
            }
            action.payload.data.item_list.forEach(data => {
                data.item_variant.forEach(value => {
                    let filter = cart.filter(i => i.item_id == data.item_id && i.variant_id == value.id)
                    if (filter.length > 0) {
                        value.qty = filter[0].qty
                        value.amount = filter[0].amount
                    }
                    else {
                        value.qty = 0
                        value.amount = 0
                    }
                })
            })
            return {
                ...state,
                item_search_list: action.payload.data.item_list,
                searchtext: action.payload.data.searchtext,
            };
        case ItemConstants.GET_PRODUCT_SEARCH_FAILURE:
            return {
                ...state,
                item_search_list: [],
            };
        case ItemConstants.PIN_UNPIN_CART:
            if (localStorage.getItem('pin_cart')) {
                localStorage.removeItem('pin_cart')
            }
            else {
                localStorage.setItem('pin_cart', true)
            }
            return {
                ...state,
                pin_cart: localStorage.getItem('pin_cart') ? Boolean(localStorage.getItem('pin_cart')) : false
            };
        
        case ItemConstants.OPEN_CART_VIEW:
            return {
                ...state,
                is_open_cart_view:action.is_open_cart_view
            };
        case ItemConstants.SELECT_FAVORITE_REQUEST:
            return {
                ...state,
            };
        case ItemConstants.SELECT_FAVORITE_SUCCESS:
            state.item_list.forEach(data => {
                if (data.item_id === action.data.item_id) {
                    data.is_favorite = !data.is_favorite
                }
            })
            return {
                ...state,
                item_list: state.item_list,
            };
        case ItemConstants.SELECT_FAVORITE_FAILURE:
            return {
                ...state,
            };
        case ItemConstants.CLEAR_ITEM_LIST:
            return {
                ...state,
                item_search_list: [],
            };

        default:
            return state
    }
}




