import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar, Typography, Button, Chip, Divider, InputBase, Paper, Badge, Card, CardMedia, CardContent, CardActionArea, Link } from '@mui/material';
import TestVideo from "../assets/test_video.mp4"
import FullBanner from "../assets/banner_full.jpg"
import MainHeader from '../components/General/MainHeader';
import TopHeader from '../components/General/TopHeader';
import BannersHome from '../components/LandingPage/BannersHome';
import TopProductsSection from '../components/LandingPage/TopProductsSection';
import AllCategories from '../components/LandingPage/AllCategories';
import BlogSection from '../components/LandingPage/BlogSection';
import Footer from '../components/General/Footer';
import { isMobile } from 'react-device-detect';

class TnC extends Component {
  render() {
    return (
      <section className={`py-5 ${isMobile ? "pt-0" : null}`}>
        <div className='container'>
          <h2>Terms and Conditions</h2>
          <hr></hr>
          <p className="small">This Terms of Use Agreement is a legal agreement between Zoetic Ayurvedics and its affiliates and subsidiaries (“Zen Wholesale Hub”, “we”, “us”, “our””) and you and, together with the Privacy Policy (collectively, these “Terms”), governs your access to and use of the Zoetic Ayurvedics websitewww.zenwholesalehub.inand its associated subdomains, all other Zoetic Ayurvedics-related sites, applications , platforms, tools, programs, systems, servers, and networks, and all related functionality, services, and Content (as defined below) offered or otherwise made available by or through any of them (individually and collectively, the “Site”). By accessing the Site you agree to be bound by these Terms. These Terms constitute the sole and entire agreement between you and us with respect to the Site and supersede all prior and contemporaneous understandings, agreements, representations, warranties, and covenants, both written and oral, with respect to the Site.</p>
          <p className="small">We may change these Terms periodically in its sole discretion. If we revise these Terms, we will post such revised Terms to the Site, and such revised Terms shall be effective immediately upon such posting. Your continued use of the Site after we post such revised Terms constitutes acceptance of such revised Terms.</p>
          <p className="small">Subject to the terms and conditions of these Terms, you may access and use the Site for your personal, noncommercial use, provided that:</p>
          <ul>
            <li>Such use is not for malicious, mischievous, fraudulent, tortious, or unlawful purposes.</li>
            <li>You otherwise comply with all applicable local, state, and Central laws, rules and regulations.</li>
            <li>You will not, and will not assist others to, disable, disrupt, or interfere with the operation of the Site or any of its security-related features, whether via hacking, transmission of malware, Trojan Horses, viruses and the like, or otherwise.</li>
            <li>You will not use any automated system to gather, use, or reproduce any Content, including, without limitation, so-called robots, spiders, crawlers, scrapers, unless and except to the extent used in connection with a legitimate and publicly available search engine.</li>
          </ul>
          <p className="small">We reserve the right to withdraw or amend the Site or any portion thereof, in our sole discretion without notice. We will not be liable if for any reason all or any portion of the Site is unavailable at any time or for any period. From time to time, we may restrict access to some or all of the Site.</p>


          <h5 className='fw-semibold text-primary'>1. What You Can Expect – Shopping & Delivery</h5>
          <p className="small">The Site may allow you to pre-order items listed on the site party plattersand buy them for home delivery or in store pickup at a local Zoetic Ayurvedics store. These transactions will be fulfilled by us and/or by our agents, suppliers, vendors, contractors, or licensees. Product availability and pricing reflected on the Site may differ from that found in our stores and is subject to change, without notice.</p>
          <p className="small">When you order fruits and vegetables from the Site for store roadside pickup, your order will be prepared and will be brought to your vehicle. For delivery orders, your groceries will be delivered to the delivery address you specify at the time of order placement.</p>
          <p className="small">Zoetic Ayurvedics shall not be liable, and you agree not to hold us or our agents or employees responsible, for any damage or theft that occurs during delivery.</p>
          <p className="small">You acknowledge that we may, prior to delivery, modify, cancel or ship only portions of the product(s) that you order. We will try to notify you of any such changes.</p>


          <h5 className='fw-semibold text-primary'>2. Privacy And Your Personal Information</h5>
          <p className="small">Privacy Policy: All information we collect through the Site is subject to our Privacy Policy. By using the Site, you consent to all actions taken by us with respect to your information in compliance with our Privacy Policy.</p>
          <p className="small">Email & Text Communications: We may email or text you about the orders you place on the Site. You may also receive occasional email or text communications regarding the Site, partner offers, special offers or services, products or newsletters. You may choose to stop receiving such communications by following the instructions in such communications.</p>

          <h5 className='fw-semibold text-primary'>3. Registration/Login</h5>
          <p className="small">In order to access and enjoy all of the features, products, and services available on the Site, and to enhance your online shopping experience, you will need to register and create a Zoetic Ayurvedics customer account or log into the Site using another Zoetic Ayurvedics-approved account, such as Google or Facebook.</p>
          <p className="small">Upon registration, you will be issued a username and password (your “Credentials”). You must treat your Credentials as confidential, and you must not disclose your Credentials to any other individual or entity. You acknowledge that your account is personal to you and agree not to provide your Credentials to any other person. You agree to notify us promptly of any unauthorized use of your Credentials or any other breach of security. You will remain liable for any unauthorized use of the Site using your Credentials until you notify us of such unauthorized use.</p>
          <p className="small">For online orders, you may be required to provide contact information (including your name, billing address, telephone number and email address, and the name and shipping address of the recipient) and financial information (including credit card number, expiration date, and CVC code). This information is used for billing purposes and to fill your orders. If we have trouble processing an order, we will use this contact information to get in touch with you. It is a condition of your access to or use of the Site, and you hereby warrant, that all information you provide to us is correct, current, and complete, that such receipt is authorized by you, and that we will have no liability arising from your failure to provide or maintain accurate information.</p>

          <h5 className='fw-semibold text-primary'>4. Fees, Payment And Access</h5>
          <p className="small">You are responsible for, and agree to pay promptly, all charges, including applicable taxes and purchases by you or anyone you allow to use your Credentials, including your family, friends or other customers. You shall be responsible and liable for any fees, including attorneys' fees and collection costs, that we may incur in our efforts to collect any unpaid balances from you. Your right to use the Site is also subject to limits established by your credit card issuer, and you are responsible for ensuring that your use of your credit card complies with the same. We will have no liability if payment is declined by your credit card issuer. The manner, timing, and availability of refunds will be determined by us in our sole discretion.</p>

          <h5 className='fw-semibold text-primary'>5. Your Conduct</h5>
          <p className="small">You agree to use the Site and any Content (as defined below) only for your personal purposes. Except as otherwise expressly stated in these Terms, you may not alter, modify, copy, distribute (for compensation or otherwise), transmit, publicly display, publicly perform, reproduce, reuse, post, republish, license, frame, download, store for subsequent use, create derivative works of, transfer, or sell any of the Content, in whole or in part, without Zoetic Ayurvedics prior written authorization. Any unauthorized use of the Content is a breach of these Terms and may violate copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. If you use any of the Content without authorization, or beyond the scope of your authorization, your right to access and use the Site shall immediately terminate. You may use downloaded material only for your personal use. You may not engage in any conduct or action that is prohibited by law or violates any central , state or local laws.</p>
          <p className="small">Any news services, nutritional information, quotes or other content on the Site are provided for informational purposes only. We shall not be responsible or liable for the accuracy, usefulness or availability of any information transmitted or made available via the Site.</p>


          <h5 className='fw-semibold text-primary'>6. Termination</h5>
          <p className="small">We may suspend or terminate your ability to access or use the Site at any time for any reason with or without notice to you.</p>


          <h5 className='fw-semibold text-primary'>7. Ownership And Trademarks</h5>
          <p className="small">Except as otherwise expressly stated, all content, features, and functionality included in or accessible through the Site (including, without limitation, all information, software, text, displays, images, video, and audio, and the design, selection, and arrangement thereof) (collectively, the “Content”) is owned by Zoetic Ayurvedics, its licensors, or other third-party content providers, and such Content is protected by United States and international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws. All rights not expressly granted in these Terms are expressly reserved.</p>
          <p className="small">All registered and/or unregistered trademarks and/or service marks (collectively, “Marks”) used or referred to on the Site are the property of Zoetic Ayurvedics or its licensors, unless otherwise noted. You may not use, copy, reproduce, republish, upload, post, transmit, distribute or modify these Marks in any way without our prior written permission. The use of Zoetic Ayurvedics Marks on any other website, without authorization, is prohibited.</p>


          <h5 className='fw-semibold text-primary'>8. Links To Third Party Sites</h5>
          <p className="small">The Site may contain links to third-party websites, which are not under the control of Zoetic Ayurvedics. We make no representations whatsoever about any other website to which you may have access through the Site. When you access another website, you do so at your own risk and acknowledge that we are not responsible or liable for any content, advertising, products or other materials available from such third party websites. You also agree that we shall not be liable for any loss or damage of any sort incurred as the result of using any third party’s website. Mention of third-party companies and websites on the Site is for informational purposes only and does not constitute an endorsement or recommendation.</p>
          <p className="small">You may link to the homepage of the Site, provided you do so in a way that is fair and legal and does not damage Zoetic Ayurvedics reputation. You may not establish a link to the Site in such a way as to suggest any form of association, approval, or endorsement on Zoetic Ayurvedics part. You agree to cooperate with us in preventing or ceasing any unauthorized framing or linking. We reserve the right to withdraw linking permission without notice.</p>
        </div>
      </section>

    );
  }
}

export default TnC;