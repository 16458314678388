import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar, Button, ListItem, Paper, Link, List, DialogActions, Typography, DialogContent, ListItemText, Switch, ListSubheader, Avatar, colors, Badge, DialogTitle, ListItemButton, Fab, ListItemIcon, InputBase } from '@mui/material';
import ShoppingBag2LineIcon from 'remixicon-react/ShoppingBag2LineIcon';
import AddCircleLineIcon from 'remixicon-react/AddCircleLineIcon';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { isMobile } from 'react-device-detect';
import {  renderTextField } from '../../_helpers/CustomFields';
import {  reduxForm, SubmissionError, change,Field,formValueSelector } from 'redux-form'
import { connect } from 'react-redux';
import {customeraction } from '../../_actions/customer.action'
import  AddressSidebar from './AddressSidebar';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class AddressEdit extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props)
        this.state = {
            Open: true,
            checked: null,
            is_add_edit_address:false,

        };
    }


    handleToggle = (value) => () => {
        this.setState({ checked: value });
    };



    handleClickOpen = () => {
        this.setState({ Open: true });
    };

    handleClose = () => {
        this.setState({ Open: false });
        this.props.handleCloseAddress()
    };

    componentDidMount() {
         this.props.dispatch(customeraction.get_state_list());
         this.props.dispatch(customeraction.get_customer_address_list())
    }
    deleteCustomerAddress = () => {
        if(this.state.checked !==null){
            this.props.dispatch(customeraction.delete_customer_address(this.state.checked))
        }
    };
    editCustomerAddress = (event,address_mode,address_id) => {
        this.setState({ is_add_edit_address: true,address_mode:address_mode,address_id:address_id });
    };
    handleCloseAddressEdit= () => {
        this.setState({ is_add_edit_address: false });
    };
    
    render() {
        return (
            <form >
                <Dialog
                    scroll="paper"
                    PaperProps={{
                        style: {
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            margin: 0,
                            bottom: 0,
                            maxHeight: "100%",
                            borderRadius: 0
                        },
                    }}

                    TransitionComponent={Transition}
                    open={this.state.Open}
                    // onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    fullWidth
                    maxWidth={'xs'}
                >
                    <DialogTitle className='p-0'>
                        <AppBar className='w-100 p-0' sx={{ position: 'relative' }} elevation={0}>
                            <Toolbar>
                                <Typography sx={{ flex: 1, }} className='fw-normal' variant="h6" component="div">
                                    Select Address
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={this.handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </DialogTitle>

                    <DialogContent className='position-relative px-0' dividers>
                        <Typography className='ms-3'>Saved Addresses</Typography>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {this.props.customer_address_list && this.props.customer_address_list.map((value,i) => {
                                return (
                                    <ListItem
                                        key={value}
                                        className='w-100'
                                        disablePadding
                                    >
                                        <ListItemButton role={undefined} onClick={this.handleToggle(value._id)} dense className='w-100'>
                                            <ListItemIcon>
                                                <Radio
                                                    edge="start"
                                                    checked={this.state.checked == value._id}
                                                    tabIndex={-1}
                                                    disableRipple
                                                    inputProps={{ 'aria-labelledby': value._id }}
                                                />
                                            </ListItemIcon>
                                            <ListItemText id={value._id} primary={`Address ${i + 1}`} primaryTypographyProps={{ fontWeight: 600 }} secondary={value.address + "," + value.landmark + "," + value.area + "," + value.city + "-" + value.pincode + "," + value.state}/>
                                        </ListItemButton>
                                    </ListItem>
                                );
                            })}
                        </List>

                    </DialogContent>
                    <DialogActions className='d-flex flex-column'>
                        <div className='d-flex w-100'>
                            {this.state.checked !=null &&<>
                                <Button variant="contained" style={{background:"#f0ad4e"}} sx={{ p: 1 }} className='m-1 w-100 fs-6 fw-light' onClick={(event) => this.editCustomerAddress(event,"UP",this.state.checked)} disableElevation>Edit</Button>
                                <Button variant="contained" sx={{ p: 1 }} style={{background:"red"}} className='m-1 w-100 fs-6 fw-light' onClick={this.deleteCustomerAddress} disableElevation>delete</Button>
                                </>
                            }
                        </div>
                        <div className='d-flex w-100'>
                            <Button variant="outlined" sx={{ p: 2 }} className='m-1 w-100 fs-6 fw-light' onClick={(event) => this.editCustomerAddress(event,"IN",0)} disableElevation>Add New Address</Button>
                        </div>
                        </DialogActions>
                </Dialog>
                <Dialog
                    PaperProps={{
                        style: {
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            margin: 0,
                            bottom: 0,
                            maxHeight: "100%",
                            borderRadius: 0
                        },
                    }}
                    TransitionComponent={Transition}
                    open={this.state.is_add_edit_address}
                    scroll="paper"
                    aria-labelledby="responsive-dialog-title"
                    fullWidth
                    maxWidth={'xs'}
                 >
                    <DialogContent>
                        <AddressSidebar mode={this.state.address_mode} address_id={this.state.address_id} handleCloseAddressEdit={this.handleCloseAddressEdit}/>
                    </DialogContent>
                </Dialog>
            </form>
        );
    }
}

function mapStateToProps(state) {
    return {
        customer_address_list: state.customer.customer_address_list,
    };
};

export default connect(mapStateToProps, null)(AddressEdit)

