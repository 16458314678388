import React, { Component } from 'react';
import { Button } from '@mui/material';

class Footer extends Component {
    render() {
        return (
            <section className='py-5' style={{ background: "#F7F7F7" }}>
                <div className='container-fluid px-0 mx-0 d-flex align-items-center justify-content-center flex-column'>
                    <img src="https://zoeticayurvedics.com/Zoetic/images/logo.png" height={80} />

                    <div className='py-4'>
                        <Button href='/tnc' sx={{ fontWeight: 600, color: "#343434" }} className='mx-1'>
                            Terms & Conditions
                        </Button>

                        <Button href='/privacy-policy' sx={{ fontWeight: 600, color: "#343434" }} className='mx-1'>
                            Privacy Policy
                        </Button>

                        <Button href='/return-exchange' sx={{ fontWeight: 600, color: "#343434" }} className='mx-1'>
                            Return and Exchange
                        </Button>

                        <Button href='/contact-us' sx={{ fontWeight: 600, color: "#343434" }} className='mx-1'>
                            Contact Us
                        </Button>

                    </div>

                    <p className='opacity-75 fw-light'>Copyrights 2018 © Zoetic Ayurvedics Pvt. Ltd.</p>
                </div>
            </section>
        );
    }
}

export default Footer;