import * as React from "react";
import { alpha, styled } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { InputAdornment } from "@mui/material";

const MyTextField = styled((props) => (
  <TextField InputProps={{ disableUnderline: true }} {...props} />
))(({ theme }) => ({
  "& .MuiFilledInput-root": {
    overflow: "hidden",
    borderRadius: 8,
    backgroundColor: "#F3F3F3",
    border: "1px solid",
    borderColor: "#dee2e6",
    transition: theme.transitions.create([
      "border-color",
      "background-color",
      "box-shadow",
    ]),
    "&.Mui-focused": {
      boxShadow: `${alpha(theme.palette.common.black, 0.25)} 0 0 0 2px`,
      borderColor: "#dee2e6",
    },
    "&.Mui-error": {
      borderColor: theme.palette.error.main,
      "&.Mui-focused": {
        boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 2px`,
      },
    },
  },
}));


export const renderTextField = ({
  input,
  label,
  meta: { touched, error },
  ...custom
}) => {
  return (
    <MyTextField
    input={input}
    label={label}
    error={Boolean(touched && error)}
    helperText={touched && error}
    {...input}
    {...custom}
      />
  )
};