import React, { Component } from 'react';
import { Button } from '@mui/material';
import BlogCard from '../General/BlogCard';
import { isMobile } from 'react-device-detect';

const Blogs = [
    {
        "Title": "Exploring Anxiety Ayurvedic Treatment",
        "Subtitle": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tristique dolor ac magna consectetur rutrum. Cras vitae est in mauris.",
        "Image": "https://images.unsplash.com/photo-1595178156906-2396ef837b0f",
        "Category": "Mental Health",
        "Date": "Oct 04, 2023",
    },
    // {
    //     "Title": "How To Stay Healthy With Ayurveda During Covid-19",
    //     "Subtitle": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tristique dolor ac magna consectetur rutrum. Cras vitae est in mauris.",
    //     "Image": "https://images.unsplash.com/photo-1486218119243-13883505764c",
    //     "Category": "Immunity",
    //     "Date": "Oct 04, 2023",
    // },
    // {
    //     "Title": "Lifestyle and Diet For Reducing a Fatty Liver",
    //     "Subtitle": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tristique dolor ac magna consectetur rutrum. Cras vitae est in mauris.",
    //     "Image": "https://images.unsplash.com/photo-1547496502-affa22d38842",
    //     "Category": "Digestive Health",
    //     "Date": "Oct 04, 2023",
    // },
    // {
    //     "Title": "Ayurveda ? The Right Formula for Youthful, Healthy Skin",
    //     "Subtitle": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean tristique dolor ac magna consectetur rutrum. Cras vitae est in mauris.",
    //     "Image": "https://images.unsplash.com/photo-1616394584738-fc6e612e71b9",
    //     "Category": "Health & Wellness Products",
    //     "Date": "Oct 04, 2023",
    // },
]

class BlogSection extends Component {
    render() {
        return (
            <section className='py-3'>
                <div className='container'>
                    <div className='d-flex align-items-center justify-content-between mb-3'>
                        <div>
                            <h3 className='fw-semibold mb-0'>Healing with Ayurveda</h3>
                            <p className='fw-light mb-0 opacity-75'>Bringing Ayurvedic Wisdom into Your Daily Life.</p>
                        </div>
                        {isMobile ? null : <Button variant="contained" disableElevation className='p-3 rounded-3'>Read all articles</Button>}
                        
                    </div>
                    <div className={`row ${isMobile ? "horizontal-scroll" : ""} `}>
                        {Blogs.map((item) => (
                            <a href='/blogpage'>
                                <div className='col-md-3'>                           
                                    <BlogCard Title={item.Title} Subtitle={item.Subtitle} Image={item.Image} Category={item.Category} Date={item.Date}/>                                                        
                                </div>
                            </a>
                        ))}
                    </div>

                    {isMobile ? <Button variant="contained" disableElevation className='p-3 rounded-3 w-100 my-3'>Read all articles</Button> : null}
                </div>
            </section>
        );
    }
}

export default BlogSection;