import React, { Component } from 'react';
import { connect } from 'react-redux';
import TopProductsSection from '../../components/LandingPage/TopProductsSection';

class NewCollectionSection extends Component {
    
    constructor(props) {
        super(props);
    }
    render() {
        return (
            this.props.collection_item_list && this.props.collection_item_list.map((data, index) => {
                return <TopProductsSection values={data}/>
           })
        )
    }
} 

function mapStateToProps(state) {
    return {
        collection_item_list: state.item.collection_item_list,
    };
}
export default connect(mapStateToProps)(NewCollectionSection);
