export const WalletConstants = {
    GET_CUSTOMER_WALLET_BALANCE_REQUEST: "GET_CUSTOMER_WALLET_BALANCE_REQUEST",
    GET_CUSTOMER_WALLET_BALANCE_SUCCESS: "GET_CUSTOMER_WALLET_BALANCE_SUCCESS",
    GET_CUSTOMER_WALLET_BALANCE_FAILURE: "GET_CUSTOMER_WALLET_BALANCE_FAILURE",
    ACTIVATE_CUSTOMER_WALLET_REQUEST: "ACTIVATE_CUSTOMER_WALLET_REQUEST",
    ACTIVATE_CUSTOMER_WALLET_SUCCESS: "ACTIVATE_CUSTOMER_WALLET_SUCCESS",
    ACTIVATE_CUSTOMER_WALLET_FAILURE: "ACTIVATE_CUSTOMER_WALLET_FAILURE",
    SEND_MOBILENO_OTP_REQUEST: 'SEND_MOBILENO_OTP_REQUEST',
    SEND_MOBILENO_OTP_SUCCESS: 'SEND_MOBILENO_OTP_SUCCESS',
    SEND_MOBILENO_OTP_FAILURE: 'SEND_MOBILENO_OTP_FAILURE',
    GET_CUSTOMER_ADD_MONEY_REQUEST: 'GET_CUSTOMER_ADD_MONEY_REQUEST',
    GET_CUSTOMER_ADD_MONEY_SUCCESS: 'GET_CUSTOMER_ADD_MONEY_SUCCESS',
    GET_CUSTOMER_ADD_MONEY_FAILURE: 'GET_CUSTOMER_ADD_MONEY_FAILURE',
};