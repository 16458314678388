import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, SubmissionError } from 'redux-form'
import { Button, Link, Paper, InputBase} from '@mui/material';

class RazerpayUpi extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            vpa: "",
        };
    }
    
    place_order_upi = (values) => {
        console.log('=========')
        if (!this.state.vpa) {
            throw new SubmissionError({ vpa: 'Please enter UPI address' })
        }
        var data = {}
        console.log('=========ttt')
        data.method = 'upi';
        data.vpa = this.state.vpa;
        this.props.place_order(data)
    }   

    click_wallet = (wallet) => {
        this.setState({ wallet: wallet });
    };
    onChangeVpa = (e) => {
        this.setState({ vpa: e.target.value });
    };

    render() {

        const { handleSubmit } = this.props;
        return (
            <div className='card-body py-3 bg-transparent'>
                <div className='row'>
                    <div className='col-md-6 mb-3'>
                        <Paper
                            component="form"
                            elevation={0}
                            className='border h-100'
                            sx={{ p: '8px 16px', display: 'flex', alignItems: 'center', borderRadius: 2, background: "#F3F3F3" }}
                        >

                            <InputBase
                                name="vpa"
                                onChange={this.onChangeVpa}
                                id="vpa"
                                sx={{ flex: 1 }}
                                placeholder="Enter UPI ID"
                                autoFocus
                                inputProps={{ 'aria-label': 'search google maps' }}
                            />
                        </Paper>
                    </div>

                    <div className='col-md-6 mb-3'>
                        <Button variant="contained" sx={{ p: 2 }} onClick={handleSubmit(values => this.place_order_upi({ ...values }))} className='fw-light w-100' disableElevation>Place Order & Pay ₹{this.props.total_amount}</Button>
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        total_amount: state.payment.total_amount,
    };
}

RazerpayUpi = reduxForm({
    form: 'payment_form',
    destroyOnUnmount: false,
})(RazerpayUpi);

export default connect(mapStateToProps, null)(RazerpayUpi);



