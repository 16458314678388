import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Paper, InputBase, Divider, Button, IconButton, Link } from '@mui/material';
import AddCircleLineIcon from 'remixicon-react/AddCircleLineIcon';
import IndeterminateCircleLineIcon from 'remixicon-react/IndeterminateCircleLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import MapPin2FillIcon from 'remixicon-react/MapPin2FillIcon';


import Dialog from '@mui/material/Dialog';

import { isMobile } from 'react-device-detect';
import { itemaction } from '../../_actions/item.action';
import ClearIcon from '@mui/icons-material/Clear';
import Addressdetail from './addressdetail';


class Address extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoggedIn: false,
            is_address_open:false
        };
    }

    componentDidMount = () => {
        this.props.dispatch(itemaction.get_product_cart())
        let customer_access_token = localStorage.getItem("customer_access_token")

        if (customer_access_token === null || customer_access_token === undefined) {
            this.setState({ isLoggedIn: true })
        }
        else {
            this.setState({ isLoggedIn: true })
        }
    }
    handleClickOpen = () => {
        this.setState({ is_address_open: true });
    };
    render() {
        return (
            <div>
                <section className='py-5'>
                    <div className='container'>
                        <div className='row'>
                            <div className='col-md-8'>
                                <div className='card rounded-4'>
                                    <div className='card-body py-1 overflow-scroll' style={{ height: isMobile ? null : 300 }}>
                                        <div className='card rounded-4 mb-3'>
                                            <div className='border-0'>
                                                <div className='d-flex justify-content-between align-items-center mb-3'>
                                                    <h6 className='d-flex align-items-center fw-semibold mb-0'><MapPin2FillIcon className='text-primary me-1' /><span>Deliver to Office (380054)</span></h6>
                                                    <a href='' className='mb-0 small text-decoration-underline fw-semibold text-primary'>Change Address</a>
                                                </div>
                                                <p className='text-muted mb-3'>9th floor the chambers, nr the grand bhagavati hotel bodakdev, Ahmedabad 380054</p>
                                                <Button variant="contained" component={Link} href='payment' sx={{ p: 2 }} onClick={() => { }} className='w-100 fs-6 fw-light' disableElevation>Proceed to buy</Button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Dialog
                    open={false}
                    aria-labelledby="cust2omized-dialog-title"
                    maxWidth="sm"
                    fullWidth>
                    <Addressdetail mode={"IN"} address_id={"0"}/>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
      cart: state.item.cart,
      total_qty: state.item.total_qty,
    //   amount: state.item.amount,
      delivery_charge: state.item.delivery_charge,
      total_amount: state.item.total_amount,
      free_shipping_order_amount: state.item.free_shipping_order_amount,
      wishlist_item: state.item.wishlist_item,
      pin_cart: state.item.pin_cart,
      customer_access_token: state.authentication.customer_access_token,
      is_special_instruction: state.item.is_special_instruction,
      is_cart_announcement: state.item.is_cart_announcement,
      cart_announcement: state.item.cart_announcement,
    };
}

export default connect(mapStateToProps, null)(Address);


