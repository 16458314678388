import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar, Typography, Button, Chip, Divider, InputBase, Paper, Badge, Card, CardMedia, CardContent, CardActionArea, Link, Alert } from '@mui/material';
import { isMobile } from 'react-device-detect';
import blog1Banner from "../assets/blog_1.png"
import CoolantImg from "../assets/coolant.jpg";

import BrahmiImg from "../assets/brahmi.jpg";

import IwallImg from "../assets/iwall_tab.jpg";

import SamsamavatiImg from "../assets/samsamavati.jpg";
import AddCircleLineIcon from 'remixicon-react/AddCircleLineIcon';
import IndeterminateCircleLineIcon from 'remixicon-react/IndeterminateCircleLineIcon';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import FacebookCircleFillIcon from 'remixicon-react/FacebookCircleFillIcon';
import TwitterFillIcon from 'remixicon-react/TwitterFillIcon';
import InstagramFillIcon from 'remixicon-react/InstagramFillIcon';
import YoutubeFillIcon from 'remixicon-react/YoutubeFillIcon';
import { ShareRounded } from '@mui/icons-material';


const Cart_Products = [
  {
    "Name": "Coolant Syrup",
    "price": "₹ 115.00",
    "Image": CoolantImg,
  },

  {
    "Name": "Arogyavardhini Vati",
    "price": "₹ 500.00",
    "Image": BrahmiImg,
  },

  {
    "Name": "iWALL Tablet",
    "price": "₹ 195.00",
    "Image": IwallImg,
  },

  {
    "Name": "Samsamani Vati",
    "price": "₹ 120.00",
    "Image": SamsamavatiImg,
  },
]

class BlogPage extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (
      <>
        <section className="" style={{ background: " url(" + blog1Banner + ")" }}>
          <div className="container" style={{ paddingTop: 160, paddingBottom: 160 }}>
            <h1 className='display-4 fw-semibold text-white'>Unveiling Zoetic Ayurvedics: Bridging Ancient Wisdom with Modern Innovation</h1>
            <p className='text-white opacity-75'>Updatd On: Jan 10, 2024</p>
          </div>
        </section>

        <section className='py-5'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-7'>
                <p>Welcome to the inaugural blog post of Zoetic Ayurvedics, your gateway to a harmonious fusion of ancient wisdom and modern innovation in the realm of holistic well-being. As we embark on this transformative journey, let us introduce ourselves and shed light on the vision that propels us forward, guided by the principles of research, development, and clinical evidence.</p>
                <p>Zoetic Ayurvedics is not just a company; it is a commitment to excellence, where traditional Ayurvedic wisdom meets the rigor of modern research and development. Our mission is to redefine well-being by creating innovative products that target various therapeutic segments, all backed by extensive research and clinical validation.</p>
                <p>In a world where wellness is often a buzzword, we stand apart by placing a strong emphasis on the scientific foundation of our products. Our research and development team, comprising experts in Ayurveda and modern healthcare, works tirelessly to bridge the gap between ancient knowledge and contemporary solutions. Every product we offer is a result of meticulous research, ensuring that it not only upholds the principles of Ayurveda but also meets the highest standards of quality and effectiveness.</p>
                <p>At Zoetic Ayurvedics, we are proud to be at the forefront of innovation, constantly pushing the boundaries of what's possible. Our commitment to research and development goes hand in hand with our dedication to providing products that are not only authentic but are also backed by robust clinical evidence. We believe in transparency and accountability, and thus, each product undergoes rigorous testing to validate its efficacy and safety.</p>
                <Alert icon={false} className='mb-3 fs-5 p-3 position-relative' title='' severity="info">
                  <FormatQuoteIcon className='position-absolute top-0 start-100 translate-middle' sx={{ fontSize: 72 }} />
                  <p className='mb-0'><span className='fs-5 fw-semibold text-secondary'>Our vision is clear.</span> to be a global leader in holistic health, recognized for our unwavering commitment to research-backed, clinically validated products. We aspire to be a beacon of trust, offering solutions that empower individuals to take charge of their well-being with confidence.</p>
                </Alert>

                <p>Join us on this exciting journey as we delve into Ayurvedic practices, share updates on our latest products, and provide valuable insights supported by research and clinical evidence. Here's to a future where tradition meets innovation, and holistic well-being knows no compromise – with Zoetic Ayurvedics, where science and ancient wisdom converge for your optimal health.</p>
              </div>

              <div className='col-md-4 offset-md-1'>
                <h5 className='text-primary border-bottom pb-3 border-2 border-primary'>Blog Related Products Title</h5>
                {Cart_Products.map((item) => (
                  <div className='cursor-pointer d-flex align-items-start justify-content-start flex-row rounded-0 border-0 border-bottom py-2 card'>
                    <img src={item.Image} className='rounded-2 border' style={{ width: 100 }} />
                    <div className='flex-grow-1 align-items-start justify-content-between flex-column d-flex p-2 h-100'>
                      <h6 className='two-line mb-1'>{item.Name}</h6>
                      <p className='two-line mb-1 text-muted'>{item.price}</p>
                    </div>

                    <div className='flex-grow-1 align-items-end justify-content-between flex-column d-flex p-2 h-100'>
                      <p className='text-muted mb-3'>200 ml</p>
                      <Button variant="text" color='primary' className='' onClick={() => { }} disableElevation>Add To Bag</Button>
                    </div>
                  </div>
                ))}

                <div className='border-bottom py-3 mt-3 border-2 border-primary d-flex align-items-center justify-content-between'>
                  <h5 className='text-primary mb-0'><ShareRounded className='me-2'/>Share</h5>
                  <div>
                    <IconButton aria-label="delete">
                      <FacebookCircleFillIcon />
                    </IconButton>

                    <IconButton aria-label="delete">
                      <TwitterFillIcon />
                    </IconButton>

                    <IconButton aria-label="delete">
                      <InstagramFillIcon />
                    </IconButton>

                    <IconButton aria-label="delete">
                      <YoutubeFillIcon />
                    </IconButton>
                  </div>
                </div>


              </div>
            </div>
          </div>
        </section>
      </>

    );
  }
}

export default BlogPage;