import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar, Typography, Button, Chip, Divider, InputBase, Paper, Badge, Card, CardMedia, CardContent, CardActionArea, Link, TextField, CardHeader } from '@mui/material';
import TestVideo from "../assets/test_video.mp4"
import FullBanner from "../assets/banner_full.jpg"
import MainHeader from '../components/General/MainHeader';
import TopHeader from '../components/General/TopHeader';
import BannersHome from '../components/LandingPage/BannersHome';
import TopProductsSection from '../components/LandingPage/TopProductsSection';
import AllCategories from '../components/LandingPage/AllCategories';
import BlogSection from '../components/LandingPage/BlogSection';
import Footer from '../components/General/Footer';
import { isMobile } from 'react-device-detect';
import MapPin2FillIcon from 'remixicon-react/MapPin2FillIcon';
import PhoneFillIcon from 'remixicon-react/PhoneFillIcon';
import MailFillIcon from 'remixicon-react/MailFillIcon';

import RndBanner from "../assets/rnd.jpg"

class ContactUs extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (
      <>
        <section className="" style={{ background: "url(" + RndBanner + ")" }}>
          <div className="container" style={{ paddingTop: 80, paddingBottom: 80 }}>
            <h1 className='display-4 fw-semibold'>Contact Us</h1>
          </div>
        </section>

        <section className='py-5'>
          <div className='container'>
            <h4 className='text-primary fw-bold'>Get in Touch</h4>
            <p>Be Informed. Be Smart. Be Sure.</p>

            <div className='row'>
              <div className='col-md-8'>
                <div className='row'>

                  <div className='col-md-6 mb-3'>
                    <TextField className='w-100' variant="outlined" label="First Name" />
                  </div>

                  <div className='col-md-6 mb-3'>
                    <TextField className='w-100' variant="outlined" label="Last Name" />
                  </div>

                  <div className='col-md-6 mb-3'>
                    <TextField className='w-100' variant="outlined" label="Email" />
                  </div>

                  <div className='col-md-6 mb-3'>
                    <TextField className='w-100' variant="outlined" label="Phone" />
                  </div>

                  <div className='col-md-12 mb-3'>
                    <TextField className='w-100' multiline rows={6} variant="outlined" label="Your Message" />
                  </div>

                  <div className='col-md-12 mb-3'>
                    <Button variant="contained" sx={{ p: 2 }} onClick={() => { }} className='fw-light w-100' disableElevation>Submit</Button>
                  </div>
                </div>

              </div>

              <div className='col-md-4'>
                <Card variant='outlined' className='mb-3'>
                  <CardHeader
                    className='align-items-start'
                    title="Zoetic Ayurvedics Pvt. Ltd."
                    subheader="8th Floor, The Chambers ,Opp New Gurudwara,Gandhinagar-Sarkhej Highway,Ahmedabad-380054"
                    avatar={<MapPin2FillIcon className='text-primary' size="28" />}
                  />
                </Card>

                <Card variant='outlined' className='mb-3'>
                  <CardHeader
                    className='align-items-start'
                    title="Contact No."
                    subheader={<><p className='mb-1'>079 - 26859890</p>  <p className='mb-0'>079 - 26859891</p></>}
                    avatar={<PhoneFillIcon className='text-primary' size="28" />}
                  />
                </Card>

                <Card variant='outlined' className='mb-3'>
                  <CardHeader
                    className='align-items-start'
                    title="Email"
                    subheader={<><p className='mb-0'>info_ay@zoetic.co.in</p></>}
                    avatar={<MailFillIcon className='text-primary' size="28" />}
                  />
                </Card>
              </div>
            </div>
          </div>
        </section>
      </>

    );
  }
}

export default ContactUs;