import React from 'react';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import {  Button  } from '@mui/material';
import { DialogTitleComponent, DialogContentComponent, DialogActionsComponent } from '../../_helpers/dialog';
import { customeraction } from '../../_actions/customer.action';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';

import { renderTextField, renderRadioGroup, } from '../../_helpers/reduxFields'

  
const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
        errors.firstname = 'Please enter first name'
    }
    if (!values.lastname) {
        errors.lastname = 'Please enter last name'
    }
    if (!values.address) {
        errors.address = 'Please enter address'
    }
    if (!values.landmark) {
        errors.landmark = 'Please enter landmark'
    }
    if (!values.area) {
        errors.area = 'Please enter area'
    }
    if (!values.pincode) {
        errors.pincode = 'Please enter pincode'
    }
    if (!values.city) {
        errors.city = 'Please enter city'
    }
    if (!values.state) {
        errors.state = 'Please enter state'
    }
    if (!values.other_address_type) {
        errors.other_address_type = 'Please address type'
    }

    return errors;
}

const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: 300,
        },
    },
};

class Addressdetail extends React.Component {


    componentDidMount() {
        this.props.dispatch(customeraction.get_state_list());
        this.props.dispatch(customeraction.get_customer_address_detail(this.props.mode, this.props.address_id));
    }

    onSubmit = (formdata) => {
        this.props.dispatch(customeraction.in_up_customer_address(this.props.mode, formdata));
    }

    render() {

        const onSubmit = this.onSubmit.bind(this);
        const { handleSubmit } = this.props
        return (
            <form onSubmit={handleSubmit(onSubmit)} >
                <DialogTitleComponent id="customized-dialog-title" onClose={this.props.handleClose}>Address</DialogTitleComponent>
                <DialogContentComponent>
                    <div className="row">
                        <div>
                            Address Type
                             <Field name="address_type" component={renderRadioGroup} row>
                                <FormControlLabel value="Home" control={<Radio color="primary" />} label="Home" />
                                <FormControlLabel value="Office" control={<Radio color="primary" />} label="Office" />
                                <FormControlLabel value="Other" control={<Radio color="primary" />} label="Other" />
                            </Field>
                        </div>
                        {this.props.address_type === "Other" ?
                            <div className="row">
                                <div className="col-12 pt-3">
                                    <Field
                                        name="other_address_type"
                                        type="text"
                                        label="Address Type"
                                        component={renderTextField}
                                        fullWidth={true}
                                        autoComplete="off"
                                        margin="dense"/>
                                </div>
                            </div>
                        : null}
                        <div className="row">
                            <div className="col-12">
                                <Field
                                    name="address"
                                    type="text"
                                    label="Address"
                                    component={renderTextField}
                                    fullWidth={true}
                                    autoComplete="off"
                                    rows="2"
                                    multiline
                                    margin="dense"
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-6">
                                <Field
                                    name="firstname"
                                    type="text"
                                    label="First Name"
                                    component={renderTextField}
                                    fullWidth={true}
                                    autoComplete="off"
                                    margin="dense"
                                    variant="outlined"
                                />
                            </div>
                            <div className="col-6">
                                <Field
                                    name="lastname"
                                    type="text"
                                    label="Last Name"
                                    component={renderTextField}
                                    fullWidth={true}
                                    autoComplete="off"
                                    margin="dense"
                                    variant="outlined"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <Field
                                    name="landmark"
                                    type="text"
                                    label="Landmark"
                                    component={renderTextField}
                                    fullWidth={true}
                                    autoComplete="off"
                                    margin="dense"
                                />
                            </div>
                        </div>
                         <div className="row">
                        <div className="col-6">
                            <Field
                                name="area"
                                type="text"
                                label="Area"
                                component={renderTextField}
                                fullWidth={true}
                                autoComplete="off"
                                margin="dense"
                            />
                        </div>
                        <div className="col-6">
                            <Field
                                name="city"
                                type="text"
                                label="City"
                                component={renderTextField}
                                fullWidth={true}
                                autoComplete="off"
                                margin="dense"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <Field
                                name="pincode"
                                type="text"
                                label="Pincode"
                                component={renderTextField}
                                fullWidth={true}
                                autoComplete="off"
                                margin="dense"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12">
                        <Field
                            name="mobileno"
                            type="text"
                            label="Alternate Mobile No"
                            component={renderTextField}
                            fullWidth={true}
                            autoComplete="off"
                            margin="dense"
                        />
                        </div>
                    </div>
                    
                    <br></br>
                   </div>
                   
                   
                   

                </DialogContentComponent>
                <DialogActionsComponent>
                    <Button type="submit" variant="contained" color="primary" >Save</Button>
                </DialogActionsComponent>
            </form>
        );
    }
}

function mapStateToProps(state) {
    let initialValues = {}
    if (state.customer.customer_detail && state.customer.customer_detail.address_id) {
        initialValues = state.customer.customer_detail
    }
    else {

        let firstname = "";
        let lastname = "";
        if (state.authentication.customer_detail) {
            if (state.authentication.customer_detail.firstname && state.authentication.customer_detail.firstname !== "") {
                firstname = state.authentication.customer_detail.firstname;
            }
            if (state.authentication.customer_detail.lastname && state.authentication.customer_detail.lastname !== "") {
                lastname = state.authentication.customer_detail.lastname;
            }
        }

        initialValues = {
            customer_id: 0,
            firstname: firstname,
            lastname: lastname,
            address: "",
            landmark: "",
            area: "",
            pincode: "",
            city: "Ahmedabad",
            state: "",
            country: "",
            mobileno: "",
            address_type: "Home",
            other_address_type: "",
        };
    }
    return {
        customer_detail: state.authentication.customer_detail,
        state_list: state.customer.state_list,
        initialValues,
    };
};

const selector = formValueSelector('addressdetail')
Addressdetail = connect(
    state => {
        const address_type = selector(state, 'address_type')
        return {
            address_type
        }
    }
)(Addressdetail)


Addressdetail = reduxForm({
    form: 'addressdetail',
    validate,
    asyncBlurFields: ['pincode'],
    enableReinitialize: true,
})(Addressdetail)



export default connect(mapStateToProps, null)(Addressdetail)

