import React, { Component } from 'react';
import { Divider, Radio, RadioGroup, FormControlLabel, FormControl, FormLabel, Button, Link, Paper, InputBase, Tooltip, IconButton, Badge } from '@mui/material';
import MapPin2FillIcon from 'remixicon-react/MapPin2FillIcon';
import ShieldStarFillIcon from 'remixicon-react/ShieldStarFillIcon';
import QuestionFillIcon from 'remixicon-react/QuestionFillIcon';
import CheckboxCircleFillIcon from 'remixicon-react/CheckboxCircleFillIcon';
import Coupon from '../assets/coupon.svg';
import Mastercard from '../assets/mastercard.svg';
import successAnim from '../assets/successAnim.json';
// import successCheck from '../assets/success_check.json';
import successCheck from '../assets/circle_2.gif';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import global from '../components/global';

import Lottie from 'react-lottie';
import CoolantImg from "../assets/coolant.jpg";

import BrahmiImg from "../assets/brahmi.jpg";

import IwallImg from "../assets/iwall_tab.jpg";
import ClearIcon from '@mui/icons-material/Clear';

import SamsamavatiImg from "../assets/samsamavati.jpg";
import { isMobile } from 'react-device-detect';
import VerifiedRoundedIcon from '@mui/icons-material/VerifiedRounded';
import { orderaction } from '../_actions/order.action'
import { connect } from 'react-redux';
const moment = require('moment');

const Cart_Products = [
    {
        "Name": "Coolant Syrup",
        "price": "₹ 115.00",
        "Image": CoolantImg,
    },

    {
        "Name": "Arogyavardhini Vati",
        "price": "₹ 500.00",
        "Image": BrahmiImg,
    },

    {
        "Name": "iWALL Tablet",
        "price": "₹ 195.00",
        "Image": IwallImg,
    },

    {
        "Name": "Samsamani Vati",
        "price": "₹ 120.00",
        "Image": SamsamavatiImg,
    },
    {
        "Name": "Coolant Syrup",
        "price": "₹ 115.00",
        "Image": CoolantImg,
    },

    {
        "Name": "Arogyavardhini Vati",
        "price": "₹ 500.00",
        "Image": BrahmiImg,
    },

    {
        "Name": "iWALL Tablet",
        "price": "₹ 195.00",
        "Image": IwallImg,
    },

    {
        "Name": "Samsamani Vati",
        "price": "₹ 120.00",
        "Image": SamsamavatiImg,
    },
]

class SuccessPage extends Component {

    constructor(props) {
        let order_id = window.location.pathname.split("/").slice(-1)[0]

        super(props);
        this.state = {
            isStopped: false,
            isPaused: false,
            dialogOpen: true,
            order_id:order_id
        };
    }

    componentDidMount() {
        this.props.dispatch(orderaction.get_order_detail(this.state.order_id))

        this.timer = setInterval(() => { this.setState({ dialogOpen: false }) }, 4000);
    }

    defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: successAnim,

    };


    render() {
        if (!this.props.order_detail) {
            return "<Loading />"
        }

        const order_detail = this.props.order_detail
        const item_list = global.get_consolidated_cart(this.props.order_detail.item_list)

        return (
            <div>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.setState({ dialogOpen: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth="xs"
                >
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <Lottie options={this.defaultOptions}
                                height={isMobile ? 350 : 400}
                                width={isMobile ? 350 : 400}
                                className="lottie"
                                style={{ marginTop: "-120px", marginBottom: "-50px" }}
                                isStopped={this.state.isStopped}
                                isPaused={this.state.isPaused}
                            />
                            <h6 className='text-center text-dark fw-semibold'>Please wait...</h6>
                            <p className='text-center text-muted'>We are confirming Your Order.</p>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
                <section className='py-5'>
                    <div className='container'>
                        <div className='row'>
                            <h5 className='fw-medium display-6 d-flex align-items-center success-title mb-3'>
                                {/* <Lottie options={this.defaultOptions}
                                        height={80}
                                        width={80}
                                        className="lottie"
                                        style={{ margin: 4, borderRadius: 100, overflow: 'hidden' }}
                                        isStopped={this.state.isStopped}
                                        isPaused={this.state.isPaused}
                                    /> */}
                                <img src={successCheck} height={54} className='me-2' />
                                <span>Order Placed Successfully</span>
                            </h5>
                        </div>
                        
                        <div className='row'>
                            <div className='col-md-8'>

                                <div className='card rounded-4 mb-3'>
                                    <div className='card-header position-relative p-3 d-flex align-items-center justify-content-between'>
                                        <div>
                                            <p className='mb-0 text-muted fw-light'>Order ID</p>
                                            <h6 className='mb-0 fw-semibold text-uppercase'>{order_detail.order_no}</h6>
                                        </div>
                                        {isMobile ? null : <h6 className='position-absolute top-50 start-50 translate-middle fs-6 badge bg-success mb-0 fw-medium'>Confirmed</h6>}
                                        

                                        <div>
                                            <p className='mb-0 text-muted fw-light'>Order Date</p>
                                            <h6 className='mb-0 fw-semibold text-uppercase small'>{moment(order_detail.order_date).format("DD MMM,YYYY h:mm A")}</h6>
                                        </div>
                                    </div>
                                    <div className='card-body py-1 overflow-scroll' style={{ height: 300 }}>
                                        {item_list && item_list.map((item) => (
                                            <div className='cursor-pointer d-flex align-items-start justify-content-start flex-row rounded-0 border-0 border-bottom py-2 card'>
                                                <img src={item.feature_image} className='rounded-2 border' style={{ width: 64 }} />
                                                <div className='flex-grow-1 align-items-start justify-content-between flex-column d-flex p-2 h-100'>
                                                    <h6 className='two-line mb-1'>{item.item_name}</h6>
                                                    <p className='two-line mb-1 text-muted'>{"₹ "+item.rate}<ClearIcon/>{item.qty}</p>
                                                </div>
                                                <div className='flex-grow-1 align-items-end justify-content-between flex-column d-flex p-2 h-100'>
                                                    <p className='text-muted mb-1'>{item.value+item.unit}</p>
                                                    <p className='text-muted mb-1'>{"₹ "+item.amount}</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div className='card-footer position-relative p-3 d-flex align-items-center justify-content-between'>


                                        {/* <div>
                                            <p className='mb-1 text-muted fw-light'>Order Type</p>
                                            <h6 className='badge bg-warning text-dark p-2 mb-0 fw-semibold text-uppercase'>Store Pickup / Door Delivery</h6>
                                        </div> */}

                                        <div>
                                            <p className='mb-1 text-muted fw-light'>Payment Type</p>
                                            <h6 className='text-dark mb-0 fw-semibold'>{order_detail.payment_type}</h6>
                                        </div>

                                        <div>
                                            <p className='mb-1 text-muted fw-light'>Delivered By</p>
                                            {/* <h6 className='mb-0 fw-semibold'>Tomorrow 10:00 am - 10:00 pm</h6> */}
                                            <h6 className='mb-0 fw-semibold'>{"SOON"}</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className='card rounded-4 mb-3 '>
                                    <div className='card-body py-3 bg-transparent'>
                                        <h6 className='d-flex align-items-center fw-semibold mb-0'><MapPin2FillIcon className='text-primary me-1' /><span>Delivery Address</span></h6>
                                        <Divider className='my-3' />
                                        {order_detail.delivery_address &&
                                            <p className='text-muted mb-0'>{order_detail.delivery_address.address + "," + order_detail.delivery_address.landmark + "," + order_detail.delivery_address.area + "," + order_detail.delivery_address.city + "-" + order_detail.delivery_address.pincode + "," + order_detail.delivery_address.state}</p>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='col-md-4'>
                                <div className='card rounded-4 mb-3'>
                                    <div className='card-body py-3 bg-transparent'>
                                        <div className='w-100 p-2'>
                                            <h6 className='fw-semibold'>Bill Summary</h6>
                                            <div className='d-flex justify-content-between my-1'><span className='text-start'>Subtotal</span> <span className='text-end'>₹ {order_detail.amount.toFixed(2)}</span></div>
                                            <div className='d-flex justify-content-between text-muted my-1'><span className='text-start'>Shipping Charges</span> <span className='text-end'>₹ 00.00</span></div>
                                            <div className='d-flex justify-content-between text-muted my-1'><span className='text-start'>Taxes (Included in MRP)</span> <span className='text-end'>₹ 00.00</span></div>
                                            <div className='d-flex justify-content-between fw-semibold text-success my-1'><span className='text-start'><img src={Coupon} className='me-2' />Coupon Code (MAX40)</span> <span className='text-end'> ₹ 00.00</span></div>
                                            <Divider className='my-3' />
                                            <div className='d-flex justify-content-between fw-semibold my-1 mb-0'><h6 className='text-start'>Total</h6> <h6 className='text-end'>₹ {order_detail.netamount.toFixed(2)}</h6></div>
                                        </div>
                                    </div>
                                </div>

                                <div className='card rounded-4 mb-3 border-success bg-success-subtle'>
                                    <div className='card-body py-3 bg-transparent d-flex align-items-center'>
                                        <img src={Coupon} className='me-2' />
                                        <div className='w-100'>
                                            <h6 className='text-success mb-0'>You have saved ₹ 00.00 on this order</h6>
                                        </div>
                                    </div>
                                </div>

                                <div className='d-flex mb-3'>
                                    <Button variant="contained" component={Link} href='/contact-us' sx={{ p: 2 }} onClick={() => { }} className='fw-light w-100 me-1' disableElevation>Contact Us</Button>
                                    {/* <Button variant="outlined" color='error' component={Link} href='' sx={{ p: 2 }} onClick={() => { }} className='fw-light w-100 ms-1' disableElevation>Cancel Order</Button> */}
                                </div>

                                <div className='d-flex'>
                                    <Button variant="outlined" component={Link} href='/' sx={{ p: 2 }} onClick={() => { }} className='fw-light w-100 me-1' disableElevation>Go back to home</Button>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        order_detail: state.order.order_detail,
    };
};

export default connect(mapStateToProps, null)(SuccessPage)