import { DashboardConstants } from '../_constants/dashboard.constants';
import { API_URL, showErrorMessage } from './utility.action'
import { history } from '../_helpers/history';

export const dashboardaction = {
    get_dashboard, get_department_list, get_collection_list, change_active_categories,
};

function get_dashboard() {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/dashboard_service/get_dashboard`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: DashboardConstants.GET_DASHBOARD_REQUEST } }
    function success(payload) { return { type: DashboardConstants.GET_DASHBOARD_SUCCESS, payload } }
    function failure(payload) { return { type: DashboardConstants.GET_DASHBOARD_FAILURE, payload } }
}

function get_department_list(department_id,innerWidth,innerHeight) {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token, "department_id": department_id ,innerWidth:innerWidth,innerHeight:innerHeight}

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/dashboard_service/get_department_list`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: DashboardConstants.GET_DEPARTMENT_LIST_REQUEST } }
    function success(payload) { return { type: DashboardConstants.GET_DEPARTMENT_LIST_SUCCESS, payload } }
    function failure(payload) { return { type: DashboardConstants.GET_DEPARTMENT_LIST_FAILURE, payload } }
}

function get_collection_list(department_id) {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token, "department_id": department_id }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/dashboard_service/get_collection_list`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: DashboardConstants.GET_COLLECTION_LIST_REQUEST } }
    function success(payload) { return { type: DashboardConstants.GET_COLLECTION_LIST_SUCCESS, payload } }
    function failure(payload) { return { type: DashboardConstants.GET_COLLECTION_LIST_FAILURE, payload } }
}

function change_active_categories(active_categories_id) {
   return {
        type: DashboardConstants.CHANGE_ACTIVE_CATEGORIES,
        payload: active_categories_id
    };
}
