import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar, Button,InputAdornment, ListItem, Paper, Link, List, DialogActions, Typography, DialogContent, ListItemText, Switch, ListSubheader, Avatar, colors, Badge, DialogTitle, ListItemButton, Fab, ListItemIcon, InputBase } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {  renderTextField } from '../../_helpers/CustomFields';
import {  reduxForm, SubmissionError, change,Field,formValueSelector } from 'redux-form'
import { connect } from 'react-redux';
import {customeraction } from '../../_actions/customer.action'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const email = value =>
    value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value) ?
        'Invalid email address' : undefined

const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
        errors.firstname = 'Please enter first name'
    }
    if (!values.lastname) {
        errors.lastname = 'Please enter last name'
    }
 /*    if (!values.email) {
        errors.email = 'Please enter email'
    } */
    if (!values.mobileno) {
        errors.mobileno = 'Please enter valid mobile no'
    }
    if (isNaN(values.mobileno)) {
        errors.mobileno = 'Please enter valid mobile no'
    }
    else if (values.mobileno.length !== 10) {
        errors.mobileno = 'Please enter valid mobile no'
    }
    return errors;
}



class Myprofile extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            Open: true,

        };
    }

    handleClose = () => {
        this.props.handleCloseCustomerProfile()
    };

    componentDidMount() {
        this.props.dispatch(customeraction.get_customer_detail());

    }

    onSubmit = (formdata) => {
        this.props.dispatch(customeraction.update_customer_detail(formdata));
        // this.props.handleCloseCustomerProfile() 
    }

    render() {
        const { handleSubmit } = this.props

        if (!this.props.customer_detail) {
            return null
        }
        return (
            <form >
                <Dialog
                    scroll="paper"
                    PaperProps={{
                        style: {
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            margin: 0,
                            bottom: 0,
                            maxHeight: "100%",
                            borderRadius: 0
                        },
                    }}

                    TransitionComponent={Transition}
                    open={this.state.Open}
                    // onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    fullWidth
                    maxWidth={'xs'}
                >
                    <DialogTitle className='p-0'>
                        <AppBar className='w-100 p-0' sx={{ position: 'relative' }} elevation={0}>
                            <Toolbar>
                                <Typography sx={{ flex: 1, }} className='fw-normal' variant="h6" component="div">
                                My Profile
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={this.handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </DialogTitle>

                    <DialogContent className='position-relative px-0' dividers>
                        <div className='row mx-3'>
                            <div className='col-md-12 pb-3'>
                                <Field
                                    name="firstname"
                                    id="firstname"
                                    type="text"
                                    label="First Name"
                                    placeholder="First Name"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps',maxLength: 10 }}
                            />  
                            </div>
                            <div className='col-md-12 pb-3'>
                                <Field
                                    name="lastname"
                                    id="lastname"
                                    type="text"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps',maxLength: 10 }}
                                />  
                            </div>
                            <div className='col-md-12 pb-3'>
                                <Field
                                    name="mobileno"
                                    id="mobileno"
                                    type="text"
                                    label="Mobile Number"
                                    placeholder="Mobile Number"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps',maxLength: 10,readOnly:true }}
                                />  
                            </div>
                            <div className='col-md-12 pb-3'>
                                <Field
                                    name="email"
                                    id="email"
                                    type="text"
                                    label="Email"
                                    placeholder="Email"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    validate={email}
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />  
                            </div>
                        </div>

                    </DialogContent>


                    <DialogActions className='d-flex flex-column'>

                        <div className='d-flex w-100'>
                            <Button variant="contained" sx={{ p: 2 }} className='m-1 w-100 fs-6 fw-light' onClick={handleSubmit(values => this.onSubmit({ ...values }))} disableElevation>Save | Update</Button>
                        </div>
                    </DialogActions>

                </Dialog>
            </form>
        );
    }
}


function mapStateToProps(state) {
    let initialValues = {}
    if (state.customer.customer_detail && state.customer.customer_detail.customer_id) {
        initialValues = state.customer.customer_detail
    }
    return {
        customer_detail: state.customer.customer_detail,
        initialValues: initialValues
    };
};


const selector = formValueSelector('myprofile')
Myprofile = connect(
    state => {
        const email = selector(state, 'email')
        return {
            email
        }
    }
)(Myprofile)


Myprofile = reduxForm({
    form: 'myprofile',
    validate,
    enableReinitialize: true,
})(Myprofile)


export default connect(mapStateToProps, null)(Myprofile)