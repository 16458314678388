import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IconButton, AppBar,DialogContent,Link, Toolbar, Button, Divider, InputBase, Paper, List, ListItem, Typography, ListItemText, ListItemIcon, ListItemAvatar, Snackbar, AlertTitle, Avatar, colors, Alert } from '@mui/material';
import UserSmileLineIcon from 'remixicon-react/UserSmileLineIcon';
import ArrowDropRightLineIcon from 'remixicon-react/ArrowDropRightLineIcon';
import ShoppingBag2LineIcon from 'remixicon-react/ShoppingBag2LineIcon';
import LockPasswordLineIcon from 'remixicon-react/LockPasswordLineIcon';
import LogoutCircleRLineIcon from 'remixicon-react/LogoutCircleRLineIcon';
import ArrowDropDownLineIcon from 'remixicon-react/ArrowDropDownLineIcon';
import MapPinLineIcon from 'remixicon-react/MapPinLineIcon';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import IndianFlag from '../../assets/india.svg'
import OtpInput from 'react-otp-input';
import { isMobile } from 'react-device-detect';
import { authenticationaction } from '../../_actions/authentication.action';
import AddressEdit from '../General/AddressEdit'
import Myprofile from '../General/Profile'


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

class LoginFlow extends Component {

    constructor(props) {
        super(props);
        this.state = {
            time: 0,
            otp:'',
            is_submit:true,
            isAddressOpen:false,
            is_add_edit_profile:false,
            number:"",

        };
    } 
    componentDidMount() {
        this.props.dispatch(authenticationaction.get_customer_info());
    }

    SendMobilenoOtp = (event) => {
        event.preventDefault();
        let formProps = {}
        formProps.mobileno = this.state.number;
        this.props.dispatch(authenticationaction.send_mobileno_otp(formProps))
        this.set_time_interval()
    }

    set_time_interval = () => {
        this.intervalID = setInterval(
            () => this.tick(),
            1000
        );
        this.setState({
            time: 60
        });
    }

    tick() {
        if (this.state.time <= 0) {
            clearInterval(this.intervalID);
            this.setState({
                time: 0
            });
        }
        else {
            this.setState({
                time: (this.state.time - 1)
            });
        }
    }

    handleChangeNumber = (event) => {
        this.setState({
            number: event.target.value,
        });
      };

    resend_code = (event) => {
        event.preventDefault();
        let formProps = {}
        formProps.mobileno = this.state.number;
        this.props.dispatch(authenticationaction.send_mobileno_otp(formProps))
        this.set_time_interval()
    }
      
    handleChangeOtp  = (otp) => {
        this.setState({
            otp: otp,
            is_submit:otp.toString().length == 6 ? false:true,
        });
    }
    handleOpenAddress  = () => {
        this.setState({
            isAddressOpen:true,
        });
    }
    
    handleCloseAddress  = () => {
        this.setState({
            isAddressOpen:false,
        });
    }

    edit_mobileno = () => {
        this.setState({
            time: 0,
            otp:'',
        });
        this.props.dispatch(authenticationaction.clear_authenticate_request())
    }

    onLogout = () => {
            localStorage.removeItem('customer_access_token');
            this.props.dispatch(authenticationaction.user_logout());
            window.location.reload();
    }    
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.customer_access_token) {
            return { open: false };
        }
        else return null;
    }

    handleClickOpen = () => {
        this.props.dispatch(authenticationaction.open_login_menu(true))
    };

    handleClose = () => {
        this.props.dispatch(authenticationaction.open_login_menu(false))
    };

    
    handleOpenCustomerProfile = () => {
        this.setState({ is_add_edit_profile: true });
    };
    handleCloseCustomerProfile= () => {
        this.setState({ is_add_edit_profile: false });
    };

    componentDidMount = () => {

       
    }
    
    onLogin = () => {
        let formProps = {}
        formProps.request_id = this.props.request_id
        formProps.otp = this.state.otp
        formProps.mobileno = this.state.number;
        this.props.dispatch(authenticationaction.authenticate_mobileno_otp(formProps))
    }
    
    render() {
        let isLoggedIn = false
        if (!(this.props.customer_access_token === null || this.props.customer_access_token === undefined)) {
            isLoggedIn = true
        }
        
        return (
            <div>
                {/* <Snackbar open={this.state.Snackbar} autoHideDuration={6000} onClose={() => this.setState({ Snackbar: false })}>
                    <Alert onClose={() => this.setState({ Snackbar: false })} severity="danger" sx={{ width: '100%' }}>
                        You have enterer wrong OTP.
                    </Alert>
                </Snackbar> */}
                
                <Button variant="text" className="text-dark fw-normal rounded-pill" startIcon={<UserSmileLineIcon />} endIcon={isLoggedIn ? <ArrowDropDownLineIcon /> : null} onClick={this.handleClickOpen}>
                    {isLoggedIn ? "Hi,"+this.props.firstname : "Login"}
                </Button> 
                
                {/* {isMobile ? <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    onClick={this.handleClickOpen}
                >
                    <UserSmileLineIcon />
                </IconButton> 
                :
                <Button variant="text" className="text-dark fw-normal rounded-pill" startIcon={<UserSmileLineIcon />} endIcon={this.state.isLoggedIn ? <ArrowDropDownLineIcon /> : null} onClick={this.handleClickOpen}>
                    {this.state.isLoggedIn ? "Hi, Deep" : "Login"}
                </Button> 
                } */}
                
              

                <Dialog
                    PaperProps={{
                        style: {
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            margin: 0,
                            bottom: 0,
                            maxHeight: "100%",
                            borderRadius: 0
                        },
                    }}

                    TransitionComponent={Transition}
                    open={this.props.is_open_login_dialog}
                    scroll="paper"
                    // onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    fullWidth
                    maxWidth={'xs'}
                >
                    <AppBar className='w-100 p-0' sx={{ position: 'relative' }} elevation={0}>
                        <Toolbar>
                            <Typography sx={{ flex: 1, }} className='fw-normal' variant="h6" component="div">
                                {isLoggedIn ? "User Menu" : "Quick Login / Register"}
                            </Typography>
                            <IconButton
                                edge="start"
                                color="inherit"
                                onClick={this.handleClose}
                                aria-label="close"
                            >
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    {isLoggedIn ? <List>
                        <ListItem button className='py-3' onClick={this.handleOpenCustomerProfile}>
                            <ListItemAvatar>
                                <Avatar className='bg-primary-subtle'>
                                    <UserSmileLineIcon className='text-primary' />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={this.props.fullname} secondary={this.props.mobileno} />
                            <ListItemIcon>
                                <ArrowDropRightLineIcon />
                            </ListItemIcon>
                        </ListItem>
                        <Divider />
                        <Link href="/orders" style={{"text-decoration":"none"}}>
                        <ListItem className='py-3' button>
                            <ListItemAvatar>
                                <Avatar className='bg-primary-subtle'>
                                    <ShoppingBag2LineIcon className='text-primary' />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="My Orders" secondary="All your past orders can be shown here" />
                            <ListItemIcon>
                                <ArrowDropRightLineIcon />
                            </ListItemIcon>
                        </ListItem>
                        </Link>
                        <Divider />
                        <ListItem button className='py-3' onClick={this.handleOpenAddress}>
                            <ListItemAvatar>
                                <Avatar className='bg-primary-subtle'>
                                    <MapPinLineIcon className='text-primary' />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="My Address" secondary="Add your address for faster checkouts." />
                            <ListItemIcon>
                                <ArrowDropRightLineIcon />
                            </ListItemIcon>
                        </ListItem>
                        <Divider />
                        {/* <ListItem button className='py-3'>
                            <ListItemAvatar>
                                <Avatar className='bg-primary-subtle'>
                                    <LockPasswordLineIcon className='text-primary' />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Change Password" secondary="A secure password protect your account" />
                            <ListItemIcon className='d-contents'>
                                <ArrowDropRightLineIcon />
                            </ListItemIcon>
                        </ListItem> */}
                        <Divider />
                        <ListItem button onClick={this.onLogout} className='py-3'>
                            <ListItemAvatar>
                                <Avatar className='bg-danger-subtle'>
                                    <LogoutCircleRLineIcon className='text-danger' />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary="Logout" primaryTypographyProps={{ className: "text-danger" }} />
                        </ListItem>
                    </List>
                        :
                        <div>{this.props.request_id ? <div className='p-3 position-relative'>
                            <p className='text-muted mb-2'>Enter 6 digit code sent to your mobile No.</p>
                            <div className='d-flex justify-content-between'>
                                <p className='text-muted'>+91-{this.state.number} <a href="#" onClick={() => this.edit_mobileno()}>Edit</a> </p>
                                {this.state.time === 0 ?
                                <a href="#" onClick={(event) => this.resend_code(event)}><h6>{"Resend OTP"} </h6></a> :
                                <h6 style={{ fontSize: "14px" }} className="text-center">{"Resend OTP (in " + this.state.time + " secs)"} </h6>}
                            </div>

                            <div className='mb-3 d-flex flex-row' style={{ flexGrow: 1 }}>

                                <OtpInput
                                    value={this.state.otp}
                                    onChange={this.handleChangeOtp}
                                    numInputs={6}
                                    containerStyle={{ width: '100%', justifyContent: "space-between" }}
                                    placeholder={<UserSmileLineIcon />}
                                    isInputNum={false}
                                    shouldAutoFocus={true}
                                    inputStyle={{ textAlign: "center", fontSize: "24px", fontWeight: 600 }}
                                    renderInput={(props) => <Paper
                                        component="form"
                                        elevation={0}
                                        className='border mx-1'
                                        sx={{ p: isMobile? 0:1, display: 'flex', alignItems: 'center', borderRadius: 1, background: "#F3F3F3" }}
                                    >
                                        <InputBase
                                            sx={{ mx: 1, flex: 1, textAlign: "center" }}
                                            inputProps={{ ...props }}
                                        />
                                    </Paper>}
                                />
                            </div>

                            <Button disabled={this.state.is_submit} variant="contained" sx={{ p: 2 }} className='w-100 fs-6 fw-light mb-3' onClick={this.onLogin} disableElevation>Login</Button>
                            {this.props.error_description ? <Alert severity="error" variant="filled"><AlertTitle className='mb-0'>You have entered a wrong OTP.</AlertTitle></Alert> : null}


                        </div>
                            :
                            <div className='p-3 position-relative'>
                                <p className='text-muted'>Get access to your orders, Addresses, Payment methods</p>

                                <div className='mb-0' style={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                                    <Paper
                                        component="form"
                                        elevation={0}
                                        className='border mb-3'
                                        sx={{ p: '12px 16px', display: 'flex', alignItems: 'center', borderRadius: 1, background: "#F3F3F3" }}
                                    >
                                        <div className='d-flex align-items-center'>
                                            <img src={IndianFlag} />
                                            <p className='fw-semibold mb-0 mx-2'>+91</p>
                                        </div>
                                        <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                                        <InputBase
                                            sx={{ ml: 1, flex: 1 }}
                                            value={this.state.number}
                                            onChange={this.handleChangeNumber}
                                            placeholder="Enter Mobile Number"
                                            inputProps={{ 'aria-label': 'search google maps', maxLength: 10 }}
                                        />
                                    </Paper>
                                </div>

                                <Button variant="contained" sx={{ p: 2 }} onClick={(event) => this.SendMobilenoOtp(event)} className='w-100 fs-6 fw-light' disableElevation>Send OTP</Button>

                            </div>

                        }</div>
                    }


                    <p className='text-muted position-absolute start-0 bottom-0 p-3'>By continuing, you agree with our <a href='/privacy-policy'>Privacy Policy</a> and <a href='/tnc'>Terms and Conditions</a>.</p>
                </Dialog>
                <Dialog
                    PaperProps={{
                        style: {
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            margin: 0,
                            bottom: 0,
                            maxHeight: "100%",
                            borderRadius: 0
                        },
                    }}
                    TransitionComponent={Transition}
                    open={this.state.isAddressOpen}
                    scroll="paper"
                    // onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    fullWidth
                    maxWidth={'xs'}
                 >
                    <DialogContent>
                        <AddressEdit handleCloseAddress={this.handleCloseAddress}/>
                    </DialogContent>
                </Dialog>
                <Dialog
                    PaperProps={{
                        style: {
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            margin: 0,
                            bottom: 0,
                            maxHeight: "100%",
                            borderRadius: 0
                        },
                    }}
                    TransitionComponent={Transition}
                    open={this.state.is_add_edit_profile}
                    scroll="paper"
                    aria-labelledby="responsive-dialog-title"
                    fullWidth
                    maxWidth={'xs'}
                 >
                    <DialogContent>
                        <Myprofile handleCloseCustomerProfile={this.handleCloseCustomerProfile}/>
                    </DialogContent>
                </Dialog>
            </div>
        );
    }
}

function mapStateToProps(state) {
    let customer_access_token = localStorage.getItem('customer_access_token')
    let customer_detail  = state.authentication.customer_detail
    let fullname=""
    let mobileno=""
    let firstname=""
    if(customer_detail){
        fullname = customer_detail?.firstname+" "+customer_detail?.lastname
        firstname = customer_detail?.firstname
        mobileno = customer_detail?.mobileno
    }

    return {
        request_id: state.authentication.request_id,
        error_description: state.authentication.error_description,    
        customer_access_token:customer_access_token,
        customer_detail: state.authentication.customer_detail,
        is_open_login_dialog:state.authentication.is_open_login_dialog,
        fullname,
        mobileno,
        firstname
    };
}
export default connect(mapStateToProps, null)(LoginFlow);


