import React, { Component } from 'react';
import Dialog from '@mui/material/Dialog';
import { history } from '../../_helpers/history';

class Imageblock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            video_url: ""
        }
    }

    click_image = (item) => {
        if (item.urlType === "department") {
            history.push("/dep/" + (item.department.department_name).replace(/[^A-Z0-9]/ig, "-").toLowerCase() + "/" + item.department._id);
        } else if(item.urlType === "items"){
            history.push("/item/" + (item.item.item_name).replace(/[^A-Z0-9]/ig, "-").toLowerCase() + "/" + item.item.item_id);
        }else if(item.urlType === "collection"){
            history.push("/col/" + (item.collection.collection_name).replace(/[^A-Z0-9]/ig, "-").toLowerCase() + "/" + item.collection.collection_id);
        }
        else if (item.urlType === "promo") {
            if (item.url !== "" && item.url !== undefined && item.url !== null) {
                window.open(item.url)
            }
        }
        else if (item.urlType === "video") {
            this.setState({
                open: true,
                video_url: item.url
            })
        }
    }


    handleClose = () => {
        this.setState({
            open: false,
            video_url: ""
        })
    }

    render() {

        if (!this.props.values.imgs) {
            return null;
        }

        var items = this.props.values.imgs;
        return (
            <section className='py-3'>
                <div className='container'>
                    <div className="row pt-3 pb-3">
                        <div className="row">
                            {items.map((item, index) => {
                                return <div style={{ cursor: "pointer" }} className={"pt-2 col-md-12"} key={index} onClick={() => this.click_image(item)}>
                                    <img className="img-fluid rounded-lg" src={item.image_url} alt={item.alterText} />
                                </div>
                            })}
                        </div>
                        <Dialog
                            open={this.state.open}
                            aria-labelledby="item-name-settings"
                            maxWidth="md"
                            onClose={this.handleClose}
                            fullWidth>
                            <iframe height={720} frameBorder={0} src={this.state.video_url} ></iframe>
                        </Dialog>
                    </div>
                </div>
            </section>
        )
    }
}

export default Imageblock;
