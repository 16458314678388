import React from 'react'
import { connect } from 'react-redux';
import { Field, reduxForm, formValueSelector, SubmissionError } from 'redux-form'
import { renderTextField } from '../../_helpers/reduxFields';
import Button from '@mui/material/Button';
import { useLocation  } from 'react-router-dom';


const validate = (values) => {
    const errors = {};
    if (!values.password) {
        errors.password = 'Please enter password'
    }
    return errors;
}


class Siteauthenticate extends React.Component {

    constructor(props) {
        super(props);
    }



    onSubmit = (formProps) => {
        if (formProps.password !== this.props.general_data.store_password) {
            const errors = {};
            errors.password = 'Please enter valid password'
            throw new SubmissionError(errors)
        }
        var site_auth = {}
        site_auth.store_password = formProps.password;
        site_auth.date = new Date();
        localStorage.setItem('site_auth', JSON.stringify(site_auth));
        // location.reload();
    }

    render() {

        const onSubmit = this.onSubmit.bind(this);
        const { handleSubmit } = this.props;
        return (
            <form onSubmit={handleSubmit(onSubmit)} >
                <div className="container pt-5" >
                    <div className="col-md-5 pt-5 mx-auto">
                        <div className="card shadow">
                            <div className="card-body text-center">
                                <img height={40} src={this.props.general_data.site_logo}></img>
                                <h5 className='pt-5'>Authentication Required</h5>
                                <hr></hr>
                                <Field
                                    name="password"
                                    type="text"
                                    label="Password"
                                    component={renderTextField}
                                    fullWidth={true}
                                    autoComplete="off"
                                    margin="dense"
                                    variant="outlined" />
                                <Button type="submit" className="mt-3" variant="contained" color="primary" fullWidth >Submit</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        );
    }
}

function mapStateToProps(state) {
    return {
        general_data: state.general.general_data,
    };
};

Siteauthenticate = reduxForm({
    form: 'siteauthenticate',
    validate,
    enableReinitialize: true,
})(Siteauthenticate);


export default connect(mapStateToProps, null)(Siteauthenticate)

