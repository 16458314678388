

import { AuthenticationConstants } from '../_constants/authentication.constants';


export function authentication(state = {}, action) {
    // console.log('authentication',action.type)
    switch (action.type) {
        case AuthenticationConstants.CLEAR_AUTHENTICATE_REQUEST:
            return {
                ...state,
                request_id: null,
                error_description: null,
            };
        case AuthenticationConstants.SEND_MOBILENO_OTP_REQUEST:
            return {
                ...state,
                request_id: null,
                error_description: null
            };
        case AuthenticationConstants.SEND_MOBILENO_OTP_SUCCESS:
            return {
                ...state,
                request_id: action.payload.data.request_id,
            };
        case AuthenticationConstants.SEND_MOBILENO_OTP_FAILURE:
            return {
                ...state,
                request_id: null,
            };
        case AuthenticationConstants.AUTHENTICATE_MOBILENO_OTP_REQUEST:
            return {
                ...state,
                error_description: null,
                customer_detail: null,
                customer_access_token: null
            };
        case AuthenticationConstants.AUTHENTICATE_MOBILENO_OTP_SUCCESS:
            return {
                ...state,
                error_description: null,
                customer_detail: action.payload.data.customer_detail,
                customer_access_token: action.payload.data.customer_access_token
            };
        case AuthenticationConstants.AUTHENTICATE_MOBILENO_OTP_FAILURE:
            return {
                ...state,
                error_description: action.payload.error_description,
                customer_detail: null,
                customer_access_token: null
            };
        case AuthenticationConstants.USER_LOGOUT:
            return {
                ...state,
                customer_detail: null,
                customer_access_token: null
            };
        case AuthenticationConstants.OPEN_DIALOG_LOGIN_MENU:
            return {
                ...state,
                is_open_login_dialog:action.payload
            };
        default:
            return state
    }
}




