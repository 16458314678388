

import { OtherConstants } from '../_constants/other.constants';
var _ = require('lodash');

export function other(state = {}, action) {
    // console.log('otherrr',action.type)
    switch (action.type) {
        case OtherConstants.GET_STATIC_CONTENT_REQUEST:
            return {
                ...state,
                content: null
            };
        case OtherConstants.GET_STATIC_CONTENT_SUCCESS:
            return {
                ...state,
                content: action.payload.data,
            };
        case OtherConstants.GET_STATIC_CONTENT_FAILURE:
            return {
                ...state,
                content: null,
            };
        case OtherConstants.GET_STORE_LIST_REQUEST:
            return {
                ...state,
                store_list: []
            };
        case OtherConstants.GET_STORE_LIST_SUCCESS:

            var city_store_list = _(action.payload.data.store_list)
                .groupBy('city')
                .map((items, city) => ({ city, count: items.length }))
                .value();

            return {
                ...state,
                store_list: action.payload.data.store_list,
                city_store_list: city_store_list,
            };
        case OtherConstants.GET_STORE_LIST_FAILURE:
            return {
                ...state,
                store_list: [],
            };
        default:
            return state
    }
}




