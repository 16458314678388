import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar, Typography, Button, Chip, Divider, InputBase, Paper, Badge, Card, CardMedia, CardContent, CardActionArea, Link } from '@mui/material';
import TestVideo from "../assets/test_video.mp4"
import FullBanner from "../assets/banner_full.jpg"
import MainHeader from '../components/General/MainHeader';
import TopHeader from '../components/General/TopHeader';
import BannersHome from '../components/LandingPage/BannersHome';
import TopProductsSection from '../components/LandingPage/TopProductsSection';
import AllCategories from '../components/LandingPage/AllCategories';
import BlogSection from '../components/LandingPage/BlogSection';
import Footer from '../components/General/Footer';
import { isMobile } from 'react-device-detect';

class ReturnExchange extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (
      <section className={`py-5 ${isMobile ? "pt-0" : null}`}>
        <div className='container'>
          <h2>Return and Exchange</h2>
          <hr></hr>
          <p className="small">Dear Customer,</p>
          <p className="small">As our products are fresh produce and food products, for the sake of Hygiene, we are unable to offer any returns or exchanges of goods.</p>
          <p className="small">However, if you have any complaint regarding quality , wrong items or wrong quantities being supplied, please contact our customer care by filling Customer Care form. Please mention Invoice No. Reference.</p>
          <p className="small">Thank you for your understanding.</p>

          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          
        </div>
      </section>

    );
  }
}

export default ReturnExchange;