

import { DashboardConstants } from '../_constants/dashboard.constants';


export function dashboard(state = {}, action) {
    // console.log('dashboard',action.type)
    switch (action.type) {
        case DashboardConstants.GET_DASHBOARD_REQUEST:
            return {
                ...state,
                dashboard: null,
            };
        case DashboardConstants.GET_DASHBOARD_SUCCESS:
            return {
                ...state,
                content: action.payload.data.content,
            };
        case DashboardConstants.GET_DASHBOARD_FAILURE:
            return {
                ...state,
                dashboard: [],
            };
        case DashboardConstants.GET_DEPARTMENT_LIST_REQUEST:
            return {
                ...state,
                department_list: null,
            };
        case DashboardConstants.GET_DEPARTMENT_LIST_SUCCESS:
            let active_categories_id = null
            if(action.payload.data.department_list && action.payload.data.department_list.length > 0){
                active_categories_id =action.payload.data.department_list[0].department_id
            }
            return {
                ...state,
                department_list: action.payload.data.department_list,
                active_categories_id : active_categories_id
            };
        case DashboardConstants.GET_DEPARTMENT_LIST_FAILURE:
            return {
                ...state,
                department_list: [],
            };
        case DashboardConstants.GET_COLLECTION_LIST_REQUEST:
            return {
                ...state,
                collection_list: null,
            };
        case DashboardConstants.GET_COLLECTION_LIST_SUCCESS:
            return {
                ...state,
                collection_list: action.payload.data.collection_list,
            };
        case DashboardConstants.GET_COLLECTION_LIST_FAILURE:
            return {
                ...state,
                collection_list: [],
            };
        case DashboardConstants.CHANGE_ACTIVE_CATEGORIES:
            return {
                ...state,
                active_categories_id: action.payload,
            };
        default:
            return state
    }
}




