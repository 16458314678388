import React from 'react';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form'
import {  Button, Link } from '@mui/material';
import { IMAGE_URL } from '../../_actions/utility.action';

class RazerpayWallet extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            wallet: "",
            error_msg: ""
        };
    }

    place_order_wallet = (values) => {
        if (this.state.wallet === "") {
            this.setState({ error_msg: "Please select wallet" });
            return false
        }
        var data = {}
        data.method = 'wallet';
        data.wallet = this.state.wallet;
        this.props.place_order(data)
    }
  
    onWalletMethodClick = (value) => {
        this.setState({ wallet: value, error_msg: "" });
    }
    WalletName(key){
        if(key === "onWalletMethod"){
            return "Freecharge" 
        }
        else if(key === "jiomoney"){
            return "JioMoney"
        }
        else if(key === "mobikwik"){
            return "Mobikwik"
        }
        else if(key === "olamoney"){
            return "Ola Money"
        }
        else if(key === "phonepe"){
            return "Phonepe"
        }
        else if(key === "amazonpay"){
            return "Amazon Pay"
        }
        else{
            return ""
        }
    }
    render() {
        console.log(this.props)
        const { handleSubmit } = this.props;
        return (
            <div className='card-body py-3 bg-transparent'>
                <div className='row'>
                {Object.keys(this.props.wallet).map((key, index) => (<>
                    {this.props.wallet[key] ?
                    <div className='mx-2 cursor-pointer w-fit-content d-flex align-items-center flex-column' onClick={() => this.onWalletMethodClick(key)}>
                        <div
                            className={`mb-2 d-flex align-items-center justify-content-center border rounded-4 ${this.state.wallet === key && "border-3 border-primary"}`}
                            style={{ height: 72, width: 72 }}
                        >
                            <img
                                src={IMAGE_URL + key + ".png"} 
                                className='rounded-pill'
                                width={36}
                                alt=''
                            />
                        </div>
                        <p className={`small text-center ${this.state.wallet === key && "fw-semibold text-primary"}`}>{key}</p>
                    </div>
                    : null}
                    </>
                ))}

                </div>
                {this.state.wallet !== "" && <Button variant="contained" sx={{ p: 2 }} onClick={handleSubmit(values => this.place_order_wallet({ ...values }))} className='fw-light w-100' disableElevation>Place Order & Pay ₹{this.props.total_amount}</Button>}
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        total_amount: state.payment.total_amount,
    };
}

RazerpayWallet = reduxForm({
    form: 'payment_form',
    destroyOnUnmount: false,
})(RazerpayWallet);

export default connect(mapStateToProps, null)(RazerpayWallet);

