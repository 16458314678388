import { createTheme } from '@mui/material/styles';

const ThemeZoetic = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#2E3192',
      light: '#757ce8',
    },
    secondary: {
      main: '#A6CE39',
    },
    success: {
      main: '#289672',
    },
    warning: {
      main: '#FFA931',
    },
    info: {
      main: '#5F9DF7',
    },
    error: {
      main: '#E64848',
    },
    background: {
      default: '#F6F9FE',
    },
    common: {
      white: '#ffffff',
      black: '#343434'
    },
  },
  typography: {
    fontFamily: 'Onest',
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTooltip: {
      arrow: true,
    },
  },
  components: {
    // Table Pagination
    MuiTablePagination: {
      styleOverrides: {
        selectLabel: {
          marginBottom: 0
        },
        displayedRows: {
          marginBottom: 0
        }
      },
    },
    // Table Scrollbar (Without Transition)
    // MuiTableContainer: {
    //   styleOverrides: {
    //     root: {
    //       overflow: 'hidden',
    //       transition: 'overflow 2s',
    //       ':hover': {
    //         overflow: 'auto',
    //       },
    //       '::-webkit-scrollbar': {
    //         width: 6,
    //         height: 6
    //       },
    //       '::-webkit-scrollbar-track': {
    //         background: '#ececec !important',
    //         borderRadius: 8,
    //       },
    //       '::-webkit-scrollbar-thumb': {
    //         background: '#1746A2 !important',
    //         borderRadius: 8,
    //       },
    //       '::-webkit-scrollbar-thumb:hover': {
    //         background: '#133986 !important'
    //       }
    //     }
    //   }
    // },

    // Table Scrollbar (With Transition)
    MuiTableContainer: {
      styleOverrides: {
        root: {
          color: 'rgba(21, 14, 159, 0)',
          transition: 'color .3s ease',
          ':hover': {
            color: 'rgba(21, 14, 159, 0.7)'
          },
          '::-webkit-scrollbar': {
            width: 6,
            height: 6,
            borderRadius: 13,
            backgroundClip: 'padding-box',
            border: '10px solid transparent'
          },
          '::-webkit-scrollbar-track': {
            display: 'none'
          },
          '::-webkit-scrollbar-thumb': {
            boxShadow: 'inset 0 0 0 10px',
            borderRadius: 8,
          },
          '::-webkit-scrollbar-thumb:hover': {
            color: 'rgba(21, 14, 159, 1)',
          }
        }
      }
    },
    // Divider 
    MuiDivider: { 
      styleOverrides: {
        root: {
          opacity: 'inherit',
          background: '#dee2e6 !important',
          borderColor: '#dee2e6 !important'
        }
      }
    },
    MuiButton: { 
        styleOverrides: {
          root: {
            textTransform: 'capitalize'
          }
        }
      },
  },

});

export default ThemeZoetic;