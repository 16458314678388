export const ItemConstants = {
    GET_ITEM_LIST_REQUEST: 'GET_ITEM_LIST_REQUEST',
    GET_ITEM_LIST_SUCCESS: 'GET_ITEM_LIST_SUCCESS',
    DASHBOARD_GET_ITEM_LIST_SUCCESS: 'DASHBOARD_GET_ITEM_LIST_SUCCESS',
    GET_ITEM_LIST_FAILURE: 'GET_ITEM_LIST_FAILURE',
    GET_ITEM_DETAIL_REQUEST: 'GET_ITEM_DETAIL_REQUEST',
    GET_ITEM_DETAIL_SUCCESS: 'GET_ITEM_DETAIL_SUCCESS',
    GET_ITEM_DETAIL_FAILURE: 'GET_ITEM_DETAIL_FAILURE',
    GET_ITEM_CART: 'GET_ITEM_CART',
    ADD_REMOVE_ITEM_CART: 'ADD_REMOVE_ITEM_CART',
    VARIANT_CHANGE: 'VARIANT_CHANGE',
    GET_PRODUCT_SEARCH_REQUEST: 'GET_PRODUCT_SEARCH_REQUEST',
    GET_PRODUCT_SEARCH_SUCCESS: 'GET_PRODUCT_SEARCH_SUCCESS',
    GET_PRODUCT_SEARCH_FAILURE: 'GET_PRODUCT_SEARCH_FAILURE',
    GET_PRODUCT_CART_REQUEST: 'GET_PRODUCT_CART_REQUEST',
    GET_PRODUCT_CART_SUCCESS: 'GET_PRODUCT_CART_SUCCESS',
    GET_PRODUCT_CART_FAILURE: 'GET_PRODUCT_CART_FAILURE',
    CLEAR_CART: 'CLEAR_CART',
    PIN_UNPIN_CART: 'PIN_UNPIN_CART',
    GET_CUSTOMER_ITEM_LIST_REQUEST: 'GET_CUSTOMER_ITEM_LIST_REQUEST',
    GET_CUSTOMER_ITEM_LIST_SUCCESS: 'GET_CUSTOMER_ITEM_LIST_SUCCESS',
    GET_CUSTOMER_ITEM_LIST_FAILURE: 'GET_CUSTOMER_ITEM_LIST_FAILURE',
    SELECT_FAVORITE_REQUEST: 'SELECT_FAVORITE_REQUEST',
    SELECT_FAVORITE_SUCCESS: 'SELECT_FAVORITE_SUCCESS',
    SELECT_FAVORITE_FAILURE: 'SELECT_FAVORITE_FAILURE',
    GET_ITEM_DETAIL_INCART_EDIT_REQUEST: 'GET_ITEM_DETAIL_INCART_EDIT_REQUEST',
    GET_ITEM_DETAIL_INCART_EDIT_SUCCESS: 'GET_ITEM_DETAIL_INCART_EDIT_SUCCESS',
    GET_ITEM_DETAIL_INCART_EDIT_FAILURE: 'GET_ITEM_DETAIL_INCART_EDIT_FAILURE',
    CLEAR_ITEM_LIST: 'CLEAR_ITEM_LIST',
    OPEN_CART_VIEW: 'OPEN_CART_VIEW',

};


