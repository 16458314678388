import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar, Button, Divider, InputBase, Paper, Badge, MenuItem, Typography, Menu, Fab, DialogTitle, DialogContent } from '@mui/material';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import ShoppingBag2LineIcon from 'remixicon-react/ShoppingBag2LineIcon';
import UserSmileLineIcon from 'remixicon-react/UserSmileLineIcon';
// import CircleFillIcon from 'remixicon-react/CircleFillIcon';
import LoginFlow from './LoginFlow';
import CartSidebar from './CartSidebar';
import Searchbar from './Searchbar';
import { connect } from 'react-redux';




class MainHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }
  render() {
    

    return (
      <AppBar position="sticky" elevation={0} className='text-dark border-bottom px-5 pt-2 main-header' style={{ backgroundColor: '#ffffffe2' }}>
        <Toolbar className='container'>
          <a href="/" className='mb-0' style={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
            <img src='https://zoeticayurvedics.com/Zoetic/images/logo.png' height={80} />
          </a>

          <Searchbar/>

          <div className='mb-0 d-flex justify-content-end align-items-center' style={{ flexGrow: 1, display: { xs: 'none', sm: 'block' }, }}>
            
            <LoginFlow/>
            <Divider sx={{ height: 28, mx: 1 }} orientation="vertical" />
            <CartSidebar cart={this.props.cart}/>
          </div>
        </Toolbar>

        <Toolbar className='container pt-2' variant="dense">
          <div style={{ flexGrow: 1 }}>
            <Button  href='/about-us' sx={{ fontWeight: 600, color: "#343434" }} className='me-1 ps-0'>
              About Us
            </Button>

            <Button href='/rnd' sx={{ fontWeight: 600, color: "#343434" }} className='mx-1'>
              Research & Development
            </Button>

            <Button href='/quality-control' sx={{ fontWeight: 600, color: "#343434" }} className='mx-1'>
              Quality Control
            </Button>

            <Button href='/blogpage' sx={{ fontWeight: 600, color: "#343434" }} className='mx-1'>
            Blog
            </Button>
          </div>

          <div className='d-flex align-items-center justify-content-end' style={{ flexGrow: 1 }}>
            {/* <Button sx={{ fontWeight: 400, color: "#343434" }} className='mx-1'>
              Help
            </Button> */}

            {/* <Button sx={{ fontWeight: 400, color: "#343434" }} className='mx-1'>
              Track Order
            </Button> */}

            <Button href='/orders'  sx={{ fontWeight: 400, color: "#343434" }} className='mx-1'>
              Orders
            </Button>

            {/* <Button sx={{ fontWeight: 400, color: "#343434" }} className='mx-1'>
              Wishlist
            </Button> */}

            <Button sx={{ fontWeight: 400, color: "#343434" }} className='mx-1'>
              Offers
            </Button>
          </div>
        </Toolbar>
      </AppBar>
    );
  }
}

function mapStateToProps(state) {
  return {
    cart: state.item.cart,
    total_qty: state.item.total_qty,
    amount: state.item.amount,
    delivery_charge: state.item.delivery_charge,
    total_amount: state.item.total_amount,
    free_shipping_order_amount: state.item.free_shipping_order_amount,
    wishlist_item: state.item.wishlist_item,
    pin_cart: state.item.pin_cart,
    customer_access_token: state.authentication.customer_access_token,
    is_special_instruction: state.item.is_special_instruction,
    is_cart_announcement: state.item.is_cart_announcement,
    cart_announcement: state.item.cart_announcement,
  };
}

export default connect(mapStateToProps, null)(MainHeader);
