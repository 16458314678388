import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Chip, Divider, IconButton } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import SubtractFillIcon from 'remixicon-react/SubtractFillIcon';
import CheckboxCircleFillIcon from 'remixicon-react/CheckboxCircleFillIcon';
import AddFillIcon from 'remixicon-react/AddFillIcon';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { isMobile } from 'react-device-detect';
import { itemaction } from '../_actions/item.action';

class ProductPage extends Component  {

    constructor(props) {
        super(props);
        let item_id = window.location.pathname.split("/").slice(-1)[0]
        this.state = {
            cartItem: 0,
            item_id:item_id,
            selectedVariant: "Variant 1"
        };
    }

    AddToCart = () => {
        this.setState({ cartItem: this.state.cartItem + 1 })
    }

    RemoveItem = () => {
        this.setState({ cartItem: this.state.cartItem - 1 })
    }
    componentDidMount() {
        this.props.dispatch(itemaction.get_item_detail(this.state.item_id,null))
    }
    
    add_remove_item_cart = (event, type, data, variant) => {

        event.stopPropagation()
        let custom_data = {}
        custom_data.item_id = data.item_id
        custom_data.item_name = data.item_name
        custom_data.feature_image = data.feature_image
        custom_data.item_sku = data.item_sku
        custom_data.variant_id = variant.variant_id
        custom_data.variant_label = variant.variant_label
        custom_data.selection_by = variant.selection_by
        custom_data.variant_sku = variant.variant_sku
        custom_data.value = variant.value
        custom_data.unit = variant.unit
        custom_data.mrp = variant.mrp
        custom_data.rate = variant.rate
        custom_data.inventory = variant.inventory
        custom_data.is_masterrate = data.is_masterrate
        custom_data.master_value = data.master_value
        custom_data.master_unit = data.master_unit
        custom_data.master_mrp = data.master_mrp
        custom_data.master_rate = data.master_rate
        custom_data.is_custom_weight = variant.is_custom_weight

        if (type === "CUSTOM_ADD") {
          if (this.state.custom_qty && this.state.custom_qty !== "") {
            if (parseFloat(this.state.custom_qty) > 0) {
              custom_data.value = this.state.custom_qty
            }
            else {
            //   this.textInput.current.focus();
              return
            }
          }
          else {
            // this.textInput.current.focus();
            return
          }
        }
        if(type === "QUICK_BUY"){
            type = "ADD"
            this.props.dispatch(itemaction.add_remove_item_cart('REMOVEALL', {}))
            this.props.dispatch(itemaction.open_cart_view(true))
        }
        this.props.dispatch(itemaction.add_remove_item_cart(type, custom_data))
        this.props.dispatch(itemaction.variant_change(custom_data.item_id, custom_data.variant_id))
        this.setState({
          custom_qty: null,
          custom_kg_qty: null,
          custom_gm_qty: null,
        });
    }
    render() {
        let variant_len = this.props.item_variant && this.props.item_variant.length || 0
        var item_detail = this.props.item_detail;
        let display_item_category_name =  item_detail?.display_item_category_name || ""

        if(variant_len <=0){
            return ;
        }
        let selectedVariantDetails = this.props.item_variant.filter(data => data.variant_id === this.state.selectedVariant)
        if (selectedVariantDetails.length > 0) {
            selectedVariantDetails = selectedVariantDetails[0]
          }else{
            selectedVariantDetails = this.props.item_variant[0]
            this.setState({
                selectedVariant:selectedVariantDetails.variant_id
            })
        }
        let offPrice = parseFloat(selectedVariantDetails.mrp) - parseFloat(selectedVariantDetails.rate)
        return (
            <div className='position-relative'>
                <section className={`py-5 ${ isMobile && "pt-3"}`}>
                    <div className='container'>
                        {isMobile && <div className='row'>
                            <div className='col-md-12'>
                                <Chip className='mb-1' label={item_detail.item_name} color="primary" variant="outlined" />
                                <h2 className='mb-3'>{item_detail.item_name}</h2>
                            </div>
                        </div>}
                        
                        <div className='row'>
                            <div className='col-md-5'>
                                <Carousel
                                    thumbWidth={72}
                                    className='Product-carousel'
                                    autoFocus
                                    infiniteLoop
                                    // showArrows={false}
                                    renderArrowPrev={(clickHandler, hasPrev, labelPrev) => {
                                        return (
                                            <div
                                                className={`${hasPrev ? "position-absolute" : "position-absolute"
                                                    } top-50 start-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-3`}
                                                onClick={clickHandler}
                                            >
                                                <p>{hasPrev}</p>
                                                <ArrowBackIosNewIcon className="text-black" />
                                            </div>
                                        );
                                    }}
                                    renderArrowNext={(clickHandler, hasNext, labelNext) => {
                                        return (
                                            <div
                                                className={`${hasNext ? "position-absolute" : "position-absolute"
                                                    } top-50 end-0 flex justify-center items-center p-3 opacity-30 hover:opacity-100 cursor-pointer z-3`}
                                                onClick={clickHandler}
                                            >
                                                <ArrowForwardIosIcon className="text-black" />
                                            </div>
                                        );
                                    }}

                                    showStatus={false}
                                    autoPlay={true}
                                    useKeyboardArrows={true}
                                    swipeScrollTolerance={0}
                                    emulateTouch={true}
                                    swipeable={true}
                                // renderArrowNext={() => <IconButton className='control-arrow control-prev' aria-label="delete"><DeleteIcon /></IconButton>}
                                >
                                    {item_detail && item_detail.item_image.map((item, i) => (
                                        <div key={i} className='rounded-4 border overflow-hidden'>
                                            {display_item_category_name &&
                                                <Chip label={display_item_category_name} color='primary' variant='outlined' className='rounded-2 fw-semibold position-absolute z-5' style={{left: 16, top: 16}}/>
                                            }
                                            <img src={item} />
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                            {isMobile ? null : <div className='col-md-1 align-items-center justify-content-center d-flex'>
                                <Divider orientation="vertical" className='position-absolute' sx={{ width: "1px" }} />
                            </div>}
                            {/* <div className='col-md-1 align-items-center justify-content-center d-flex'>
                                <Divider orientation="vertical" className='position-absolute' sx={{ width: "1px" }} />
                            </div> */}

                            <div className='col-md-6'>

                                {!isMobile && <div>
                                    {this.props.department_name &&
                                        <Chip className='mb-3' label={this.props.department_name} color="primary" variant="outlined" />
                                    }
                                    <h2 className='mb-3'>{item_detail.item_name}</h2>
                                </div>}

                                <div className='row mb-3'>
                                    <div className='col-md-12'>
                                        {this.props.item_variant && this.props.item_variant.map((variant, i) => (
                                            <Chip label={variant.value+" "+variant.unit} className='mx-1 mb-3' onDelete={() => {}} deleteIcon={this.state.selectedVariant == variant.variant_id ? <CheckCircleIcon/> : <></>} onClick={()=> this.setState({selectedVariant : variant.variant_id})} color={this.state.selectedVariant == variant.variant_id ? "primary" : "default"}/>
                                        ))}

                                        <div className="position-relative w-100">
                                            <label className="border p-3 w-100 d-flex align-items-center flex-row justify-content-between rounded-4 list-group-item py-3 " for="var_1">
                                                <div>
                                                    <strong className="fw-semibold"> {selectedVariantDetails.value+""+selectedVariantDetails.unit }</strong>
                                                    <span className="d-block small opacity-75 mb-0">
                                                        {selectedVariantDetails.variant_label}
                                                    </span>
                                                </div>
                                                <div className='d-flex flex-column justify-content-end align-items-end'>
                                                    <h6 className='mb-0'>
                                                        {offPrice >0 &&
                                                            <span className='mx-1 small text-muted text-decoration-line-through'>
                                                                {"₹ "+selectedVariantDetails.mrp}
                                                            </span>
                                                        }
                                                        {"₹ "+selectedVariantDetails.rate}
                                                    </h6>
                                                    {offPrice >0 &&
                                                        <h6 className='mb-0 badge bg-secondary border border-white border-4 text-dark p-2 rounded-pill '>{"₹"+offPrice} OFF</h6>
                                                    }
                                                </div>
                                            </label>
                                        </div>
                                        
                                        {/* <Chip label="variant 1" className='mx-2'/>
                                        <Chip label="variant 1" className='mx-2'/>
                                        <Chip label="variant 1" className='mx-2'/> */}
                                    </div>
                                </div>

                            
                                
                               

                                <div className='row mb-5'>
                                    {selectedVariantDetails.qty === 0 ? <div class="col">
                                        <Button variant="contained" sx={{ p: 2 }} className='w-100 fs-6 fw-light' onClick={(event) => this.add_remove_item_cart(event, 'ADD', item_detail, selectedVariantDetails)} disableElevation>Add to cart</Button>
                                        </div>
                                        :
                                        <div class="col">
                                            <div className='card h-100 border-primary d-flex align-items-center justify-content-center flex-row'>
                                                <IconButton onClick={(event) => this.add_remove_item_cart(event, 'REMOVE', item_detail, selectedVariantDetails)} aria-label="delete" color='primary'><SubtractFillIcon /></IconButton>
                                                <h5 className='mb-0 mx-2'>{selectedVariantDetails.qty}</h5>
                                                <IconButton onClick={(event) => this.add_remove_item_cart(event, 'ADD', item_detail, selectedVariantDetails)} aria-label="delete" color='primary'><AddFillIcon /></IconButton>
                                            </div>
                                        </div>
                                    }
                                    <div class="col">
                                        <Button variant="contained" sx={{ p: 2 }} className='w-100 fs-6 fw-light' color='secondary' onClick={(event) => this.add_remove_item_cart(event, 'QUICK_BUY', item_detail, selectedVariantDetails)} disableElevation>Quick Buy</Button>
                                    </div>
                                </div>

                                {item_detail.is_itemdescription_tab? 
                                 item_detail && item_detail.itemdescription_tab.map((itemdescription, index) =>
                                    <div className='mb-3'>
                                    <h5 className='fw-semibold pb-2 border-bottom'>{itemdescription.label}</h5>
                                    <div dangerouslySetInnerHTML={{ __html: itemdescription.description }}></div>
                                    </div> 
                                  )
                                :null 
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}
function mapStateToProps(state,props) {
    let item_variant = [];
    let department_name = ""
    let breadcrumb = state.item.breadcrumb
    if (state.item.item_detail && state.item.item_detail.item_variant) {
    item_variant = state.item.item_detail.item_variant;
    }
    if(breadcrumb && breadcrumb.slice(-2)[0]){
        department_name = breadcrumb.slice(-2)[0].name
    }
    return {
    item_detail: state.item.item_detail,
    item_variant: [...item_variant],
    department_name:department_name,
    breadcrumb: breadcrumb,
    general_data: state.general.general_data,
    };
}
export default connect(mapStateToProps)(ProductPage);
