

import { RecipeConstants } from '../_constants/recipe.constants';
const _ = require("lodash")


export function recipe(state = {}, action) {
    switch (action.type) {
        case RecipeConstants.GET_CUSTOMER_RECIPE_LIST_REQUEST:
            return {
                ...state,
                customer_recipe_list: null,
            };
        case RecipeConstants.GET_CUSTOMER_RECIPE_LIST_SUCCESS:
            return {
                ...state,
                customer_recipe_list: action.payload.data.customer_recipe_list,
            };
        case RecipeConstants.GET_CUSTOMER_RECIPE_LIST_FAILURE:
            return {
                ...state,
                customer_recipe_list: false,
            };
        case RecipeConstants.GET_CUSTOMER_RECIPE_DETAIL_REQUEST:
            return {
                ...state,
                recipe_detail: null,
                ingredient_list: [],
                cooking_steps: [],
                image_list: []
            }
        case RecipeConstants.GET_CUSTOMER_RECIPE_DETAIL_SUCCESS:
            return {
                ...state,
                recipe_detail: action.payload.data.recipe_detail,
                ingredient_list: action.payload.data.recipe_detail.ingredient_list,
                cooking_steps: action.payload.data.recipe_detail.cooking_steps,
                image_list: action.payload.data.recipe_detail.image_list
            }
        case RecipeConstants.GET_CUSTOMER_RECIPE_DETAIL_FAILURE:
            return {
                ...state,
                recipe_detail: {},
                image_list: [],
            }
        case RecipeConstants.CUSTOMER_RECIPE_NEW:
            return {
                ...state,
                recipe_detail: {
                    recipe_images: []
                },
                ingredient_list: [],
                cooking_steps: [],
                image_list: []
            }
        case RecipeConstants.ADD_INGREDIENT:
            if (!state.ingredient_list) {
                state.ingredient_list = []
                state.ingredient_list.push({
                    id: 0,
                    ingredient_name: "",
                    unit: "",
                    quantity: "",
                })
            } else {
                state.ingredient_list.unshift({
                    id: 0,
                    ingredient_name: "",
                    unit: "",
                    quantity: "",
                })
            }
            return {
                ...state,
                ingredient_list: [...state.ingredient_list],
            };
        case RecipeConstants.SAVE_INGREDIENT:
            state.ingredient_list = state.ingredient_list.filter(item => item.id !== 0)
            if (action.payload.row.row.id == 0 && action.payload.ingredient_name) {
                var randomstring = require("randomstring");
                state.ingredient_list.push({
                    id: parseInt(randomstring.generate({ length: 8, charset: 'numeric' })),
                    ingredient_name: action.payload.ingredient_name, unit: action.payload.unit, quantity: action.payload.quantity
                })
            }
            else {
                state.ingredient_list.map(item => {
                    if (action.payload.row.row.id === item.id) {
                        item.ingredient_name = action.payload.ingredient_name
                        item.unit = action.payload.unit
                        item.quantity = action.payload.quantity
                    }
                })
            }
            return {
                ...state,
                ingredient_list: [...state.ingredient_list],
            };
        case RecipeConstants.DELETE_INGREDIENT:
            return {
                ...state,
                ingredient_list: [...state.ingredient_list.filter(item => item.id !== action.payload)],
            };

        case RecipeConstants.ADD_COOKING_STEP:
            if (!state.cooking_steps) {
                state.cooking_steps = []
            }
            state.cooking_steps.push({
                id: 0,
                step: "",
            })
            return {
                ...state,
                cooking_steps: [...state.cooking_steps],
            };
        case RecipeConstants.SAVE_COOKINF_STEP:
            state.cooking_steps = state.cooking_steps.filter(item => item.id !== 0)
            if (action.payload.row.row.id == 0 && action.payload.step) {
                var randomstring = require("randomstring");
                state.cooking_steps.push({
                    id: parseInt(randomstring.generate({ length: 8, charset: 'numeric' })),
                    step: action.payload.step
                })
            }
            else {
                state.cooking_steps.map(item => {
                    if (action.payload.row.row.id === item.id) {
                        item.step = action.payload.step
                    }
                })
            }
            return {
                ...state,
                cooking_steps: [...state.cooking_steps],
            };
        case RecipeConstants.DELETE_COOKINF_STEP:
            return {
                ...state,
                cooking_steps: [...state.cooking_steps.filter(item => item.id !== action.payload)],
            };
        default:
            return state
    }
}




