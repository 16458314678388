

export const DashboardConstants = {
    GET_DASHBOARD_REQUEST: 'GET_DASHBOARD_REQUEST',
    GET_DASHBOARD_SUCCESS: 'GET_DASHBOARD_SUCCESS',
    GET_DASHBOARD_FAILURE: 'GET_DASHBOARD_FAILURE',
    GET_DEPARTMENT_LIST_REQUEST: 'GET_DEPARTMENT_LIST_REQUEST',
    GET_DEPARTMENT_LIST_SUCCESS: 'GET_DEPARTMENT_LIST_SUCCESS',
    GET_DEPARTMENT_LIST_FAILURE: 'GET_DEPARTMENT_LIST_FAILURE',
    GET_COLLECTION_LIST_REQUEST: 'GET_COLLECTION_LIST_REQUEST',
    GET_COLLECTION_LIST_SUCCESS: 'GET_COLLECTION_LIST_SUCCESS',
    GET_COLLECTION_LIST_FAILURE: 'GET_COLLECTION_LIST_FAILURE',
    CHANGE_ACTIVE_CATEGORIES : 'CHANGE_ACTIVE_CATEGORIES',
};


