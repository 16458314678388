

import { CustomerConstants } from '../_constants/customer.constants';


export function customer(state = {}, action) {
    // console.log('customer',action.type)
    switch (action.type) {
        case CustomerConstants.GET_CUSTOMER_ADDRESS_LIST_REQUEST:
            return {
                ...state,
                customer_address_list: null,
                is_updated: null,
            };
        case CustomerConstants.GET_CUSTOMER_ADDRESS_LIST_SUCCESS:
            return {
                ...state,
                customer_address_list: action.payload.data.customer_address_list,
            };
        case CustomerConstants.GET_CUSTOMER_ADDRESS_LIST_FAILURE:
            return {
                ...state,
                customer_address_list: false,
            };
        case CustomerConstants.GET_CUSTOMER_ADDRESS_DETAIL_REQUEST:
            return {
                ...state,
                customer_detail: null,
            };
        case CustomerConstants.GET_CUSTOMER_ADDRESS_DETAIL_SUCCESS:
            return {
                ...state,
                customer_detail: action.payload.data,
            };
        case CustomerConstants.GET_CUSTOMER_ADDRESS_DETAIL_FAILURE:
            return {
                ...state,
                customer_detail: false,
            };
        case CustomerConstants.CUSTOMER_ADDRESS_NEW:
            return {
                ...state,
                customer_detail: false,
            };
        case CustomerConstants.IN_UP_CUSTOMER_ADDRESS_REQUEST:
            return {
                ...state,
                is_updated: null,
            };
        case CustomerConstants.IN_UP_CUSTOMER_ADDRESS_SUCCESS:
            return {
                ...state,
                is_updated: true,
            };
        case CustomerConstants.IN_UP_CUSTOMER_ADDRESS_FAILURE:
            return {
                ...state,
                is_updated: null,
            };
        case CustomerConstants.GET_CUSTOMER_DETAIL_REQUEST:
            return {
                ...state,
                customer_detail: null,
            };
        case CustomerConstants.GET_CUSTOMER_DETAIL_SUCCESS:
            return {
                ...state,
                customer_detail: action.payload.data,
            };
        case CustomerConstants.GET_CUSTOMER_DETAIL_FAILURE:
            return {
                ...state,
                customer_detail: null,
            };
        case CustomerConstants.GET_STATE_LIST_REQUEST:
            return {
                ...state,
                state_list: [],
            };
        case CustomerConstants.GET_STATE_LIST_SUCCESS:
            return {
                ...state,
                state_list: action.payload.data.state_list
            };
        case CustomerConstants.GET_STATE_LIST_FAILURE:
            return {
                ...state,
                state_list: []
            };
        case CustomerConstants.UPDATE_CUSTOMER_MOBILENO_REQUEST:
            return {
                ...state,
                request_id: null,
            };
        case CustomerConstants.UPDATE_CUSTOMER_MOBILENO_SUCCESS:
            return {
                ...state,
                request_id: action.payload.data.request_id,
            };
        case CustomerConstants.UPDATE_CUSTOMER_MOBILENO_FAILURE:
            return {
                ...state,
                request_id: null,
            };
        case CustomerConstants.GET_CUSTOMER_ITEM_LIST_REQUEST:
            return {
                ...state,
                customer_item_list: null,
            };
        case CustomerConstants.GET_CUSTOMER_ITEM_LIST_SUCCESS:
            return {
                ...state,
                customer_item_list: action.payload.data.customer_item_list,
            };
        case CustomerConstants.GET_CUSTOMER_ITEM_LIST_FAILURE:
            return {
                ...state,
                customer_item_list: [],
            };
        case CustomerConstants.GET_PRODUCT_SEARCH_REQUEST:
            return {
                ...state,
                item_search_list: [],
            };
        case CustomerConstants.GET_PRODUCT_SEARCH_SUCCESS:
            return {
                ...state,
                item_search_list: action.payload.data.item_list,
            };
        case CustomerConstants.GET_PRODUCT_SEARCH_FAILURE:
            return {
                ...state,
                item_search_list: [],
            };
        default:
            return state
    }
}




