import React, { Component } from 'react';
import ProductCard from '../General/ProductCard';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';


class TopProductsSection extends Component {
    render() {
        const { values } = this.props;
        if(!values){
         return ;
        }
        return (
            <section className='py-3'> 
                <div className='container'>
                    <h3 className='fw-semibold mb-3'>{values.department_name}</h3>
                    
                    <div className='row'>
                        {values.item_list && values.item_list.map((item, index) => {
                            let itmed  = this.props.item_list && this.props.item_list.length > 0 ? this.props.item_list.filter(item_d => item_d._id == item._id)[0]:[]
                            let item_variant = itmed && itmed.item_variant[0]
                            return <div className={`col-md-3 col-6 ${isMobile ? "p-3 mb-0" : "mb-3"}`} style={{outline: isMobile ? "1px solid rgba(40,44,63,.05)" : null}} >
                                <ProductCard Image={item.feature_image} ImageHover={item.hover_image?item.hover_image:item.feature_image} Name={item.item_name} Price={item_variant.rate} item={itmed} />
                            </div>
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    return {
        item_list: state.item.item_list,
    };
}
export default connect(mapStateToProps)(TopProductsSection);
