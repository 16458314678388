import React, { Component } from 'react';
import { Badge, Button, Chip, Divider, IconButton } from '@mui/material';
import ArrowDownSLineIcon from 'remixicon-react/ArrowDownSLineIcon';
import CoolantImg from "../../assets/coolant.jpg";
import CoolantImgHover from "../../assets/coolant_hover.jpg";
import Stripes from "../../assets/stripes.svg";
import { isMobile } from 'react-device-detect';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SubtractFillIcon from 'remixicon-react/SubtractFillIcon';
import AddFillIcon from 'remixicon-react/AddFillIcon';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import { itemaction } from '../../_actions/item.action';
import { connect } from 'react-redux';

class ProductCard extends Component {

    constructor(props) {
        super(props);
        this.state = {
            hovererImage: false,
            anchorEl: null,
            cartItem: 0

        };
    }
    add_remove_item_cart = (event, type, data, variant) => {
        event.stopPropagation()
        let custom_data = {}
        custom_data.item_id = data.item_id
        custom_data.item_name = data.item_name
        custom_data.feature_image = data.feature_image
        custom_data.item_sku = data.item_sku
        custom_data.variant_id = variant.id
        custom_data.variant_label = variant.variant_label
        custom_data.selection_by = variant.selection_by
        custom_data.variant_sku = variant.variant_sku
        custom_data.value = variant.value
        custom_data.unit = variant.unit
        custom_data.mrp = variant.mrp
        custom_data.rate = variant.rate
        custom_data.inventory = variant.inventory
        custom_data.is_masterrate = data.is_masterrate
        custom_data.master_value = data.master_value
        custom_data.master_unit = data.master_unit
        custom_data.master_mrp = data.master_mrp
        custom_data.master_rate = data.master_rate
        custom_data.is_custom_weight = variant.is_custom_weight

        if (type === "CUSTOM_ADD") {
          if (this.state.custom_qty && this.state.custom_qty !== "") {
            if (parseFloat(this.state.custom_qty) > 0) {
              custom_data.value = this.state.custom_qty
            }
            else {
            //   this.textInput.current.focus();
              return
            }
          }
          else {
            // this.textInput.current.focus();
            return
          }
        }
        if(type === "QUICK_BUY"){
            type = "ADD"
            this.props.dispatch(itemaction.add_remove_item_cart('REMOVEALL', {}))
            this.props.dispatch(itemaction.open_cart_view(true))
        }
        this.props.dispatch(itemaction.add_remove_item_cart(type, custom_data))
        this.props.dispatch(itemaction.variant_change(custom_data.item_id, custom_data.variant_id))

        this.setState({
          custom_qty: null,
          custom_kg_qty: null,
          custom_gm_qty: null,
        });
    }
    handleClick = (event) => {
        this.setState({ anchorEl: event.currentTarget })
    };

    handleClose = () => {
        this.setState({ anchorEl: null })
    };
    render() {

        const { Image, Name, ImageHover, Price, item } = this.props;
        const open = Boolean(this.state.anchorEl);

        let active_variant = item.item_variant ? item.item_variant.filter(variant => variant.active === true):[]
        let item_variant = active_variant.length > 0 ? active_variant[0]:item.item_variant[0]
        if(!(item_variant)){
            return null;
        }
        let item_link = "/item/" + (item.item_name).replace(/[^A-Z0-9]/ig, "-").toLowerCase() + "/" + item.item_id;
        let display_item_category_name =  item.display_item_category_name || ""
        return (
            <div>
                {isMobile ? <div className='card border-0'>
                    <a href={item_link}>
                        <img src={Image} className='mb-1 card-img-top border rounded-3' alt="..." />
                    </a>
                    <p className='small opacity-50 mb-0'>Category Name</p>
                        <p className='fw-medium mb-1'>{Name}</p>
                    <div className='pe-2 mb-1 d-flex justify-content-between align-items-center'>
                        <p className='fw-medium mb-1'><span className=' small text-decoration-line-through opacity-50'>{Price}</span>  {Price} </p>
                        <div>
                            <Chip label={item_variant.value+" "+item_variant.unit}
                                className='fw-semibold rounded-2'
                                variant="filled"
                                aria-controls={open ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open ? 'true' : undefined}
                                onClick={this.handleClick}
                                onDelete={this.handleClick}
                                deleteIcon={<ArrowDownSLineIcon />}
                            />
                            <Menu
                                id="basic-menu"
                                anchorEl={this.state.anchorEl}
                                open={open}
                                // sx={{minWidth: 600}}
                                PaperProps={{
                                    style: {
                                        width: 500,
                                        padding: 16
                                    },
                                }}
                                MenuListProps={{
                                    'aria-labelledby': 'basic-button',
                                }}
                            >
                                <div className='row'>
                                    <div className='col-md-12 pb-2 d-flex align-items-center justify-content-between'>
                                        <h6 className='fw-bold mb-0'>{Name}</h6>
                                        <IconButton size='small' onClick={this.handleClose} aria-label="delete" color='default'><CloseCircleLineIcon /></IconButton>
                                    </div>
                                    <Divider />
                                    <div className='col-md-12 p-0'>
                                        <table className='w-100 table'>
                                            <thead>
                                                <tr>
                                                    <th className='text-muted'>Variant</th>
                                                    <th className='text-muted'>Rate</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {item && item.item_variant.map((variant, i) => (
                                                <tr>
                                                    <td style={{ verticalAlign: "middle" }}>
                                                        <span className='mb-1 fw-semibold'>Variant {i+1}</span>
                                                        <br></br>
                                                        <span className='small text-muted'>{variant.value+" "+variant.unit} {variant.variant_label?(variant.variant_label):""}</span>
                                                    </td>
                                                    <td style={{ verticalAlign: "middle" }}>{"₹ "+variant.mrp}</td>
                                                    <td width="150" style={{ textAlign: "right", verticalAlign: "middle" }}>
                                                        {variant.qty === 0 ? <div class="col">
                                                            <Button variant="contained"  onClick={(event) => this.add_remove_item_cart(event, 'ADD', item, variant)} disableElevation>Add to cart</Button>
                                                        </div>
                                                            :
                                                            <div class="col">
                                                                <div className='w-100 card h-100 border-primary d-flex align-items-center justify-content-center flex-row'>
                                                                    <IconButton size='small' onClick={(event) => this.add_remove_item_cart(event, 'REMOVE', item, variant)} aria-label="delete" color='primary'><SubtractFillIcon /></IconButton>
                                                                    <h5 className='mb-0 mx-2'>{variant.qty}</h5>
                                                                    <IconButton size='small' onClick={(event) => this.add_remove_item_cart(event, 'ADD', item, variant)} aria-label="delete" color='primary'><AddFillIcon /></IconButton>
                                                                </div>
                                                            </div>
                                                        }

                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </Menu>
                        </div>
                    </div>
                    <div className='d-flex align-items-start flex-column justify-content-between'>
                        {item_variant.qty === 0 ?
                                <Button className='me-1 p-2 d-block w-100 rounded-3' color='primary' variant="contained" onClick={(event) => this.add_remove_item_cart(event, 'ADD', item, item_variant)} disableElevation>Add to bag</Button>:
                                <div className='w-100 card h-100 border-primary d-flex align-items-center justify-content-center flex-row'>
                                    <IconButton size='small' onClick={(event) => this.add_remove_item_cart(event, 'REMOVE', item, item_variant)} aria-label="delete" color='primary' disableElevation><SubtractFillIcon /></IconButton>
                                    <h5 className='mb-0 mx-2'>{item_variant.qty}</h5>
                                    <IconButton size='small' onClick={(event) => this.add_remove_item_cart(event, 'ADD', item, item_variant)} aria-label="delete" color='primary' disableElevation><AddFillIcon /></IconButton>
                                </div>
                                }
                    </div>
                </div>
                    :
                    <div className="card product-card border-0" onMouseOver={() => { this.setState({ hovererImage: true }) }} onMouseLeave={() => { this.setState({ hovererImage: false }) }}>
                        <div className='position-relative rounded-3 border p-0 mb-3 overflow-hidden'>
                            {display_item_category_name &&
                                <Chip label={display_item_category_name} color='primary' variant='outlined' className='rounded-2 fw-semibold position-absolute start-0 top-0 z-5 m-1' />
                            }
                            <a href={item_link}>
                                <div className={`h-100 top-0 card-img-top main-img ${this.state.hovererImage ? "opacity-100" : "opacity-100"}`}>
                                    <img src={Image} className='card-img-top' alt="..." style={{ background: "linear-gradient(rgb(245, 245, 245) 0%, rgb(229 229 229) 76.04%, rgb(217 217 217) 86.98%, rgb(244, 244, 244) 100%)" }} />
                                </div>
                                <div className={`position-absolute h-100 z-3 top-0 card-img-top hover-img ${this.state.hovererImage ? "opacity-100" : "opacity-0"}`}>
                                    <img src={ImageHover} className="card-img-top" alt="..." style={{ background: "linear-gradient(rgb(245, 245, 245) 0%, rgb(229 229 229) 76.04%, rgb(217 217 217) 86.98%, rgb(244, 244, 244) 100%)" }} />
                                </div>
                            </a>

                            {isMobile ? null : <div className='product-action d-flex position-absolute start-0 end-0 bottom-0 w-100 p-2 z-3' style={{ background: "linear-gradient(180deg, rgba(244, 244, 244, 0.00) 0%, rgba(244, 244, 244, 0.80) 100%)" }}>
                            {item_variant.qty === 0 ?
                                <Button className='me-1 p-2 d-block w-100 rounded-3' color='primary' variant="contained" onClick={(event) => this.add_remove_item_cart(event, 'ADD', item, item_variant)} disableElevation>Add to bag</Button>:
                                <div className='w-100 card h-100 border-primary d-flex align-items-center justify-content-center flex-row'>
                                    <IconButton size='small' onClick={(event) => this.add_remove_item_cart(event, 'REMOVE', item, item_variant)} aria-label="delete" color='primary' disableElevation><SubtractFillIcon /></IconButton>
                                    <h5 className='mb-0 mx-2'>{item_variant.qty}</h5>
                                    <IconButton size='small' onClick={(event) => this.add_remove_item_cart(event, 'ADD', item, item_variant)} aria-label="delete" color='primary' disableElevation><AddFillIcon /></IconButton>
                                </div>
                                }
                                <Button className='ms-1 p-2 d-block w-100 rounded-3' color='secondary' variant="contained" onClick={(event) => this.add_remove_item_cart(event, 'QUICK_BUY', item, item_variant)} disableElevation>Quick Buy</Button>
                            </div>}

                            {/* <img src={Stripes} className="position-absolute start-0 end-0 bottom-0 pb-3 z-1 w-100" /> */}
                        </div>
                        <div className="card-body p-0 ">
                            <h5 className="mb-2 text-truncate">{Name}</h5>
                            <div className='pe-2 d-flex justify-content-between align-items-center'>
                                <p className="mb-0">{"₹"+Price}</p>
                                <div>
                                    <Chip label={item_variant.value+" "+item_variant.unit}
                                        className='fw-semibold rounded-2'
                                        variant="filled"
                                        aria-controls={open ? 'basic-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={this.handleClick}
                                        onDelete={this.handleClick}
                                        deleteIcon={<ArrowDownSLineIcon />}
                                    />
                                    <Menu
                                        id="basic-menu"
                                        anchorEl={this.state.anchorEl}
                                        open={open}
                                        // sx={{minWidth: 600}}
                                        PaperProps={{
                                            style: {
                                                width: 500,
                                                padding: 16
                                            },
                                        }}
                                        MenuListProps={{
                                            'aria-labelledby': 'basic-button',
                                        }}
                                    >
                                        <div className='row'>
                                            <div className='col-md-12 pb-2 d-flex align-items-center justify-content-between'>
                                                <h6 className='fw-bold mb-0'>{Name}</h6>
                                                <IconButton size='small' onClick={this.handleClose} aria-label="delete" color='default'><CloseCircleLineIcon /></IconButton>
                                            </div>
                                            <Divider />
                                            <div className='col-md-12 p-0'>
                                                <table className='w-100 table'>
                                                    <thead>
                                                        <tr>
                                                            <th className='text-muted'>Variant</th>
                                                            <th className='text-muted'>Rate</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {item && item.item_variant.map((variant, i) => (
                                                        <tr>
                                                            <td style={{ verticalAlign: "middle" }}>
                                                                <span className='mb-1 fw-semibold'>Variant {i+1}</span>
                                                                <br></br>
                                                                <span className='small text-muted'>{variant.value+" "+variant.unit} {variant.variant_label?(variant.variant_label):""}</span>
                                                            </td>
                                                            <td style={{ verticalAlign: "middle" }}>{"₹ "+variant.mrp}</td>
                                                            <td width="150" style={{ textAlign: "right", verticalAlign: "middle" }}>
                                                                {variant.qty === 0 ? <div class="col">
                                                                    <Button variant="contained"  onClick={(event) => this.add_remove_item_cart(event, 'ADD', item, variant)} disableElevation>Add to cart</Button>
                                                                </div>
                                                                    :
                                                                    <div class="col">
                                                                        <div className='w-100 card h-100 border-primary d-flex align-items-center justify-content-center flex-row'>
                                                                            <IconButton size='small' onClick={(event) => this.add_remove_item_cart(event, 'REMOVE', item, variant)} aria-label="delete" color='primary'><SubtractFillIcon /></IconButton>
                                                                            <h5 className='mb-0 mx-2'>{variant.qty}</h5>
                                                                            <IconButton size='small' onClick={(event) => this.add_remove_item_cart(event, 'ADD', item, variant)} aria-label="delete" color='primary'><AddFillIcon /></IconButton>
                                                                        </div>
                                                                    </div>
                                                                }

                                                            </td>
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </Menu>
                                </div>
                                {/* <Chip label={item_variant.value+" "+item_variant.unit} className='fw-semibold rounded-2' variant="filled" onClick={() => { }} onDelete={() => { }} deleteIcon={<ArrowDownSLineIcon />} /> */}
                            </div>
                            {isMobile ? <div className='d-flex mt-3'>
                                <Button className='p-2 d-block w-100 rounded-3' color='primary' variant="contained" disableElevation>Add to bag</Button>
                            </div> : null}
                        </div>
                    </div>}

            </div>

        );
    }
}

function mapStateToProps(state,props) {
    return {
     
    };
  }
  export default connect(mapStateToProps)(ProductCard);