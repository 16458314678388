

import { OrderConstants } from '../_constants/order.constants';


export function order(state = {}, action) {
    // console.log('order',action.type)
    switch (action.type) {
        case OrderConstants.GET_ORDER_DETAIL_REQUEST:
            return {
                ...state,
                order_detail: null,
            };
        case OrderConstants.GET_ORDER_DETAIL_SUCCESS:
            var breadcrumb = [];
            breadcrumb.push({ path: "/orders", name: "My Orders" })
            breadcrumb.push({ path: "", name: action.payload.data.order_no })
            return {
                ...state,
                order_detail: action.payload.data,
                breadcrumb: breadcrumb
            };
        case OrderConstants.GET_ORDER_DETAIL_FAILURE:
            return {
                ...state,
                order_detail: null,
            };
        case OrderConstants.GET_CUSTOMER_ORDER_LIST_REQUEST:
            return {
                ...state,
                order_list: null,
            };
        case OrderConstants.GET_CUSTOMER_ORDER_LIST_SUCCESS:
            var breadcrumb = [];
            breadcrumb.push({ path: "", name: "My Orders" })
            return {
                ...state,
                order_list: action.payload.data.order_list,
                breadcrumb: breadcrumb
            };
        case OrderConstants.GET_CUSTOMER_ORDER_LIST_FAILURE:
            return {
                ...state,
                order_list: null,
            };
        case OrderConstants.GET_BASKET_LIST_REQUEST:
            return {
                ...state,
                basket_list: [],
            };
        case OrderConstants.GET_BASKET_LIST_SUCCESS:
            var breadcrumb = [];
            breadcrumb.push({ path: "", name: "My Basket" })
            return {
                ...state,
                basket_list: action.payload.data,
            };
        case OrderConstants.GET_BASKET_LIST_FAILURE:
            return {
                ...state,
                basket_list: [],
            };
        case OrderConstants.GET_BASKET_DETAIL_REQUEST:
            return {
                ...state,
                basket_details: {},
            };
        case OrderConstants.GET_BASKET_DETAIL_SUCCESS:
            return {
                ...state,
                is_basket_refresh:true,
                basket_details:action.payload.data,
            };
        case OrderConstants.GET_BASKET_DETAIL_FAILURE:
            return {
                ...state,
                basket_details: {},
            };
        default:
            return state
    }
}




