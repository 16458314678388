

export const PaymentConstants = {
    GET_CART_PAYMENT_REQUEST: 'GET_CART_PAYMENT_REQUEST',
    GET_CART_PAYMENT_SUCCESS: 'GET_CART_PAYMENT_SUCCESS',
    GET_CART_PAYMENT_FAILURE: 'GET_CART_PAYMENT_FAILURE',
    SELECT_DELIVERY_ADDRESS: 'SELECT_DELIVERY_ADDRESS',
    CHANGE_DELIVERY_ADDRESS: 'CHANGE_DELIVERY_ADDRESS',
    SELECT_DELIVERY_TIMESLOT: 'SELECT_DELIVERY_TIMESLOT',
    CHANGE_DELIVERY_TIMESLOT: 'CHANGE_DELIVERY_TIMESLOT',
    SELECT_STORE: 'SELECT_STORE',
    SELECT_STORE_TYPE: 'SELECT_STORE_TYPE',
    GET_DELIVERY_TIME_SLOT_REQUEST: 'GET_DELIVERY_TIME_SLOT_REQUEST',
    GET_DELIVERY_TIME_SLOT_SUCCESS: 'GET_DELIVERY_TIME_SLOT_SUCCESS',
    GET_DELIVERY_TIME_SLOT_FAILURE: 'GET_DELIVERY_TIME_SLOT_FAILURE',

    GET_NEW_DELIVERY_TIME_SLOT_REQUEST: 'GET_NEW_DELIVERY_TIME_SLOT_REQUEST',
    GET_NEW_DELIVERY_TIME_SLOT_SUCCESS: 'GET_NEW_DELIVERY_TIME_SLOT_SUCCESS',
    GET_NEW_DELIVERY_TIME_SLOT_FAILURE: 'GET_NEW_DELIVERY_TIME_SLOT_FAILURE',
    
    CHANGE_ORDER_TYPE: 'CHANGE_ORDER_TYPE',
    GET_ORDER_STORE_LIST_REQUEST: 'GET_ORDER_STORE_LIST_REQUEST',
    GET_ORDER_STORE_LIST_SUCCESS: 'GET_ORDER_STORE_LIST_SUCCESS',
    GET_ORDER_STORE_LIST_FAILURE: 'GET_ORDER_STORE_LIST_FAILURE',
    GET_PAYLATER_ORDER_DETAIL_REQUEST: 'GET_PAYLATER_ORDER_DETAIL_REQUEST',
    GET_PAYLATER_ORDER_DETAIL_SUCCESS: 'GET_PAYLATER_ORDER_DETAIL_SUCCESS',
    GET_PAYLATER_ORDER_DETAIL_FAILURE: 'GET_PAYLATER_ORDER_DETAIL_FAILURE',
    GET_ORDER_CUSTOMER_WALLET_BALANCE_REQUEST: 'GET_ORDER_CUSTOMER_WALLET_BALANCE_REQUEST',
    GET_ORDER_CUSTOMER_WALLET_BALANCE_SUCCESS: 'GET_ORDER_CUSTOMER_WALLET_BALANCE_SUCCESS',
    GET_ORDER_CUSTOMER_WALLET_BALANCE_FAILURE: 'GET_ORDER_CUSTOMER_WALLET_BALANCE_FAILURE',
    SELECT_UNSELECT_CUSTOMER_WALLET_BALANCE: 'SELECT_UNSELECT_CUSTOMER_WALLET_BALANCE',
    AUTHENTICATE_WALLET_OTP_REQUEST: 'AUTHENTICATE_WALLET_OTP_REQUEST',
    AUTHENTICATE_WALLET_OTP_SUCCESS: 'AUTHENTICATE_WALLET_OTP_SUCCESS',
    AUTHENTICATE_WALLET_OTP_FAILURE: 'AUTHENTICATE_WALLET_OTP_FAILURE',

    APPLY_COUPEN_CODE_REQUEST: 'APPLY_COUPEN_CODE_REQUEST',
    APPLY_COUPEN_CODE_SUCCESS: 'APPLY_COUPEN_CODE_SUCCESS',
    APPLY_COUPEN_CODE_FAILURE: 'APPLY_COUPEN_CODE_FAILURE',
    REMOVE_COUPEN_CODE: 'REMOVE_COUPEN_CODE',
    SPECIAL_INSTRUCTIONS:"SPECIAL_INSTRUCTIONS"

};
