import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Card, CardMedia } from '@mui/material';
import TestVideo from "../assets/test_video.mp4"
import FullBanner1 from "../assets/banner_full.jpg"
import BannersHome from '../components/LandingPage/BannersHome';
import TopProductsSection from '../components/LandingPage/TopProductsSection';
import CollectionSection from '../components/LandingPage/CollectionSection'
import NewCollectionSection from '../components/LandingPage/NewCollectionSection';
import AllCategories from '../components/LandingPage/AllCategories';
import BlogSection from '../components/LandingPage/BlogSection';
import Imageblock from '../components/LandingPage/ImageBlock';

import { isMobile } from 'react-device-detect';
import { itemaction } from '../_actions/item.action';
import { dashboardaction } from '../_actions/dashboard.action';
import { authenticationaction } from '../_actions/authentication.action';
import FullBanner from '../components/LandingPage/fullbanner';
class LandingPage extends Component {

  constructor(props) {
    super(props);
    this.state = {
      Fname: "Deep",
      Lname: "Bhavsar",

    };
  }
  componentDidMount() {
    this.props.dispatch(dashboardaction.get_dashboard())
    this.props.dispatch(itemaction.dashboard_get_item_list())
}

  render() {
    return (
      <div>

        {/* <TopHeader />


        <MainHeader /> */}


        <section className={`py-5 ${isMobile ? "pt-0" : null} pb-0`}>
          <div className={`row mx-auto ${isMobile ? null : "container"}`}>
            <Card elevation={0} variant="outlined" className={`p-0 ${isMobile ? "rounded-0" : "rounded-5"}`}>
              <div style={{ maxHeight: "40vh", overflow: 'hidden' }} className='position-relative'>
                <div style={{ height: "100%", width: "100%", background: "#000000" }} className='position-absolute opacity-50'></div>
                <div className='py-5 position-absolute top-50 start-50 translate-middle w-100 z-3'>
                  <h1 className='text-center fw-bold text-white display-4'>Unlock the Secrets of Ayurvedic Wellness</h1>
                  <h4 className='text-center fw-lighter text-white'>Rediscover Wellness with Our Authentic Ayurvedic Products.</h4>
                </div>
                <CardMedia
                  component="video"
                  height="300"
                  autoPlay
                  loop
                  muted
                  className='z-2'
                  sx={{ width: "100vw", height: "100%", overflow: "hidden" }}
                  image={TestVideo}
                  alt="green iguana"
                />
              </div>

            </Card>
          </div>
        </section>
 
        {this.props.content && this.props.content.map((data, index) => {
            return data.type === "DEPARTMENT_BLOCKS" ? <AllCategories key={index} values={data.values} /> :
              data.type === "WEB_SLIDER" ? data.values.status && data.values.status === true ? <BannersHome key={index} values={data.values} /> : null :
                // data.type === "COLLECTION" ? <CollectionSection department_id="All" key={index} values={data.values} /> :
                data.type === "COLLECTION" ? <NewCollectionSection department_id="All" key={index} values={data.values} /> :
                  data.type === "IMAGEBLOCK" ? <Imageblock key={index} values={data.values} /> : null
        })}

         {/* ItemBlock */}
         {this.props.category_wise_item_list && this.props.category_wise_item_list.map((data, index) => {
             return <TopProductsSection values={data}/>
        })}


        {/* reamining 
        COLLECTION
        IMAGEBLOCK */}

        {/* <section className='py-3'>
          <div className='container-fluid px-0 mx-0'>
            <img src={FullBanner1} className='w-100' alt=''/>
          </div>
        </section> */}

        {this.props.content && this.props.content.map((data, index) => {
            return data.type === "SLIDER" ? <FullBanner key={index} values={data.values} /> : null
        })}
        <BlogSection />

        {/* <Footer/> */}

      </div>

    );
  }
}

function mapStateToProps(state) {
  return {
    content: state.dashboard.content,
    category_wise_item_list: state.item.category_wise_item_list,
  };
}

export default connect(mapStateToProps)(LandingPage);