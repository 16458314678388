

export const CustomerConstants = {
    GET_CUSTOMER_ADDRESS_LIST_REQUEST: 'GET_CUSTOMER_ADDRESS_LIST_REQUEST',
    GET_CUSTOMER_ADDRESS_LIST_SUCCESS: 'GET_CUSTOMER_ADDRESS_LIST_SUCCESS',
    GET_CUSTOMER_ADDRESS_LIST_FAILURE: 'GET_CUSTOMER_ADDRESS_LIST_FAILURE',
    GET_CUSTOMER_ADDRESS_DETAIL_REQUEST: 'GET_CUSTOMER_ADDRESS_DETAIL_REQUEST',
    GET_CUSTOMER_ADDRESS_DETAIL_SUCCESS: 'GET_CUSTOMER_ADDRESS_DETAIL_SUCCESS',
    GET_CUSTOMER_ADDRESS_DETAIL_FAILURE: 'GET_CUSTOMER_ADDRESS_DETAIL_FAILURE',
    IN_UP_CUSTOMER_ADDRESS_REQUEST: 'IN_UP_CUSTOMER_ADDRESS_REQUEST',
    IN_UP_CUSTOMER_ADDRESS_SUCCESS: 'IN_UP_CUSTOMER_ADDRESS_SUCCESS',
    IN_UP_CUSTOMER_ADDRESS_FAILURE: 'IN_UP_CUSTOMER_ADDRESS_FAILURE',
    CUSTOMER_ADDRESS_NEW: 'CUSTOMER_ADDRESS_NEW',
    GET_CUSTOMER_DETAIL_REQUEST: 'GET_CUSTOMER_DETAIL_REQUEST',
    GET_CUSTOMER_DETAIL_SUCCESS: 'GET_CUSTOMER_DETAIL_SUCCESS',
    GET_CUSTOMER_DETAIL_FAILURE: 'GET_CUSTOMER_DETAIL_FAILURE',
    GET_STATE_LIST_REQUEST: 'GET_STATE_LIST_REQUEST',
    GET_STATE_LIST_SUCCESS: 'GET_STATE_LIST_SUCCESS',
    GET_STATE_LIST_FAILURE: 'GET_STATE_LIST_FAILURE',
    UPDATE_CUSTOMER_MOBILENO_REQUEST: 'UPDATE_CUSTOMER_MOBILENO_REQUEST',
    UPDATE_CUSTOMER_MOBILENO_SUCCESS: 'UPDATE_CUSTOMER_MOBILENO_SUCCESS',
    UPDATE_CUSTOMER_MOBILENO_FAILURE: 'UPDATE_CUSTOMER_MOBILENO_FAILURE',
    GET_PRODUCT_SEARCH_REQUEST: 'GET_PRODUCT_SEARCH_REQUEST',
    GET_PRODUCT_SEARCH_SUCCESS: 'GET_PRODUCT_SEARCH_SUCCESS',
    GET_PRODUCT_SEARCH_FAILURE: 'GET_PRODUCT_SEARCH_FAILURE',
    GET_CUSTOMER_ITEM_LIST_REQUEST: 'GET_CUSTOMER_ITEM_LIST_REQUEST',
    GET_CUSTOMER_ITEM_LIST_SUCCESS: 'GET_CUSTOMER_ITEM_LIST_SUCCESS',
    GET_CUSTOMER_ITEM_LIST_FAILURE: 'GET_CUSTOMER_ITEM_LIST_FAILURE',
};
