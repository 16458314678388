import React, { Component } from 'react';
import { Chip, Card, CardMedia, CardContent, CardActionArea, Link } from '@mui/material';

class BlogCard extends Component {
    render() {
        const { Title, Subtitle, Image, Category, Date } = this.props;
        return (
            <Card elevation={0} className='blog-card'>
                <CardActionArea className='p-1 rounded-4'>
                    <div className='rounded-4 overflow-hidden position-relative'>
                        <CardMedia
                            sx={{ height: 200, transform: "Scale(1.0)" }}
                            image={Image}
                            className='blog-banner'
                            title={Title}
                        />
                        <div className='d-flex align-items-center justify-content-between p-2 position-absolute w-100 bottom-0' style={{ backdropFilter: "blur(1px)", background: "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, #000 100%)" }}>
                            <Chip label={Category} className='text-capitalize fw-semibold' sx={{ background: "#D8D9F3", color: "#2E3192", maxWidth: '50%' }} />
                        </div>
                    </div>
                    <CardContent className='px-2'>
                        <h5 className='mb-1 two-line'>{Title}</h5>
                        <p className='opacity-75 three-line small mb-2'>{Subtitle}</p>
                        <p className='mb-0 text-dark fw-semibold small'>{Date}</p>
                    </CardContent>
                </CardActionArea>
            </Card>
        );
    }
}

export default BlogCard;