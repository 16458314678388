import React from 'react';
import { Link } from 'react-router-dom';

class Paymentfailed extends React.Component {

    render() {
        return (
            <div className="container " style={{ paddingTop: "60px",height:"500px" }} >
                <div className="col-12">
                    <div className="card" >
                        <div className="card-body text-center">
                            <h4 >Oops something went wrong !</h4>
                            <br></br>
                            <h5>Payment Failed</h5>
                            <p>If amount is debit from account please mail us at <a href="mailto:customercare@zenwholesalehub.in">info_ay@zoetic.co.in</a> </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Paymentfailed;