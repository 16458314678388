import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.css';
import LandingPage from './Pages/LandingPage';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import ThemeZoetic from './ThemeZoetic';
import CartPage from './Pages/CartPage';
import Layout from './components/General/Layout';
import PaymentPage from './Pages/PaymentPage';
import ProductPage from './Pages/ProductPage';
import SuccessPage from './Pages/SuccessPage';
import TnC from './Pages/TnC';
import PrivacyPolicy from './Pages/PrivacyPolicy';
import ReturnExchange from './Pages/ReturnExchange';
import RnD from './Pages/RnD';
import QualityControl from './Pages/QualityControl';
import AboutUs from './Pages/AboutUs';
import ContactUs from './Pages/ContactUs';
import { generalaction } from './_actions/general.action'
import { Helmet } from "react-helmet";
import { history } from './_helpers/history';
import PublicRoute from './_routers/PublicRoute';
import PrivateRoute from './_routers/PrivateRoute';
import Siteauthenticate from './components/master/siteauthenticate';
import CategoryPage from './Pages/CategoryPage';
import CollectionPage from './Pages/CollectionPage';
import Address from './components/profile/address';
import Paymentfailed  from '../src/components/payment/paymentfailed'
import Razerpay_Card from './components/payment/razerpay_card';
import BlogPage from '../src/Pages/BlogPage'
import  SimpleBackdrop  from './_helpers/CircularProgress';
import Orderhistory from '../src/components/order/orderhistory';
import {authenticationaction} from './_actions/authentication.action';


class App extends Component {

  constructor(props) {
    super(props);
    var host = window.location.host
    //var host = "localhost:1003"
    //host = "beta.zenwholesalehub.in";
    this.state = {
      host: host,
    }
  }
  componentDidMount() {
    this.props.dispatch(generalaction.get_access_token_byhost(this.state.host))
    this.props.dispatch(authenticationaction.get_customer_info())
  }

  render() {

    if (!this.props.is_valid) {
      return this.props.is_valid === false ?
        <h5>Invalid Site Configration</h5>
        : <SimpleBackdrop/>
    }

    if (!this.props.general_data) {
      return <SimpleBackdrop/>
    }

    var site_auth = false
    if (localStorage.getItem("site_auth")) {
      var site_auth_data = JSON.parse(localStorage.getItem('site_auth'));
      if (site_auth_data && (site_auth_data.store_password === this.props.general_data.store_password)) {
        site_auth = true
      }
    }

    return (
      <div>
        <ThemeProvider theme={ThemeZoetic}>
          <BrowserRouter>
            <Layout>
              <Routes history={history}>
                <Route path="/" element={<LandingPage />} />
                <Route path="/payment" element={<PaymentPage />} />
                <Route path="/item/:item_name/:item_id" element={<ProductPage/>}  />
                <Route path="/dep/:department_name/:department_id" element={<CategoryPage/>}/>
                <Route path="/col/:collection_name/:collection_id" element={<CollectionPage/>}/>
                <Route path="/orderstatus/:order_id" element={<SuccessPage />} />
                <Route path="/tnc" element={<TnC/>} />
                <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
                <Route path="/return-exchange" element={<ReturnExchange/>} />
                <Route path="/rnd" element={<RnD/>} />
                <Route path="/quality-control" element={<QualityControl/>} />
                <Route path="/blogpage" element={<BlogPage/>} />
                <Route path="/about-us" element={<AboutUs/>} />
                <Route path="/contact-us" element={<ContactUs/>} />
                <Route path="/cart" element={<CartPage />} />
                <Route path="/razerpay_card" element={<Razerpay_Card />} />
                <Route path="/orders" element={<Orderhistory/>} />
                <Route path="/paymentfailed/:order_id" element={<Paymentfailed/>} />
              </Routes>
            </Layout>
          </BrowserRouter>
        </ThemeProvider>
      </div>

    );
  }
}

function mapStateToProps(state) {
  // console.log(state)
  return {
    is_valid: state.general.is_valid,
    general_data: state.general.general_data,
  };
}
export default connect(mapStateToProps, null)(App)
