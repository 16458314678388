import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar } from '@mui/material';
import FacebookCircleFillIcon from 'remixicon-react/FacebookCircleFillIcon';
import TwitterFillIcon from 'remixicon-react/TwitterFillIcon';
import InstagramFillIcon from 'remixicon-react/InstagramFillIcon';
import YoutubeFillIcon from 'remixicon-react/YoutubeFillIcon';

class TopHeader extends Component {
  render() {
    return (
      <AppBar position="static" elevation={0} className='bg-white text-dark border-bottom px-5'>
        <Toolbar variant='dense' className='container'>
          <p className='mb-0' style={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
            {/* <span className='badge rounded-pill bg-danger m-0 fw-semibold text-uppercase me-2 p-2'>Offfer</span>
            Get up to ₹800.00 instant savings on selected products with eligible payment options. */}
          </p>
          <IconButton aria-label="delete">
            <FacebookCircleFillIcon />
          </IconButton>

          <IconButton aria-label="delete">
            <TwitterFillIcon />
          </IconButton>

          <IconButton aria-label="delete">
            <InstagramFillIcon />
          </IconButton>

          <IconButton aria-label="delete">
            <YoutubeFillIcon />
          </IconButton>
        </Toolbar>
      </AppBar>
    );
  }
}

export default TopHeader;