import React, { Component } from 'react';
import { Alert, AppBar, Toolbar, Typography, Button, Chip, Divider, InputBase, Paper, Badge, Card, CardMedia, CardContent, CardActionArea, Link } from '@mui/material';
import TestVideo from "../assets/test_video.mp4"
import FullBanner from "../assets/banner_full.jpg"
import MainHeader from '../components/General/MainHeader';
import TopHeader from '../components/General/TopHeader';
import BannersHome from '../components/LandingPage/BannersHome';
import TopProductsSection from '../components/LandingPage/TopProductsSection';
import AllCategories from '../components/LandingPage/AllCategories';
import BlogSection from '../components/LandingPage/BlogSection';
import Footer from '../components/General/Footer';
import { isMobile } from 'react-device-detect';
import RndBanner from "../assets/rnd.jpg"
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';


class QualityControl extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (
      <>
        <section className="" style={{ background: "url(" + RndBanner + ")" }}>
          <div className="container" style={{ paddingTop: 80, paddingBottom: 80 }}>
            <h1 className='display-4 fw-semibold'>Quality Control</h1>
          </div>
        </section>

        <section className='py-5'>
          <div className='container'>
            <Alert icon={false} className='mb-3 fs-5 p-3 position-relative' title='' severity="info">
              <FormatQuoteIcon className='position-absolute top-0 start-100 translate-middle' sx={{ fontSize: 72 }} />
              Zoetic Prides Itself On Being a Completely Research-Oriented Company.Today not even Ayurveda or herbal but Modern Medicine loving people also, recognizes Zoetic products as a hallmark of Efficacy, Reliability and Quality.
            </Alert>

            <p>Indeed, it is this emphasis on R&D that allows Zoetic Ayurvedics to produce safe, efficacious and consistent remedies using ayurvedic principles. Within the Manufacturing Plant independent Quality Control Laboratories manned by expert Botanists, Pharmacologists, Chemists as well as eminent Vaidyas (Ayurvedic Physicians) have been set up to ensure that the unfinished (raw & processed) and finished (ready to use) products conform consistently to a high level of quality. In all the processes, SHASTROKTA SIDDHANTA is to be applied at every step, which is clearly laid down in ancient Ayurvedic Treatises.</p>
            
            <Alert icon={false} className='mb-3 fs-5 p-3 position-relative' title='' severity="info">
              <FormatQuoteIcon className='position-absolute top-0 start-100 translate-middle' sx={{ fontSize: 72 }} />
              Zoetic Ayurvedics products have come to be regarded as the finest specimens as in the Ayurvedic Pharmacopoeia has to offer. As far as genuineness and quality of the product are concerned, Zoetic's accept no compromise.
            </Alert>

            <p>Every raw material is thoroughly inspected and only after it is approved at the Laboratory does it find a place in the production process. The Quality Control Laboratory also keeps a careful and close watch on the medicines under preparation. At every stage of the production process, samples are drawn, examined and necessary guidance given to the Production Department whenever required. Every finished product also comes to this Laboratory for inspection and approval.</p>
          </div>
        </section>
      </>

    );
  }
}

export default QualityControl;