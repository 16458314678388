import { OrderConstants } from '../_constants/order.constants';
import { API_URL, showErrorMessage,showSuccessMessage } from './utility.action'

export const orderaction = {
    get_order_detail, get_customer_order,add_customer_basket,get_customer_basket_list,update_customer_basket_name,delete_customer_basket,get_customer_basket
};

function get_order_detail(order_id) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token, "order_id": order_id }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/order_service/get_order_detail`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: OrderConstants.GET_ORDER_DETAIL_REQUEST } }
    function success(payload) { return { type: OrderConstants.GET_ORDER_DETAIL_SUCCESS, payload } }
    function failure(payload) { return { type: OrderConstants.GET_ORDER_DETAIL_FAILURE, payload } }
}


function get_customer_order() {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/order_service/get_customer_order`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: OrderConstants.GET_CUSTOMER_ORDER_LIST_REQUEST } }
    function success(payload) { return { type: OrderConstants.GET_CUSTOMER_ORDER_LIST_SUCCESS, payload } }
    function failure(payload) { return { type: OrderConstants.GET_CUSTOMER_ORDER_LIST_FAILURE, payload } }
}

function add_customer_basket(basket_name,order_id) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token,"order_id": order_id ,"basket_name":basket_name}

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };
    return dispatch => {
        fetch(`${API_URL}/customer_service/add_customer_basket`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    showErrorMessage(response.error_description)
                }
                else {
                    showSuccessMessage(response.error_description)
                }
            });
    };
}

function get_customer_basket_list() {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };
    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/customer_service/get_customer_basket_list`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: OrderConstants.GET_BASKET_LIST_REQUEST } }
    function success(payload) { return { type: OrderConstants.GET_BASKET_LIST_SUCCESS, payload } }
    function failure(payload) { return { type: OrderConstants.GET_BASKET_LIST_FAILURE, payload } }
}

function update_customer_basket_name(basket_name,basket_id) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token,"basket_id": basket_id ,"basket_name":basket_name}

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        fetch(`${API_URL}/customer_service/update_customer_basket_name`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    showErrorMessage(response.error_description)
                }
                else {
                    dispatch(get_customer_basket_list())
                    // dispatch(success(response))
                }
            });
    };
}

function get_customer_basket(basket_id) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token,"basket_id": basket_id}

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/customer_service/get_customer_basket`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };
    function request() { return { type: OrderConstants.GET_BASKET_DETAIL_REQUEST } }
    function success(payload) { return { type: OrderConstants.GET_BASKET_DETAIL_SUCCESS, payload } }
    function failure(payload) { return { type: OrderConstants.GET_BASKET_DETAIL_FAILURE, payload } }
}


function delete_customer_basket(basket_id) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token,"basket_id": basket_id}

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        fetch(`${API_URL}/customer_service/delete_customer_basket`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    showErrorMessage(response.error_description)
                }
                else {
                    dispatch(get_customer_basket_list())
                    // dispatch(success(response))
                }
            });
    };
}


