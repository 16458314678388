import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Paper, InputBase, Divider, Button, IconButton, Link, Dialog } from '@mui/material';
import AddCircleLineIcon from 'remixicon-react/AddCircleLineIcon';
import IndeterminateCircleLineIcon from 'remixicon-react/IndeterminateCircleLineIcon';
import ArrowRightSLineIcon from 'remixicon-react/ArrowRightSLineIcon';
import MapPin2FillIcon from 'remixicon-react/MapPin2FillIcon';
import Coupon from '../assets/coupon.svg';
import { isMobile } from 'react-device-detect';
import { authenticationaction } from '../_actions/authentication.action';
import ClearIcon from '@mui/icons-material/Clear';
import AddressSidebarSelect from '../components/General/AddressSidebarSelect'
import { paymentaction } from '../_actions/payment.action';
import { useNavigate } from 'react-router-dom';
import GuestCheckout from './GuestCheckout';

const CartPage = ({
  dispatch,
  cart,
  total_qty,
  delivery_address,
  delivery_charge,  
  total_amount,
  free_shipping_order_amount,
  wishlist_item,
  pin_cart,
  props,
  prevProps,
  customer_access_token,
  is_special_instruction,
  is_cart_announcement,
  cart_announcement,
}) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(paymentaction.get_cart_payment());
    dispatch(authenticationaction.get_customer_info());

    let customerAccessToken = localStorage.getItem('customer_access_token');

    if (!customerAccessToken) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
  }, [dispatch]);

  const onClickProcessedToBuy = (event) => {
    navigate('/payment');
  }
  return (
    <div>
        <section className='py-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-8'>
                        <div className='card rounded-4'>
                            <div className='card-header py-3 bg-transparent'>
                                <h6 className='fw-semibold mb-0'>{total_qty} Items in your Cart</h6>
                            </div>
                            <div className='card-body py-1 overflow-scroll' style={{ height: isMobile ? null : 300 }}>
                                {cart && cart.map((item) => (
                                    <div className='cursor-pointer d-flex align-items-start justify-content-start flex-row rounded-0 border-0 border-bottom py-2 card'>
                                        <img src={item.feature_image} className='rounded-2 border' style={{ width: 64 }} alt=""/>
                                        <div className='flex-grow-1 align-items-start justify-content-between flex-column d-flex p-2 h-100'>
                                            <h6 className='two-line mb-1'>{item.item_name}</h6>
                                            <p className='two-line mb-1 text-muted'>{"₹ "+item.mrp}<ClearIcon/>{item.qty}</p>
                                            {isMobile && <div className='d-flex flex-row align-items-center'>
                                                <IconButton aria-label="delete">
                                                    <IndeterminateCircleLineIcon />
                                                </IconButton>
                                                <p className='mb-0 mx-2'>01</p>
                                                <IconButton aria-label="delete">
                                                    <AddCircleLineIcon />
                                                </IconButton>
                                            </div>}
                                        </div>
                                        <div className='flex-grow-1 align-items-end justify-content-between flex-column d-flex p-2 h-100'>
                                            <p className='text-muted mb-1'>{item.value+" "+item.unit}</p>
                                            <p className='text-muted mb-1'>{"₹ "+item.amount}</p>
                                            {/* <p className='text-muted'>200 ml</p> */}
                                            {/* <Button variant="text" color='error' className='' onClick={() => { }} disableElevation>Remove</Button> */}
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div className='card-footer'>
                                <div className='w-100 p-2'>
                                    <h6 className='fw-semibold'>Cart Summary</h6>
                                    <div className='d-flex justify-content-between my-1'><span className='text-start'>Subtotal</span> <span className='text-end'>₹ {total_amount}</span></div>
                                    <div className='d-flex justify-content-between text-muted my-1'><span className='text-start'>Shipping Charges</span> <span className='text-end'>₹ 00.00</span></div>
                                    <div className='d-flex justify-content-between fw-semibold text-success my-1'><span className='text-start'><img src={Coupon} className='me-2' alt=""/>Coupon Code (MAX00)</span> <span className='text-end'>- ₹ 00.00</span></div>
                                    <Divider className='my-3' />
                                    <div className='d-flex justify-content-between fw-semibold my-1'><h6 className='text-start'>Total</h6> <h6 className='text-end'>₹ {total_amount}</h6></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='col-md-4'>
                        {isLoggedIn ? <>
                            <div className='card border-success rounded-4 mb-3 bg-success-subtle cursor-pointer'>
                                <div className='card-body'>
                                    <div className='d-flex justify-content-between fw-semibold text-dark my-1'><span className='text-start'><img src={Coupon} className='me-2' alt=""/>Apply Coupon</span> <ArrowRightSLineIcon /></div>
                                </div>
                            </div>

                            <div className='card rounded-4 mb-3'>
                                <div className='card-body'>
                                    <div className='d-flex justify-content-between align-items-center mb-3'>
                                        <h6 className='d-flex align-items-center fw-semibold mb-0'><MapPin2FillIcon className='text-primary me-1' /><span>Deliver to ({delivery_address && delivery_address.pincode})</span></h6>
                                        <AddressSidebarSelect/>

                                    </div>
                                    {delivery_address &&
                                        <p className='text-muted mb-3'>{delivery_address.address + "," + delivery_address.landmark + "," + delivery_address.area + "," + delivery_address.city + "-" + delivery_address.pincode + "," + delivery_address.state}</p>
                                    }

                                    <Button variant="contained" sx={{ p: 2 }} onClick={() => { onClickProcessedToBuy() }} className='w-100 fs-6 fw-light' disableElevation>Proceed to buy</Button>
                                </div>
                            </div>
                        </>
                            :
                            <GuestCheckout/>
                        }
                    </div>
                </div>
            </div>
        </section>
    </div>
);
};

const mapStateToProps = (state) => {
    console.log(state.authentication)
  return {
    delivery_address: state.payment.delivery_address,
    cart: state.payment.cart,
    total_qty: state.payment.total_qty,
    delivery_charge: state.payment.delivery_charge,
    total_amount: state.payment.total_amount,
    free_shipping_order_amount: state.payment.free_shipping_order_amount,
    wishlist_item: state.payment.wishlist_item,
    pin_cart: state.payment.pin_cart,
    customer_access_token: state.authentication.customer_access_token,
    is_special_instruction: state.payment.is_special_instruction,
    is_cart_announcement: state.payment.is_cart_announcement,
    cart_announcement: state.payment.cart_announcement,
  };
};

export default connect(mapStateToProps, null)(CartPage);
