import React from 'react'
import CloseIcon from 'remixicon-react/CloseFillIcon';
import { styled } from '@mui/system';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';

const MyDialogTitle = styled(DialogTitle)(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  margin: 0,
  padding: theme.spacing(2),
}));

const MyDialogContent = styled(DialogContent)(({ theme }) => ({
  margin: 0,
  padding: theme.spacing(2),
}));

const MyDialogActions = styled(DialogActions)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  margin: 0,
  padding: theme.spacing(1),
}));

function DialogTitleComponent({ children, onClose }) {
  return (
    React.createElement(MyDialogTitle, { disableTypography: true },
      React.createElement(Typography, { variant: "h6" }, children),
      onClose && (
        React.createElement(IconButton, {
          "aria-label": "Close",
          onClick: onClose,
          sx: { position: 'absolute', right: 1, top: 1, color: theme => theme.palette.grey[500] }
        },
          React.createElement(CloseIcon, null)
        )
      )
    )
  );
}

function DialogContentComponent({ children }) {
  return React.createElement(MyDialogContent, null, children);
}

function DialogActionsComponent({ children }) {
  return React.createElement(MyDialogActions, null, children);
}

export {
  DialogTitleComponent,
  DialogContentComponent,
  DialogActionsComponent
};
