

export const RecipeConstants = {
    GET_CUSTOMER_RECIPE_LIST_REQUEST: "GET_CUSTOMER_RECIPE_LIST_REQUEST",
    GET_CUSTOMER_RECIPE_LIST_SUCCESS: "GET_CUSTOMER_RECIPE_LIST_SUCCESS",
    GET_CUSTOMER_RECIPE_LIST_FAILURE: "GET_CUSTOMER_RECIPE_LIST_FAILURE",
    CUSTOMER_RECIPE_NEW: "CUSTOMER_RECIPE_NEW",
    ADD_INGREDIENT: "ADD_INGREDIENT",
    SAVE_INGREDIENT: "SAVE_INGREDIENT",
    DELETE_INGREDIENT: "DELETE_INGREDIENT",
    IN_UP_CUSTOMER_RECIPE_REQUEST: "IN_UP_CUSTOMER_RECIPE_REQUEST",
    IN_UP_CUSTOMER_RECIPE_SUCCESS: "IN_UP_CUSTOMER_RECIPE_SUCCESS",
    IN_UP_CUSTOMER_RECIPE_FAILURE: "IN_UP_CUSTOMER_RECIPE_FAILURE",
    GET_CUSTOMER_RECIPE_DETAIL_REQUEST: "GET_CUSTOMER_RECIPE_DETAIL_REQUEST",
    GET_CUSTOMER_RECIPE_DETAIL_SUCCESS: "GET_CUSTOMER_RECIPE_DETAIL_SUCCESS",
    GET_CUSTOMER_RECIPE_DETAIL_FAILURE: "GET_CUSTOMER_RECIPE_DETAIL_FAILURE",
    ADD_COOKING_STEP: "ADD_COOKING_STEP",
    SAVE_COOKINF_STEP: "SAVE_COOKINF_STEP",
    DELETE_COOKINF_STEP: "DELETE_COOKINF_STEP",
};
