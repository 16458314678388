

export const OtherConstants = {
    GET_STATIC_CONTENT_REQUEST: 'GET_STATIC_CONTENT_REQUEST',
    GET_STATIC_CONTENT_SUCCESS: 'GET_STATIC_CONTENT_SUCCESS',
    GET_STATIC_CONTENT_FAILURE: 'GET_STATIC_CONTENT_FAILURE',
    GET_STORE_LIST_REQUEST: 'GET_STORE_LIST_REQUEST',
    GET_STORE_LIST_SUCCESS: 'GET_STORE_LIST_SUCCESS',
    GET_STORE_LIST_FAILURE: 'GET_STORE_LIST_FAILURE',
};


