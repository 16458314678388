import React, { Component } from 'react';
import { IconButton, AppBar, FocusTrap, Button, Divider, InputBase, Paper, List, DialogActions, DialogContentText, DialogContent, ListItemIcon, ListItemAvatar, Snackbar, Avatar, colors, Badge, DialogTitle, Chip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import SearchLineIcon from 'remixicon-react/SearchLineIcon';
import CloseCircleLineIcon from 'remixicon-react/CloseCircleLineIcon';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { connect } from 'react-redux';
import { itemaction } from '../../_actions/item.action';
import { isMobile } from 'react-device-detect';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});


class Searchbar extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dialogOpen: false,
            OTPview: false,
            searchValue: "",
            isLoggedIn: false,
            // Lname: "Bhavsar",

        };
    }

    componentDidMount() {
        this.props.dispatch(itemaction.get_product_search("is_top_serach_item"))
    }
    handleInputChange = (event) => {
        let searchValue = event.target.value
        this.setState({searchValue:searchValue})
        if(searchValue){
            this.props.dispatch(itemaction.get_product_search(searchValue))
        }else{
            this.props.dispatch(itemaction.get_product_search("is_top_serach_item"))
        }
      };

    handleSearchClick = () => {
        this.props.dispatch(itemaction.get_product_search(this.state.searchValue))
    };
    
    handleSearchCloseClick = () => {
        this.setState({searchValue:""})
        this.props.dispatch(itemaction.get_product_search("is_top_serach_item"))
    };
    handleTrendingSearchClick = (searchValue) => {
        this.setState({searchValue:searchValue})
        this.props.dispatch(itemaction.get_product_search(searchValue))
    };
   
    render() {
        return (
            <div className='px-3 position-absolute start-50 top-50 translate-middle'  style={{ minWidth: isMobile ? "100%":600, paddingBottom:isMobile ? 15:0, flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
                {/* <FocusTrap></FocusTrap> */}
                <Paper
                    component="form"
                    elevation={0}
                    onClick={() => this.setState({ dialogOpen: true })}
                    className='border'
                    sx={{ p: '8px 16px', display: 'flex', alignItems: 'center', justifyContent: "space-between", borderRadius: 2 }}
                >
                    <p className='fs-6 fw-light m-0 opacity-75'>Search by Symptoms or Products</p>

                    {/* <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                        <CloseCircleLineIcon />
                    </IconButton>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
                    <IconButton type="button" onClick={() => this.setState({ dialogOpen: true })} sx={{ p: '10px' }} aria-label="search">
                        <SearchLineIcon />
                    </IconButton>
                </Paper>

                <Dialog
                    open={this.state.dialogOpen}
                    // TransitionComponent={Transition}
                    disableEnforceFocus
                    onClose={() => this.setState({ dialogOpen: false })}
                    aria-describedby="alert-dialog-slide-description"
                    sx={{ borderRadius: 2, }}
                    maxWidth="md"

                    PaperProps={{
                        style: {
                            position: 'fixed',
                            top: 0,
                            minWidth:isMobile ? "100%": 900,
                            Width: isMobile ? "100%":900,
                            height: 500,
                            // background: "transparent"
                        },
                        elevation: 0,
                        sx: { borderRadius: 2 }
                    }}
                >
                    <DialogTitle>
                        <Paper
                            component="form"
                            elevation={0}
                            className='border'
                            sx={{ p: '8px 16px', display: 'flex', alignItems: 'center', borderRadius: 2 }}
                        >

                            <InputBase
                                sx={{ ml: 1, flex: 1 }}
                                placeholder={this.props.is_search_placeholder? this.props.search_placeholder:"Search by Symptoms or Products"}
                                autoFocus
                                value={this.state.searchValue}
                                onChange={this.handleInputChange}
                                inputProps={{ 'aria-label': 'search google maps' }}
                            />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <CloseCircleLineIcon onClick={this.handleSearchCloseClick}/>
                            </IconButton>
                            <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                            <IconButton type="button" sx={{ p: '10px' }} aria-label="search">
                                <SearchLineIcon  onClick={this.handleSearchClick}/>
                            </IconButton>
                        </Paper>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <p className='fw-semibold d-flex align-items-center'><TrendingUpIcon className='me-2' /><span>Trending Searches</span></p>

                            <div className='row mb-3'>
                                <div className='col-md-12'>
                                {this.props.top_search_product_name && this.props.top_search_product_name.map((item_name, i) => (
                                    <Chip className='m-1' label={item_name} key={i} onClick={() => this.handleTrendingSearchClick(item_name)} onDelete={() => {}}/>
                                ))}
                                
                                </div>
                            </div>

                            <p className='fw-semibold'>Serching for "{this.state.searchValue?this.state.searchValue:"top selling"}"</p>
                            <div className='row'>
                                {this.props.item_list && this.props.item_list.map((item) => (
                                    <a className='col-md-3 mb-3' href={"/item/" + (item.item_name).replace(/[^A-Z0-9]/ig, "-").toLowerCase() + "/" + item.item_id}>
                                        <div>
                                            <div className='card cursor-pointer  border-0'>
                                                <img src={item.feature_image} class="border rounded-2 card-img-top" alt="..." />
                                                <p className='small mb-0 one-line'>{item.item_name}</p>
                                            </div>
                                        </div>
                                    </a>
                                ))}
                            </div>
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

            </div>
        );
    }
}


function mapStateToProps(state) {
    let item_list = []
    if (state.item.item_search_list) {
        item_list = state.item.item_search_list
    }
    return {
        is_search_placeholder: state.general.general_data.is_search_placeholder,
        search_placeholder: state.general.general_data.search_placeholder,
        top_search_product_name: state.general.general_data.top_search_product_name,
        search_tags: state.general.general_data.search_tags,
        item_list: [...item_list],
    };
}
export default connect(mapStateToProps)(Searchbar);