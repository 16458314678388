

export const GeneralConstants = {
    GET_ACCESS_TOKEN_BYSUBDOMAIN_REQUEST: 'GET_ACCESS_TOKEN_BYSUBDOMAIN_REQUEST',
    GET_ACCESS_TOKEN_BYSUBDOMAIN_SUCCESS: 'GET_ACCESS_TOKEN_BYSUBDOMAIN_SUCCESS',
    GET_ACCESS_TOKEN_BYSUBDOMAIN_FAILURE: 'GET_ACCESS_TOKEN_BYSUBDOMAIN_FAILURE',
    HIDE_SHOW_DEPARTMENT_HEADER: 'HIDE_SHOW_DEPARTMENT_HEADER',
    GET_LONGURL_REQUEST: 'GET_LONGURL_REQUEST',
    GET_LONGURL_SUCCESS: 'GET_LONGURL_SUCCESS',
    GET_LONGURL_FAILURE: 'GET_LONGURL_FAILURE',
};


