import { CustomerConstants } from '../_constants/customer.constants';
import { API_URL, showErrorMessage, showSuccessMessage } from './utility.action'
import { reset } from 'redux-form';

export const customeraction = {
    get_state_list, get_customer_address_list, get_customer_address_detail, in_up_customer_address,
    get_customer_detail, update_customer_detail, delete_customer_address,
    update_customer_mobileno_request, update_customer_mobileno,
    get_product_search, add_update_customer_item_list, get_customer_item_list
};

function get_state_list() {

    var access_token = localStorage.getItem('access_token')
    const request_data = { "access_token": access_token }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/partner_service/get_state_list`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };
    function request() { return { type: CustomerConstants.GET_STATE_LIST_REQUEST } }
    function success(payload) { return { type: CustomerConstants.GET_STATE_LIST_SUCCESS, payload } }
    function failure(payload) { return { type: CustomerConstants.GET_STATE_LIST_FAILURE, payload } }
}


function get_customer_address_list() {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/customer_service/get_customer_address_list`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: CustomerConstants.GET_CUSTOMER_ADDRESS_LIST_REQUEST } }
    function success(payload) { return { type: CustomerConstants.GET_CUSTOMER_ADDRESS_LIST_SUCCESS, payload } }
    function failure(payload) { return { type: CustomerConstants.GET_CUSTOMER_ADDRESS_LIST_FAILURE, payload } }
}

function get_customer_address_detail(mode, address_id) {

    if (mode == "IN") {
        return dispatch => { dispatch(request_new()); }
    }


    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token, "address_id": address_id }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/customer_service/get_customer_address_detail`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: CustomerConstants.GET_CUSTOMER_ADDRESS_DETAIL_REQUEST } }
    function success(payload) { return { type: CustomerConstants.GET_CUSTOMER_ADDRESS_DETAIL_SUCCESS, payload } }
    function failure(payload) { return { type: CustomerConstants.GET_CUSTOMER_ADDRESS_DETAIL_FAILURE, payload } }
    function request_new() { return { type: CustomerConstants.CUSTOMER_ADDRESS_NEW } }
}

function in_up_customer_address(mode, fomdata) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token, "mode": mode, "data": fomdata }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/customer_service/in_up_customer_address`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    showErrorMessage(response.error_description)
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                    dispatch(get_customer_address_list())
                    dispatch(reset('addressdetail'));
                }
            });
    };
    function request() { return { type: CustomerConstants.IN_UP_CUSTOMER_ADDRESS_REQUEST } }
    function success(payload) { return { type: CustomerConstants.IN_UP_CUSTOMER_ADDRESS_SUCCESS, payload } }
    function failure(payload) { return { type: CustomerConstants.IN_UP_CUSTOMER_ADDRESS_FAILURE, payload } }
}

function get_customer_detail() {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/customer_service/get_customer_detail`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: CustomerConstants.GET_CUSTOMER_DETAIL_REQUEST } }
    function success(payload) { return { type: CustomerConstants.GET_CUSTOMER_DETAIL_SUCCESS, payload } }
    function failure(payload) { return { type: CustomerConstants.GET_CUSTOMER_DETAIL_FAILURE, payload } }
}

function update_customer_detail(fomdata) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token, "data": fomdata }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        fetch(`${API_URL}/customer_service/update_customer_detail`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    showErrorMessage(response.error_description)
                }
                else {
                    showSuccessMessage(response.error_description)
                    dispatch(get_customer_detail())
                }
            });
    };

}

function update_customer_mobileno_request(fomdata) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token, "data": fomdata }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/customer_service/update_customer_mobileno_request`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: CustomerConstants.UPDATE_CUSTOMER_MOBILENO_REQUEST } }
    function success(payload) { return { type: CustomerConstants.UPDATE_CUSTOMER_MOBILENO_SUCCESS, payload } }
    function failure(payload) { return { type: CustomerConstants.UPDATE_CUSTOMER_MOBILENO_FAILURE, payload } }
}

function update_customer_mobileno(fomdata) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token, "data": fomdata }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        fetch(`${API_URL}/customer_service/update_customer_mobileno`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    showErrorMessage(response.error_description)
                }
                else {
                    showSuccessMessage(response.error_description)
                    dispatch(get_customer_detail())
                }
            });
    };
}

function delete_customer_address(address_id) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token, "address_id": address_id }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        fetch(`${API_URL}/customer_service/delete_customer_address`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    showErrorMessage(response.error_description)
                }
                else {
                    showSuccessMessage(response.error_description)
                    dispatch(get_customer_address_list())
                }
            });
    };

}

function get_product_search(searchtext) {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token, "searchtext": searchtext }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/product_service/get_product_search`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: CustomerConstants.GET_PRODUCT_SEARCH_REQUEST } }
    function success(payload) { return { type: CustomerConstants.GET_PRODUCT_SEARCH_SUCCESS, payload } }
    function failure(payload) { return { type: CustomerConstants.GET_PRODUCT_SEARCH_FAILURE, payload } }
}


function get_customer_item_list() {


    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/customer_service/get_customer_item_list`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: CustomerConstants.GET_CUSTOMER_ITEM_LIST_REQUEST } }
    function success(payload) { return { type: CustomerConstants.GET_CUSTOMER_ITEM_LIST_SUCCESS, payload } }
    function failure(payload) { return { type: CustomerConstants.GET_CUSTOMER_ITEM_LIST_FAILURE, payload } }
}


function add_update_customer_item_list(data) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token, "data": data }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        fetch(`${API_URL}/customer_service/add_update_customer_item_list`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                }
                else {
                    dispatch(get_customer_item_list())
                }
            });
    };

}
