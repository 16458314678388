import React, { Component } from 'react';
import CategoryImg from '../../assets/cardiac.jpg'
import { isMobile } from 'react-device-detect';
import { IconButton, AppBar, Toolbar, Button, ListItem, Paper, Link, List, DialogActions, Typography, DialogContent, ListItemText, Switch, ListSubheader, Avatar, colors, Badge, DialogTitle, ListItemButton, Fab } from '@mui/material';
class Category extends Component {
    render() {
        const { catName,department_id,LCategoryImg } = this.props;

        return (
            <a href={`/dep/${(catName).replace(/[^A-Z0-9]/ig, "-").toLowerCase()}/${department_id}`} className={`col-md-1 ${isMobile ? "px-2" : "px-3"} text-decoration-none`}>
                <p className='text-center mb-1'>
                    <img src={LCategoryImg?LCategoryImg:CategoryImg} className="w-100" style={{"border-radius": "28px", "border": "1px solid #ececec"}} alt="..." />
                </p>
                <p className={`text-dark text-center ${isMobile ? "small" : ""}`}>{catName}</p>
            </a>
        );
    }
}

export default Category;

