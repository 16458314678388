

export const OrderConstants = {
    GET_ORDER_DETAIL_REQUEST: 'GET_ORDER_DETAIL_REQUEST',
    GET_ORDER_DETAIL_SUCCESS: 'GET_ORDER_DETAIL_SUCCESS',
    GET_ORDER_DETAIL_FAILURE: 'GET_ORDER_DETAIL_FAILURE',
    GET_CUSTOMER_ORDER_LIST_REQUEST: 'GET_CUSTOMER_ORDER_LIST_REQUEST',
    GET_CUSTOMER_ORDER_LIST_SUCCESS: 'GET_CUSTOMER_ORDER_LIST_SUCCESS',
    GET_CUSTOMER_ORDER_LIST_FAILURE: 'GET_CUSTOMER_ORDER_LIST_FAILURE',
    GET_BASKET_LIST_REQUEST: 'GET_BASKET_LIST_REQUEST',
    GET_BASKET_LIST_SUCCESS: 'GET_BASKET_LIST_SUCCESS',
    GET_BASKET_LIST_FAILURE: 'GET_BASKET_LIST_FAILURE',
    GET_BASKET_DETAIL_REQUEST: 'GET_BASKET_DETAIL_REQUEST',
    GET_BASKET_DETAIL_SUCCESS: 'GET_BASKET_DETAIL_SUCCESS',
    GET_BASKET_DETAIL_FAILURE: 'GET_BASKET_DETAIL_FAILURE',
};
