import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Divider, Radio, RadioGroup, FormControlLabel, Button, Link } from '@mui/material';
import MapPin2FillIcon from 'remixicon-react/MapPin2FillIcon';
import ShieldStarFillIcon from 'remixicon-react/ShieldStarFillIcon';
import Coupon from '../assets/coupon.svg';
import RazerpayUpi from '../components/payment/razerpay_upi';
import RazerpayWallet from '../components/payment/razerpay_wallet';
import RazerpayCard from '../components/payment/razerpay_card';
import RazerpayNetbanking from '../components/payment/razerpay_netbanking';
import { paymentaction } from '../_actions/payment.action';
import { API_URL, ENGINE_TYPE, DEVICE_TYPE, DEVICE_INFO } from '../_actions/utility.action';
import { useNavigate } from 'react-router-dom';
import { itemaction } from '../_actions/item.action';

const axios = require('axios');

const PaymentPage = (props) => {
  const [selectedPaymentOption, setSelectedPaymentOption] = useState("upi1");
  const [PaymentState, setPaymentState] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    console.log('88888useEffect')
    props.dispatch(paymentaction.get_order_customer_wallet_balance());
    onLoadRazorpay()
  }, []);

  const onLoadRazorpay = () => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/razorpay.js";
    document.body.appendChild(script);

    const razorpay_key_id = props.razorpay_key_id;
    script.onload = () => {
        const razorpay = new window.Razorpay({
        key: razorpay_key_id,
        });

    razorpay.once('ready', function (response) {
        console.log(response)
      let wallet_list = {};
      Object.keys(response.methods.wallet).forEach(function (key) {
        if (response.methods.wallet[key] === true) {
          wallet_list[key] = true;
        }
      });
      setPaymentState({
        payment_gateway: response.methods,
        wallet_list: wallet_list,
      });
    });
    };
    script.onerror = () => console.error('Error loading Razorpay script'); // Log an error if script loading fails
  };

  const onRadioClick = (value) => {
    setSelectedPaymentOption(value);
  };

const place_order = (data) => {
    let self = props
    const access_token = localStorage.getItem('access_token')
    const customer_access_token = localStorage.getItem("customer_access_token")
    const cart = props.cart;
    const order_type = "delivery";
    const pickup_store = null;
    const delivery_address = props.delivery_address
    const is_delivery_time_slot = false;
    const delivery_timeslot = null;
    const special_instructions = "";
    const payment_gateway = "razorpay";
    const payment_type = "Online";
    const is_wallet_used = false;
    const wallet_amount_used = 0.00;
    const engine_type = ENGINE_TYPE;
    const device_type = DEVICE_TYPE;
    const device_info = DEVICE_INFO;

    const is_express_checkout = props.is_express_checkout;
    const is_saved_address = props.is_saved_address;
    const is_delivery_anytime = props.is_delivery_anytime;
    const is_delivery_rightaway = props.is_delivery_rightaway;
    const discount_code = props.discount_code;
    const method = data?.method

    axios.post(API_URL + '/order_service/create_order', {
        access_token: access_token,
        customer_access_token: customer_access_token,
        cart: cart,
        order_type: order_type,
        delivery_address: delivery_address,
        pickup_store: pickup_store,
        is_delivery_time_slot: is_delivery_time_slot,
        delivery_timeslot: delivery_timeslot,
        payment_gateway: payment_gateway,
        payment_type: payment_type,
        payment_method: method,
        is_pay_later: false,
        special_instructions: special_instructions,
        is_wallet_used: is_wallet_used,
        wallet_amount_used: wallet_amount_used,
        engine_type: engine_type,
        device_type: device_type,
        device_info: device_info,
        is_express_checkout:is_express_checkout,
        is_saved_address:is_saved_address,
        is_delivery_anytime:is_delivery_anytime,
        is_delivery_rightaway:is_delivery_rightaway,
        discount_code:discount_code
    })
        .then(function (response) {
            console.log(response)
            if (response.data.error_code === "1") {

                self.dispatch(itemaction.add_remove_item_cart('REMOVEALL', {}));

                const razorpay = new window.Razorpay({
                    key: response.data.data.razorpay_key_id,
                    callback_url: API_URL + '/order_service/razerpaycheckoutstatus?callbackurl=' + window.location.origin + "&order_id=" + response.data.data.order_id + "&access_token=" + access_token,
                    redirect: true
                });

                var netamount = parseFloat(response.data.data.netamount)
                netamount = parseInt(netamount * 100)

                data.amount = netamount;
                data.currency = "INR";
                data.email = response.data.data.email;
                data.contact = response.data.data.mobileno;
                data.notes = {
                    address: 'Web_' + response.data.data.order_no,
                };
                data.order_id = response.data.data.razerpay_orderid;
                razorpay.createPayment(data);
            }
            else {
            }
        })
        .catch(function (error) {
            console.log(error)
        });
}


const place_order_cod = (otp) => {
    var self = props
    const access_token = localStorage.getItem('access_token')
    const customer_access_token = localStorage.getItem("customer_access_token")
    const cart = props.cart;
    const order_type = "delivery";
    const pickup_store = null;
    const delivery_address = props.delivery_address
    const is_delivery_time_slot = false;
    const delivery_timeslot = null;
    const special_instructions = "";
    const payment_gateway = "COD";
    const payment_type = "COD";
    const payment_method = "COD";
    const is_wallet_used = false;
    const wallet_amount_used = 0.00;
    const engine_type = ENGINE_TYPE;
    const device_type = DEVICE_TYPE;
    const device_info = DEVICE_INFO;
    const is_express_checkout = props.is_express_checkout;
    const is_saved_address = props.is_saved_address;
    const is_delivery_anytime = props.is_delivery_anytime;
    const is_delivery_rightaway = props.is_delivery_rightaway;
    const discount_code = props.discount_code;
    const is_otp_authentication = true
    const request_id = Object()
    
    axios.post(API_URL + '/order_service/create_order', {
        access_token: access_token,
        customer_access_token: customer_access_token,
        cart: cart,
        order_type: order_type,
        delivery_address: delivery_address,
        pickup_store: pickup_store,
        is_delivery_time_slot: is_delivery_time_slot,
        delivery_timeslot: delivery_timeslot,
        payment_gateway: "",
        payment_type: payment_type,
        payment_method: payment_method,
        is_otp_authentication: is_otp_authentication,
        request_id: request_id,
        otp: otp,
        is_pay_later: true,
        special_instructions: special_instructions,
        is_wallet_used: is_wallet_used,
        wallet_amount_used: wallet_amount_used,
        engine_type: ENGINE_TYPE,
        device_type: DEVICE_TYPE,
        device_info: DEVICE_INFO,
        is_express_checkout:is_express_checkout,
        is_saved_address:is_saved_address,
        is_delivery_anytime:is_delivery_anytime,
        is_delivery_rightaway:is_delivery_rightaway,
        discount_code:discount_code
    })
        .then(function (response) {
            if (response.data.error_code == "1") {
                self.dispatch(itemaction.add_remove_item_cart('REMOVEALL', {}));
                navigate("/orderstatus/" + response.data.data.order_id);
            }
            else {
            //if code ordered fails to create
            }
        })
        .catch(function (error) {
        });
}

  return (
    <div>
        <section className='py-5'>
            <div className='container'>
                <div className='row'>
                    <div className='col-md-8'>
                        <h6 className='d-flex justify-content-between mb-3'>
                            <span className='text-start'>Payment Options</span>
                            <span className='text-end text-muted fw-light'><ShieldStarFillIcon className='me-1 text-primary' />100% Secure Payments</span>
                        </h6>
                        <RadioGroup
                            aria-labelledby="payment-radio-group-label"
                            defaultValue={selectedPaymentOption}
                            name="payment-radio-group"
                        >
                            <div className='card rounded-4 mb-3' onClick={() => onRadioClick("upi")}>
                                <div className='card-header d-flex align-items-center justify-content-between bg-transparent border-0 cursor-pointer'>
                                    <h6 className='mb-0'>UPI</h6>
                                    <FormControlLabel value="upi" className='m-0' control={<Radio checked={selectedPaymentOption === "upi"} />} />
                                </div>
                                {selectedPaymentOption === "upi" && 
                                    <RazerpayUpi place_order={place_order} />
                                }

                            </div>

                            <div className='card rounded-4 mb-3' onClick={() => onRadioClick("wallets")}>
                                <div className='card-header d-flex align-items-center justify-content-between bg-transparent border-0 cursor-pointer'>
                                    <h6 className='mb-0'>Wallets</h6>
                                    <FormControlLabel value="wallets" className='m-0' control={<Radio checked={selectedPaymentOption === "wallets"} />} />
                                </div>
                                {selectedPaymentOption === "wallets" && 
                                    <RazerpayWallet place_order={place_order}  wallet={PaymentState.wallet_list}/>
                                }

                            </div>

                            <div className='card rounded-4 mb-3' onClick={() => onRadioClick("cards")}>
                                <div className='card-header d-flex align-items-center justify-content-between bg-transparent border-0 cursor-pointer'>
                                    <h6 className='mb-0'>Credit/Debit Card</h6>
                                    <FormControlLabel value="cards" className='m-0' control={<Radio checked={selectedPaymentOption === "cards"} />} />
                                </div>
                                {selectedPaymentOption === "cards" && 
                                <RazerpayCard place_order={place_order}/>
                                }
                            </div>

                            <div className='card rounded-4 mb-3' onClick={() => onRadioClick("netbanking")}>
                                <div className='card-header d-flex align-items-center justify-content-between bg-transparent border-0 cursor-pointer'>
                                    <h6 className='mb-0'>Net Banking</h6>
                                    <FormControlLabel value="netbanking" className='m-0' control={<Radio checked={selectedPaymentOption === "netbanking"} />} />
                                </div>
                                {selectedPaymentOption === "netbanking" && <div className='card-body py-3 bg-transparent'>
                                <RazerpayNetbanking place_order={place_order}  netbanking_list={PaymentState.payment_gateway.netbanking}/>

                                
                                </div>}
                            </div>
                                    <div className='card rounded-4 mb-3' onClick={() => onRadioClick("cod")}>
                                        <div className='card-header d-flex align-items-center justify-content-between bg-transparent border-0 cursor-pointer'>
                                            <h6 className='mb-0'>Cash On Delivery</h6>
                                            <FormControlLabel value="cod" className='m-0' control={<Radio checked={selectedPaymentOption === "cod"} />} />
                                        </div>
                                        {selectedPaymentOption === "cod" && <div className='card-body py-3 bg-transparent'>
                                            <p className='text-muted fw-light small'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec ante risus. Morbi finibus malesuada.</p>
                                            <Button variant="contained" sx={{ p: 2 }} onClick={() => { place_order_cod()}} className='fw-light w-100' disableElevation>Place Order & Pay ₹2209.42</Button>
                                        </div>}
                                    </div>

                        </RadioGroup>
                    </div>

                    <div className='col-md-4'>
                        <div className='card rounded-4 mb-3'>
                            <div className='card-body py-3 bg-transparent'>
                                <h6 className='fw-semibold mb-0'>6 Items in your Cart</h6>
                            </div>
                        </div>

                        <div className='card rounded-4 mb-3'>
                            <div className='card-body py-3 bg-transparent'>
                                <div className='w-100 p-2'>
                                    <h6 className='fw-semibold'>Cart Summary</h6>
                                    <div className='d-flex justify-content-between my-1'><span className='text-start'>Subtotal</span> <span className='text-end'>₹ 3,145.00</span></div>
                                    <div className='d-flex justify-content-between text-muted my-1'><span className='text-start'>Shipping Charges</span> <span className='text-end'>₹ 00.00</span></div>
                                    <div className='d-flex justify-content-between text-muted my-1'><span className='text-start'>Taxes (Included in MRP)</span> <span className='text-end'>₹ 145.00</span></div>
                                    <div className='d-flex justify-content-between fw-semibold text-success my-1'><span className='text-start'><img src={Coupon} className='me-2' alt=''/>Coupon Code (MAX40)</span> <span className='text-end'>- ₹ 145.00</span></div>
                                    <Divider className='my-3' />
                                    <div className='d-flex justify-content-between fw-semibold my-1 mb-0'><h6 className='text-start'>Total</h6> <h6 className='text-end'>₹ 3,000.00</h6></div>
                                </div>
                            </div>
                        </div>

                        <div className='card rounded-4 mb-3 border-success bg-success-subtle'>
                            <div className='card-body py-3 bg-transparent d-flex align-items-start'>
                                <img src={Coupon} className='me-2' alt=''/>
                                <div className='w-100'>
                                    <h6 className='text-success mb-3'>Total savings of ₹ 294.00 on this order</h6>
                                    <div className='d-flex justify-content-between my-1 text-muted'><span className='text-start'>MRP Discount 40%</span> <span className='text-end'>- ₹ 145.00</span></div>
                                    <div className='d-flex justify-content-between text-muted my-1'>
                                        <p className='text-start mb-0'>Delivery charges</p>
                                        <p className='text-end mb-0'>
                                            <span className='text-decoration-line-through'>₹ 149.00</span>
                                            <span className='text-decoration-none ms-2 text-success fw-semibold'>FREE</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='card rounded-4 mb-3 '>
                            <div className='card-body py-3 bg-transparent'>
                                <h6 className='d-flex align-items-center fw-semibold mb-0'><MapPin2FillIcon className='text-primary me-1' /><span>Delivery Address</span></h6>
                                <Divider className='my-3' />
                                <p className='text-muted mb-0'>9th floor the chambers, nr the grand bhagavati hotel bodakdev, Ahmedabad 380054</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        </section>
    </div>
  );
};


function mapStateToProps(state) {
    return {
        cart: state.payment.cart,
        is_cod: state.payment.is_cod,
        is_online: state.payment.is_online,
        razorpay_key_id: state.payment.razorpay_key_id,
        customer_access_token: state.authentication.customer_access_token,
        customer_detail: state.authentication.customer_detail,
        delivery_address: state.payment.delivery_address,
        pickup_store: state.payment.pickup_store,
        is_pay_later: state.payment.is_pay_later,
        is_delivery_time_slot: state.payment.is_delivery_time_slot,
        delivery_timeslot: state.payment.delivery_timeslot,
        special_instructions: state.payment.special_instructions,
        total_amount: state.payment.total_amount,
        is_wallet_active: state.payment.is_wallet_active,
        is_wallet_used: state.payment.is_wallet_used,
        wallet_amount_used: state.payment.wallet_amount_used,
        order_type:state.payment.order_type,
        is_express_checkout:state.payment.is_express_checkout,
        is_saved_address:state.payment.is_saved_address,
        is_delivery_anytime:state.payment.is_delivery_anytime,
        is_delivery_rightaway:state.payment.is_delivery_rightaway,
        discount_code:state.payment.discount_code
    };
}
export default connect(mapStateToProps, null)(PaymentPage);
