

import { PaymentConstants } from '../_constants/payment.constants';


export function payment(state = {}, action) {
    // console.log(action.type) 
    switch (action.type) {
        case PaymentConstants.GET_CART_PAYMENT_REQUEST:
            return {
                ...state,
                cart: null,
                delivery_address: null,
                pickup_store: null,
                is_auth_wallet: null,
                coupen_details:state.coupen_details,
                is_applied_coupon:state.is_applied_coupon,
            };
        case PaymentConstants.GET_CART_PAYMENT_SUCCESS:
            var cart = action.payload.data.cart;
            var is_delivery_charge = action.payload.data.is_delivery_charge;
            var is_other_taxes_charges = action.payload.data.is_other_taxes_charges;

            if (cart === null) {
                cart = []
            }
            var amount = cart.reduce((sum, { amount }) => sum += amount, 0)
            var total_amount = 0
            var delivery_charge = 0
            var new_taxes_charges_amount = 0
            if(is_delivery_charge){
                delivery_charge = action.payload.data.delivery_charge
            }
            if(is_other_taxes_charges){
                new_taxes_charges_amount = action.payload.data.taxes_charges_amount

            }
            /* action.payload.data.shipping_rate.forEach(data => {
                if (amount >= data.order_amount) {
                    delivery_charge = data.rate
                }
            }) */
            total_amount = amount + delivery_charge + new_taxes_charges_amount;
            let special_instructions = ""
            if (localStorage.getItem("special_instructions") && localStorage.getItem("special_instructions") !== "") {
                special_instructions = localStorage.getItem("special_instructions");
            }
            var is_disable_code_payment = false
            var disable_code_payment_note = false

            if (state.order_type === "delivery") {
                if(action.payload.data.cod_delivery_amount > amount){
                    is_disable_code_payment = true;
                    disable_code_payment_note = action.payload.data.cod_delivery_announcement;
                }
            }
            else if (state.order_type === "storepickup") {
                if(action.payload.data.cod_storepickup_amount > action.payload.data.amount){
                    is_disable_code_payment = true;
                    disable_code_payment_note = action.payload.data.cod_storepickup_announcement;
                }
            }
            else if (state.order_type === "curbsidepickup") {
                if(action.payload.data.cod_curbsidepickup_amount > action.payload.data.amount){
                    is_disable_code_payment = true;
                    disable_code_payment_note = action.payload.data.cod_curbsidepickup_announcement;
                }
            }
            return {
                ...state,
                coupen_details:state.coupen_details,
                is_applied_coupon:state.is_applied_coupon,
                total_qty: cart.reduce((sum, { qty }) => sum += qty, 0),
                amount: amount,
                delivery_charge: delivery_charge,
                total_amount: total_amount,
                cart: cart,
                razorpay_key_id: action.payload.data.razorpay_key_id,
                is_delivery: action.payload.data.is_delivery,
                is_storepickup: action.payload.data.is_storepickup,
                is_curbsidepickup: action.payload.data.is_curbsidepickup,
                is_delivery_time_slot: action.payload.data.is_delivery_time_slot,
                shipping_rate: action.payload.data.shipping_rate,
                is_cod_delivery: action.payload.data.is_cod_delivery,
                is_online_delivery: action.payload.data.is_online_delivery,
                is_cod_storepickup: action.payload.data.is_cod_storepickup,
                is_online_storepickup: action.payload.data.is_online_storepickup,
                is_cod_curbsidepickup: action.payload.data.is_cod_curbsidepickup,
                is_online_curbsidepickup: action.payload.data.is_online_curbsidepickup,
                is_pay_later: action.payload.data.is_pay_later,
                is_discount: action.payload.data.is_discount,
                delivery_address: null,
                pickup_store: null,
                is_special_instruction: action.payload.data.is_special_instruction,
                is_cart_announcement: action.payload.data.is_cart_announcement,
                cart_announcement: action.payload.data.cart_announcement,
                cod_delivery_amount : action.payload.data.cod_delivery_amount,
                cod_storepickup_amount :action.payload.data.cod_storepickup_amount,
                cod_curbsidepickup_amount : action.payload.data.cod_curbsidepickup_amount,
                cod_delivery_announcement: action.payload.data.cod_delivery_announcement,
                cod_storepickup_announcement:action.payload.data.cod_storepickup_announcement,
                cod_curbsidepickup_announcement:action.payload.data.cod_curbsidepickup_announcement,
                is_disable_code_payment:is_disable_code_payment,
                disable_code_payment_note:disable_code_payment_note,
                taxes_charges_amount:action.payload.data.taxes_charges_amount,
                taxes_charges:action.payload.data.taxes_charges,
                is_delivery_charge:is_delivery_charge,
                is_other_taxes_charges:is_other_taxes_charges,
            };
        case PaymentConstants.GET_CART_PAYMENT_FAILURE:
            return {
                ...state,
            };
        case PaymentConstants.CHANGE_ORDER_TYPE:
            var is_cod = true
            var is_online = true

            if (action.data === "delivery") {
                is_cod = state.is_cod_delivery
                is_online = state.is_online_delivery
            }
            else if (action.data === "storepickup") {
                is_cod = state.is_cod_storepickup
                is_online = state.is_online_storepickup
            }
            else if (action.data === "curbsidepickup") {
                is_cod = state.is_cod_curbsidepickup
                is_online = state.is_online_curbsidepickup
            }

            var cart = state.cart;
            var amount = cart && cart.reduce((sum, { amount }) => sum += amount, 0)
            var total_amount = 0
            var delivery_charge = 0
            let free_shipping_order_amount = 0
            let taxes_charges_amount = 0
            if (action.data === "delivery") {
                delivery_charge = state.delivery_charge
                // state.shipping_rate && state.shipping_rate.forEach(data => {
                //     if (amount >= data.order_amount) {
                //         delivery_charge = data.rate
                //     }
                //     if (data.rate === 0) {
                //         free_shipping_order_amount = data.order_amount;
                //     }
                // })
            }
            if(state.is_other_taxes_charges){
                taxes_charges_amount = state.taxes_charges_amount
            }
            total_amount = amount + delivery_charge+taxes_charges_amount;
            return {
                ...state,
                amount: amount,
                delivery_charge: delivery_charge,
                total_amount: total_amount,
                free_shipping_order_amount: free_shipping_order_amount,
                order_type: action.data,
                is_cod: is_cod,
                is_online: is_online
            };
        case PaymentConstants.GET_DELIVERY_TIME_SLOT_REQUEST:
            return {
                ...state,
                delivery_date_list: null
            };
        case PaymentConstants.GET_DELIVERY_TIME_SLOT_SUCCESS:
            return {
                ...state,
                delivery_date_list: action.payload.data.delivery_date_list,
            };
        case PaymentConstants.GET_DELIVERY_TIME_SLOT_FAILURE:
            return {
                ...state,
            };
        case PaymentConstants.GET_NEW_DELIVERY_TIME_SLOT_REQUEST:
            return {
                ...state,
                schedule_date_list:[],
                today_tomorrow_date_list:[]
            };
        case PaymentConstants.GET_NEW_DELIVERY_TIME_SLOT_SUCCESS:
            return {
                ...state,
                schedule_date_list: action.payload.data.schedule_date_list,
                today_tomorrow_date_list: action.payload.data.today_tomorrow_date_list,
            };
        case PaymentConstants.GET_NEW_DELIVERY_TIME_SLOT_FAILURE:
            return {
                ...state,
            };
        case PaymentConstants.SELECT_DELIVERY_ADDRESS:
            return {
                ...state,
                delivery_address: action.data,
            };
        case PaymentConstants.SELECT_STORE:
            return {
                ...state,
                pickup_store: action.data,
            };
        case PaymentConstants.SELECT_STORE_TYPE:
            return {
                ...state,
                selected_store_type: action.data,
            };
        case PaymentConstants.CHANGE_DELIVERY_ADDRESS:
            return {
                ...state,
                delivery_address: null,
                pickup_store: null,
                delivery_timeslot: null,
            };
        case PaymentConstants.SELECT_DELIVERY_TIMESLOT:
            return {
                ...state,
                delivery_timeslot: action.data,
                is_delivery_rightaway:action.is_delivery_rightaway || false,
                is_delivery_anytime:action.is_delivery_anytime || false,
                
            };
        case PaymentConstants.CHANGE_DELIVERY_TIMESLOT:
            return {
                ...state,
                delivery_timeslot: null,
            };
        case PaymentConstants.GET_ORDER_STORE_LIST_REQUEST:
            return {
                ...state,
                store_list: null
            };
        case PaymentConstants.GET_ORDER_STORE_LIST_SUCCESS:
            return {
                ...state,
                store_list: action.payload.data.store_list,
            };
        case PaymentConstants.GET_ORDER_STORE_LIST_FAILURE:
            return {
                ...state,
            };
        case PaymentConstants.GET_ORDER_CUSTOMER_WALLET_BALANCE_REQUEST:
            return {
                ...state,
                is_wallet_used: false,
                is_wallet_active: null,
                is_wallet_block: null,
                wallet_amount: null
            };
        case PaymentConstants.GET_ORDER_CUSTOMER_WALLET_BALANCE_SUCCESS:
            return {
                ...state,
                is_wallet_active: action.payload.data.is_wallet_active,
                is_wallet_block: action.payload.data.is_wallet_block,
                wallet_amount: action.payload.data.wallet_amount,
            };
        case PaymentConstants.GET_ORDER_CUSTOMER_WALLET_BALANCE_FAILURE:
            return {
                ...state,
            };
        case PaymentConstants.SELECT_UNSELECT_CUSTOMER_WALLET_BALANCE:

            var cart = state.cart;
            var amount = cart.reduce((sum, { amount }) => sum += amount, 0)
            var total_amount = 0
            var delivery_charge = 0
            var wallet_amount = state.wallet_amount
            var is_wallet_used = !state.is_wallet_used
            var wallet_amount_used = 0;

            if (state.order_type === "delivery") {
                state.shipping_rate.forEach(data => {
                    if (amount >= data.order_amount) {
                        delivery_charge = data.rate
                    }
                })
            }
            if(state.is_other_taxes_charges){
                taxes_charges_amount = state.taxes_charges_amount 
            }
            total_amount = amount + delivery_charge + taxes_charges_amount;
            if (is_wallet_used === true) {
                if (wallet_amount >= total_amount) {
                    wallet_amount_used = total_amount
                    total_amount = 0
                }
                else {
                    total_amount = total_amount - wallet_amount
                    wallet_amount_used = wallet_amount
                }
            }
            return {
                ...state,
                total_amount: total_amount,
                is_wallet_used: is_wallet_used,
                wallet_amount_used: wallet_amount_used
            };
        case PaymentConstants.AUTHENTICATE_WALLET_OTP_REQUEST:
            return {
                ...state,
                is_auth_wallet: null,
            };
        case PaymentConstants.AUTHENTICATE_WALLET_OTP_SUCCESS:
            return {
                ...state,
                is_auth_wallet: true,
            };
        case PaymentConstants.AUTHENTICATE_WALLET_OTP_FAILURE:
            return {
                ...state,
            };
        case PaymentConstants.GET_PAYLATER_ORDER_DETAIL_REQUEST:
            return {
                ...state,
            };
        case PaymentConstants.GET_PAYLATER_ORDER_DETAIL_SUCCESS:
            return {
                ...state,
                order_detail: action.payload.data,
            };
        case PaymentConstants.GET_PAYLATER_ORDER_DETAIL_FAILURE:
            return {
                ...state,
            };
        
        case PaymentConstants.APPLY_COUPEN_CODE_REQUEST:
            return {
                ...state,
            };
        case PaymentConstants.APPLY_COUPEN_CODE_SUCCESS:
            return {
                ...state,
                coupen_details:action.payload.data,
                is_applied_coupon:true,
            };
        case PaymentConstants.APPLY_COUPEN_CODE_FAILURE:
            return {
                ...state,
                coupen_details:action.payload.data,
                is_applied_coupon:true,

            };
        case PaymentConstants.REMOVE_COUPEN_CODE:
            return {
                ...state,
                coupen_details:{},
                is_applied_coupon:false,
            };
        case PaymentConstants.SPECIAL_INSTRUCTIONS:
            return {
                ...state,
                special_instruction:action.data,
            };
        default:
            return state
    }
}




