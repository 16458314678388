import React, { Component } from 'react';
import ProductCard from '../General/ProductCard';
import { connect } from 'react-redux';
import { isMobile } from 'react-device-detect';


class ProductsSection extends Component {
    render() {
        const { department_name } = this.props;
        if(!this.props.item_list){
         return ;
        }
        return (
            <section className='py-3'> 
                <div className='container'>
                    <h3 className='fw-semibold mb-3'>{department_name}</h3>
                    
                    <div className='row'>
                        {this.props.item_list && this.props.item_list.map((item, index) => {
                            let item_variant = item.item_variant && item.item_variant[0]
                            return <div className={`col-md-3 col-6 ${isMobile ? "p-3 mb-0" : "mb-3"}`} style={{outline: isMobile ? "1px solid rgba(40,44,63,.05)" : null}} >
                                <ProductCard Image={item.feature_image} ImageHover={item.hover_image} Name={item.item_name} Price={item_variant.rate} item={item} />
                            </div>
                        })}
                    </div>
                </div>
            </section>
        );
    }
}

function mapStateToProps(state) {
    let item_list = []
    if (state.item.item_list) {
        item_list = state.item.item_list
    }
    return {
        item_list: [...item_list],
    };
}
export default connect(mapStateToProps)(ProductsSection);
