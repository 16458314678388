import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProductCard from '../General/ProductCard';
import { dashboardaction } from '../../_actions/dashboard.action';
import { Divider, Radio, RadioGroup, FormControlLabel, Button, Link, Paper, InputBase, Tooltip, IconButton } from '@mui/material';

import { isMobile } from 'react-device-detect';


class CollectionSection extends Component {
    
    constructor(props) {
        super(props);
    }


    componentDidMount() {
        this.props.dispatch(dashboardaction.get_collection_list(this.props.department_id))
    }
    render() {
        return (this.props.collection_list && this.props.collection_list.length > 0 ?

            <section className='py-3'> 
                <div className='container'>
                <div className="row pb-1">
                    <h3 className='fw-semibold mb-3'>Quick Links</h3>
                </div>
                <div className="row seven-cols">
                    {this.props.collection_list.map((data, index) => {
                         return  <a href={`/col/${(data.collection_name).replace(/[^A-Z0-9]/ig, "-").toLowerCase()}/${data.collection_id}`} className={`col-md-1 ${isMobile ? "px-2" : "px-3"} text-decoration-none`}>
                          <p className='text-center mb-1'>
                              <img src={data.collection_image} className="w-100" alt={data.collection_image} />
                          </p>
                          <p className={`text-dark text-center ${isMobile ? "small" : ""}`}>{data.collection_name}</p>
                      </a>
                    })}
                </div>
                </div>
            </section>
            : null
        );
    }
} 

function mapStateToProps(state) {
    return {
        collection_list: state.dashboard.collection_list,
    };
}
export default connect(mapStateToProps)(CollectionSection);
