import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar, Typography, Button, Chip, Divider, InputBase, Paper, Badge, Card, CardMedia, CardContent, CardActionArea, Link } from '@mui/material';
import TestVideo from "../assets/test_video.mp4"
import FullBanner from "../assets/banner_full.jpg"
import MainHeader from '../components/General/MainHeader';
import TopHeader from '../components/General/TopHeader';
import BannersHome from '../components/LandingPage/BannersHome';
import TopProductsSection from '../components/LandingPage/TopProductsSection';
import AllCategories from '../components/LandingPage/AllCategories';
import BlogSection from '../components/LandingPage/BlogSection';
import Footer from '../components/General/Footer';
import { isMobile } from 'react-device-detect';
import RndBanner from "../assets/rnd.jpg"

class RnD extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }


  render() {
    return (
      <>
        <section className="" style={{ background: "url(" + RndBanner + ")" }}>
          <div className="container" style={{ paddingTop: 80, paddingBottom: 80 }}>
            <h1 className='display-4 fw-semibold'>Reasearch & Development</h1>
          </div>
        </section>

        <section className='py-5'>
          <div className='container'>
            <p>At Zoetic Ayurvedics, scientists unlock the secrets to good health by unraveling mysteries behind diseases. As a research-oriented company, we ensure that we produce safe, efficacious remedies using the principles of Ayurveda.</p>
            <p>Our Research and Development team is headed by experienced scientists specializing in Ayurveda, Microbiology, Biotechnology and Pharmaceutical Science. Our team works tirelessly to develop new formulations, test safety and efficacy of formulations, and develop accurate and precise analytical methods. Currently, our lab is equipped with state of the art, chromatography, microbiology and spectrophotometry facilities. The expansion of our labs includes new analytical infrastructure along with cutting edge formulation and development equipment.</p>
            <p>Our Research scientists constantly evolve by conducting research in lifestyle related disorders, skin diseases, childs, elderly persons and womens health. They actively participate in various National and International conferences, presenting their research and innovation.</p>
          </div>
        </section>
      </>

    );
  }
}

export default RnD;