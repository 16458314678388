import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form'
import { general, get_long_url } from './general.reducer';
import { dashboard } from './dashboard.reducer';
import { item } from './item.reducer';
import { authentication } from './authentication.reducer';
import { customer } from './customer.reducer';
import { payment } from './payment.reducer';
import { order } from './order.reducer';
import { other } from './other.reducer';
import { support } from './support.reducer';
import { recipe } from './recipe.reducer';
import { wallet } from './wallet.reducer';


const rootReducer = combineReducers({
    general, get_long_url, dashboard, item, authentication, customer, payment, order, other, support, recipe, wallet,
    form: formReducer
});

export default rootReducer;