import React, { Component } from 'react';
import { IconButton, AppBar, Toolbar, Button, ListItem, Paper, Link, List, DialogActions, Typography, DialogContent, ListItemText, Switch, ListSubheader, Avatar, colors, Badge, DialogTitle, ListItemButton, Fab, ListItemIcon, InputBase } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import {  renderTextField } from '../../_helpers/CustomFields';
import {  reduxForm, SubmissionError, change,Field,formValueSelector } from 'redux-form'
import { connect } from 'react-redux';
import {customeraction } from '../../_actions/customer.action'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const validate = (values) => {
    const errors = {};
    if (!values.firstname) {
        errors.firstname = 'Please enter first name'
    }
    if (!values.lastname) {
        errors.lastname = 'Please enter last name'
    }
    if (!values.address) {
        errors.address = 'Please enter address'
    }
    if (!values.landmark) {
        errors.landmark = 'Please enter landmark'
    }
    if (!values.area) {
        errors.area = 'Please enter area'
    }
    if (!values.pincode) {
        errors.pincode = 'Please enter pincode'
    }
    if (!values.city) {
        errors.city = 'Please enter city'
    }
    if (!values.state) {
        errors.state = 'Please enter state'
    }
    if (!values.other_address_type) {
        errors.other_address_type = 'Please address type'
    }

    return errors;
}


class AddressSidebar extends React.Component {

    constructor(props) {
        super(props);
        console.log(this.props)
        this.state = {
            Open: true,

        };
    }


    handleClickOpen = () => {
        this.setState({ Open: true });
    };

    handleClose = () => {
        this.setState({ Open: false });
        this.props.handleCloseAddressEdit()
    };

    componentDidMount() {
         this.props.dispatch(customeraction.get_state_list());
        this.props.dispatch(customeraction.get_customer_address_detail(this.props.mode, this.props.address_id));
    }

    onSubmit = (formdata) => {
        this.props.dispatch(customeraction.in_up_customer_address(this.props.mode, formdata));
        this.props.handleCloseAddressEdit()
    }

    render() {
        const { handleSubmit } = this.props

        return (
            <form >
                <Dialog
                    scroll="paper"
                    PaperProps={{
                        style: {
                            position: 'fixed',
                            top: 0,
                            right: 0,
                            margin: 0,
                            bottom: 0,
                            maxHeight: "100%",
                            borderRadius: 0
                        },
                    }}

                    TransitionComponent={Transition}
                    open={this.state.Open}
                    // onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                    fullWidth
                    maxWidth={'xs'}
                >
                    <DialogTitle className='p-0'>
                        <AppBar className='w-100 p-0' sx={{ position: 'relative' }} elevation={0}>
                            <Toolbar>
                                <Typography sx={{ flex: 1, }} className='fw-normal' variant="h6" component="div">
                                    Address
                                </Typography>
                                <IconButton
                                    edge="start"
                                    color="inherit"
                                    onClick={this.handleClose}
                                    aria-label="close"
                                >
                                    <CloseIcon />
                                </IconButton>
                            </Toolbar>
                        </AppBar>
                    </DialogTitle>

                    <DialogContent className='position-relative px-0' dividers>
                        <Typography className='ms-3 mb-3'>Add Address</Typography>
                        <div className='row mx-3'>
                            <div className='col-md-6 pb-3'>
                                <Field
                                    name="firstname"
                                    id="firstname"
                                    type="text"
                                    label="First Name"
                                    placeholder="First Name"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps',maxLength: 10 }}
                            />  
                            </div>
                            <div className='col-md-6 pb-3'>
                                <Field
                                    name="lastname"
                                    id="lastname"
                                    type="text"
                                    label="Last Name"
                                    placeholder="Last Name"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps',maxLength: 10 }}
                                />  
                            </div>

                            <div className='col-md-12 pb-3'>
                                <Field
                                    name="address"
                                    id="address"
                                    type="text"
                                    label="Address"
                                    placeholder="Address"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />  
                            </div>

                            <div className='col-md-12 pb-3'>
                                <Field
                                    name="landmark"
                                    id="landmark"
                                    type="text"
                                    label="Landmark"
                                    placeholder="Landmark"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />  
                            </div>

                            <div className='col-md-6 pb-3'>
                                <Field
                                    name="area"
                                    id="area"
                                    type="text"
                                    label="Area"
                                    placeholder="Area"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />  
                            </div>
                            <div className='col-md-6 pb-3'>
                                <Field
                                    name="city"
                                    id="city"
                                    type="text"
                                    label="City"
                                    placeholder="City"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />
                            </div>
                            <div className='col-md-6 pb-3'>
                                <Field
                                    name="pincode"
                                    id="pincode"
                                    type="text"
                                    label="Pincode"
                                    placeholder="Pincode"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />  
                            </div>
                            <div className='col-md-6 pb-3'>
                                <Field
                                    name="state"
                                    id="state"
                                    type="text"
                                    label="State"
                                    placeholder="State"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />
                            </div>

                            <div className='col-md-12 pb-3'>
                                <Field
                                    name="mobileno"
                                    id="mobileno"
                                    type="text"
                                    label="Alternate Mobile No"
                                    placeholder="Alternate Mobile No"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    inputProps={{ 'aria-label': 'search google maps' }}
                                />
                            </div>
                        </div>

                    </DialogContent>


                    <DialogActions className='d-flex flex-column'>

                        <div className='d-flex w-100'>
                            <Button variant="contained" sx={{ p: 2 }} className='m-1 w-100 fs-6 fw-light' onClick={handleSubmit(values => this.onSubmit({ ...values }))} disableElevation>Save | Update</Button>
                        </div>
                    </DialogActions>

                </Dialog>
            </form>
        );
    }
}

function mapStateToProps(state) {
    let initialValues = {}
    if (state.customer.customer_detail && state.customer.customer_detail.address_id) {
        initialValues = state.customer.customer_detail
    }
    else {

        let firstname = "";
        let lastname = "";
        // if (state.authentication.customer_detail) {
        //     if (state.authentication.customer_detail.firstname && state.authentication.customer_detail.firstname !== "") {
        //         firstname = state.authentication.customer_detail.firstname;
        //     }
        //     if (state.authentication.customer_detail.lastname && state.authentication.customer_detail.lastname !== "") {
        //         lastname = state.authentication.customer_detail.lastname;
        //     }
        // }

        initialValues = {
            customer_id: 0,
            firstname: firstname,
            lastname: lastname,
            address: "",
            landmark: "",
            area: "",
            pincode: "",
            city: "Ahmedabad",
            state: "",
            country: "",
            mobileno: "",
            address_type: "Home",
            other_address_type: "",
        };
    }
    return {
        customer_detail: state.authentication.customer_detail,
        state_list: state.customer.state_list,
        initialValues,
    };
};

const selector = formValueSelector('addressdetail')
AddressSidebar = connect(
    state => {
        const address_type = selector(state, 'address_type')
        return {
            address_type
        }
    }
)(AddressSidebar)


AddressSidebar = reduxForm({
    form: 'addressdetail',
    validate,
    asyncBlurFields: ['pincode'],
    enableReinitialize: true,
})(AddressSidebar)



export default connect(mapStateToProps, null)(AddressSidebar)

