import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { history } from '../../_helpers/history';
import Dialog from '@mui/material/Dialog';
import { DialogContent  } from '@mui/material';

class BannersHome extends Component {

  
  constructor(props) {
    super(props);
    this.state = {
        is_item_dialog_open: false,
        is_video_dialog_open: false,
        text_name: "",
        is_video_dialog_open:""
    }
  }
  handleCloseItemDialog = () => {
    this.setState({
      is_item_dialog_open: false,
        text_name: ""
    })
  }
  handleCloseVideoDialog = () => {
    this.setState({
      is_video_dialog_open: false,
      is_video_dialog_open: ""
    })
  }
  click_image = (event,item) => {
    event.stopPropagation()

    if (item.urlType === "department") {
        history.push("/dep/" + (item.department.department_name).replace(/[^A-Z0-9]/ig, "-").toLowerCase() + "/" + item.department._id);
    }
    else if(item.urlType === "items"){
        history.push("/item/" + (item.item.item_name).replace(/[^A-Z0-9]/ig, "-").toLowerCase() + "/" + item.item.item_id);
    }else if(item.urlType === "collection"){
        history.push("/col/" + (item.collection.collection_name).replace(/[^A-Z0-9]/ig, "-").toLowerCase() + "/" + item.collection.collection_id);
    }else if (item.urlType === "promo") {
        if (item.url !== "" && item.url !== undefined && item.url !== null) {
            window.open(item.url)
        }
    }else if (item.urlType === "text") {
        this.setState({
          is_item_dialog_open: true,
           text_name: item.text_name
       })
    }else if (item.urlType === "video") {
        this.setState({
            is_video_dialog_open: true,
            video_url: item.url
        })
    }
}

  render() {
    var items = this.props.values.imgs;
    return (<>
      <section className='py-3'>
        <div className='container'>
          <div className={`row ${isMobile ? "horizontal-scroll pb-1" : null}`}>
            {items && items.map((item) => (
              <div onClick={(event) => this.click_image(event,item)} className='col-md-4 my-2 product-banners'>
                <a href=''>
                  <img src={item.image_url} alt={item.alterText} className='w-100 border rounded-3 shadow-sm banner-img' />
                </a>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Dialog
          open={this.state.is_item_dialog_open}
          aria-labelledby="item-name-settings"
          maxWidth="xs"
          onClose={this.handleClose}
          fullWidth>
          <DialogContent>
              <div>{this.state.text_name}</div>
          </DialogContent>
      </Dialog>
      <Dialog
          open={this.state.is_video_dialog_open}
          aria-labelledby="item-name-settings"
          maxWidth="md"
          onClose={this.handleClose}
          fullWidth>
          <iframe height={720} frameBorder={0} src={this.state.video_url} ></iframe>
      </Dialog>
      </>
    );
  }
}

export default BannersHome;