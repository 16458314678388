import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import './index.css';
import App from './App';

// import 'bootstrap/dist/css/bootstrap.css';
import './css/main.min.css';
import 'remixicon/fonts/remixicon.css'
import { store } from './_helpers';

const rootElement = document.getElementById('root');

const renderApp = () => {
  ReactDOM.createRoot(rootElement).render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  );
};

renderApp();
