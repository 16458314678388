import React from 'react';
import { connect } from 'react-redux';
import {  reduxForm, SubmissionError, change,Field } from 'redux-form'
import { Divider,  Button, Link, Paper, InputBase, Tooltip, IconButton,InputAdornment } from '@mui/material';
import QuestionFillIcon from 'remixicon-react/QuestionFillIcon';
import Mastercard from '../../assets/mastercard.svg';
import {  renderTextField } from '../../_helpers/CustomFields';


class RazerpayCard extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            card_valid: false,
            expiry_valid: false
        };
    }

    place_order_card = (values) => {

        const errors = {};
        let is_validate = true
        console.log(values)
        if (!values.card_number || this.state.card_valid === false) {
            errors.card_number = 'Please enter valid card no';
            is_validate = false
        }
        if (!values.card_expiry || this.state.expiry_valid === false) {
            errors.card_expiry = 'Please enter valid card expiry';
            is_validate = false
        }
        if (!values.card_cvv) {
            errors.card_cvv = 'Please enter card cvv'
            is_validate = false
        }
        if (!values.card_name) {
            errors.card_name = 'Please enter card name'
            is_validate = false
        }
        if (is_validate === false) {
            throw new SubmissionError(errors)
        }

        var data = {}
        data.method = 'card';
        data['card[name]'] = values.card_name;
        data['card[number]'] = values.card_number;
        data['card[cvv]'] = values.card_cvv;
        data['card[expiry_month]'] = values.card_expiry.split('/')[0].trim();
        data['card[expiry_year]'] = values.card_expiry.split('/')[1].trim();
        this.props.place_order(data)
    }

    componentDidMount() {

        const self = this;

        var getEl = document.getElementById.bind(document);
        var formatter =  new window.Razorpay.setFormatter(getEl('card_payment'));
        var cvvField = getEl('card_cvv');

        formatter.add('card', getEl('card_number'))
            .on('network', function (o) {
                var type = this.type;
                var cvvlen = type === 'amex' ? 4 : 3;
                cvvField.maxLength = cvvlen;
                cvvField.pattern = '^[0-9]{' + cvvlen + '}$';
                document.getElementById("card_type").setAttribute("cardtype", type);
            })
            .on('change', function () {
                self.props.dispatch(change('payment_form', 'card_number', this.prettyValue));
                var isValid = this.isValid();
                if (this.value.length === this.maxLen) {
                    if (isValid) {
                        self.setState({ card_valid: true });
                    }
                    else {
                        self.setState({ card_valid: false });
                    }
                    getEl('card_valid').innerHTML = isValid ? '' : 'Please enter valid card no';
                }
                if (isValid && this.el.value.length === this.caretPosition) {
                    getEl('card_expiry').focus();
                }
            })

        formatter.add('expiry', getEl('card_expiry'))
            .on('change', function () {
                self.props.dispatch(change('payment_form', 'card_expiry', this.prettyValue));
                var isValid = this.isValid();
                if (this.value.length === 4) {
                    if (isValid) {
                        self.setState({ expiry_valid: true });
                    }
                    else {
                        self.setState({ expiry_valid: false });
                    }
                    getEl('expiry_valid').innerHTML = isValid ? '' : 'Please enter valid card expiry';
                }
                if (isValid && this.el.value.length === this.caretPosition) {
                    getEl('card_cvv').focus();
                }
            })
    }

    render() {

        const { handleSubmit } = this.props;
        return (
            <form className="col-md-12" id='card_payment'>
                <div className='card-body py-3 bg-transparent card_payment'>
                    <div className='row'>
                        <div className='col-md-7 mb-3'>
                            <Field
                                name="card_number"
                                id="card_number"
                                type="text"
                                label="Card Number"
                                placeholder="Card Number"
                                autoFocus
                                sx={{ flex: 1 }}
                                className='w-100'
                                variant="filled"
                                component={renderTextField}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: <InputAdornment position="end">
                                        <div id="card_type" className="cardtype" cardtype="" >
                                            <img src={Mastercard} width={24} alt=''/>
                                        </div>
                                    </InputAdornment>,
                                    'aria-label': 'search google maps'
                                }}
                            />
                        </div>

                        <div className='col-md-5 mb-3'>
                            <Field
                                name="card_expiry"
                                id="card_expiry"
                                type="text"
                                label="(MM/YY)"
                                placeholder="(MM/YY)"
                                autoFocus
                                className='w-100'
                                variant="filled"
                                component={renderTextField}
                                sx={{ flex: 1 }}
                                inputProps={{ 'aria-label': 'search google maps' }}
                            />  
                        </div>
                        <div className='col-md-6 mb-3'>
                                <Field
                                    name="card_cvv"
                                    id="card_cvv"
                                    type="password"
                                    label="Security code"
                                    autoFocus
                                    className='w-100'
                                    variant="filled"
                                    component={renderTextField}
                                    sx={{ flex: 1 }}
                                    placeholder="CVV"
                                    inputProps={{ 'aria-label': 'search google maps', maxLength: 3 }}
                                    InputProps={{
                                        disableUnderline: true,
                                        endAdornment: <Tooltip title="The CVV number is the last three digits on the back of your card">
                                        <IconButton>
                                            <QuestionFillIcon />
                                        </IconButton>
                                        </Tooltip>,
                                         maxLength: 3,
                                        'aria-label': 'search google maps'
                                    }}
                                />
                            </div>
                        <div className='col-md-6 mb-3'>
                            <Field
                                name="card_name"
                                id="card_name"
                                type="text"
                                label="Card Holder's Name"
                                component={renderTextField}
                                sx={{ flex: 1 }}
                                className='w-100'
                                variant="filled"
                                placeholder="Card Holder’s Name"
                                autoFocus
                                inputProps={{ 'aria-label': 'search google maps' }}
                            />
                        </div>
                    </div>
                    <Button variant="contained" sx={{ p: 2 }} onClick={handleSubmit(values => this.place_order_card({ ...values }))} className='fw-light w-100' disableElevation>Place Order & Pay ₹{this.props.total_amount}</Button>
                </div>
            </form>
        )
    }
}

function mapStateToProps(state) {
    return {
        total_amount: state.payment.total_amount,
    };
}

RazerpayCard = reduxForm({
    form: 'payment_form',
    destroyOnUnmount: false,
})(RazerpayCard);


export default connect(mapStateToProps, null)(RazerpayCard);

