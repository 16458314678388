import { ItemConstants } from '../_constants/item.constants';
import { API_URL, showErrorMessage } from './utility.action'
import { history } from '../_helpers/history';

export const itemaction = {
    get_item_list, get_item_detail, get_item_cart, add_remove_item_cart,
    get_product_search, get_product_cart, pin_unpin_cart, get_customer_item_list,
    select_favorite, variant_change, get_item_detail_incart_edit,dashboard_get_item_list,clear_item_list,open_cart_view
};

function get_item_list(type, department_id) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = {
        "access_token": access_token, "customer_access_token": customer_access_token,
        "type": type, "department_id": department_id
    }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/product_service/get_item_list`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: ItemConstants.GET_ITEM_LIST_REQUEST } }
    function success(payload) { return { type: ItemConstants.GET_ITEM_LIST_SUCCESS, payload } }
    function failure(payload) { return { type: ItemConstants.GET_ITEM_LIST_FAILURE, payload } }
}

function get_item_detail(item_id,route_address=null) {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token, "item_id": item_id,route_address:route_address}

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/product_service/get_item_detail`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: ItemConstants.GET_ITEM_DETAIL_REQUEST } }
    function success(payload) { return { type: ItemConstants.GET_ITEM_DETAIL_SUCCESS, payload } }
    function failure(payload) { return { type: ItemConstants.GET_ITEM__DETAIL_FAILURE, payload } }
}

function get_item_cart() {
    return {
        type: ItemConstants.GET_ITEM_CART,
    };
}

function add_remove_item_cart(type, data) {
    return {
        type: ItemConstants.ADD_REMOVE_ITEM_CART,
        action_type: type, data: data
    };
}

function variant_change(item_id, variant_id) {
    return {
        type: ItemConstants.VARIANT_CHANGE,
        item_id: item_id, variant_id: variant_id
    };
}

function get_product_search(searchtext) {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token, "searchtext": searchtext }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/product_service/get_product_search`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: ItemConstants.GET_PRODUCT_SEARCH_REQUEST } }
    function success(payload) { return { type: ItemConstants.GET_PRODUCT_SEARCH_SUCCESS, payload } }
    function failure(payload) { return { type: ItemConstants.GET_PRODUCT_SEARCH_FAILURE, payload } }
}

function get_product_cart() {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    var cart = JSON.parse(localStorage.getItem('cart'));

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token, "cart": cart }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/order_service/get_cart_payment`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: ItemConstants.GET_PRODUCT_CART_REQUEST } }
    function success(payload) { return { type: ItemConstants.GET_PRODUCT_CART_SUCCESS, payload } }
    function failure(payload) { return { type: ItemConstants.GET_PRODUCT_CART_FAILURE, payload } }
}

function pin_unpin_cart(type) {
    return {
        type: ItemConstants.PIN_UNPIN_CART,
    };
}

function open_cart_view(is_open_cart_view) {
    return {
        type: ItemConstants.OPEN_CART_VIEW,
        is_open_cart_view,
    };
}


function get_customer_item_list() {


    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        fetch(`${API_URL}/customer_service/get_customer_item_list`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                }
                else {
                    var cart = [];
                    response.data.customer_item_list.forEach(data => {
                        if (data.inventory > 0) {
                            cart.push({
                                "item_id": data.item_id, "item_name": data.item_name, "feature_image": data.feature_image, "item_sku": data.item_sku,
                                "variant_id": data.variant_id, "variant_label": data.variant_label, "selection_by": data.selection_by, "variant_sku": data.variant_sku,
                                "value": data.value, "unit": data.unit, "mrp": data.mrp, "rate": data.rate, "qty": data.qty, "amount": data.rate * data.qty,
                                "inventory": data.inventory, "is_masterrate": data.is_masterrate, "master_value": data.master_value, "master_unit": data.master_unit,
                                "master_mrp": data.master_mrp, "master_rate": data.master_rate, "is_custom_weight": data.is_custom_weight
                            })
                        }
                    })
                    localStorage.setItem('cart', JSON.stringify(cart));
                    dispatch(add_remove_item_cart("", {}))
                }
            });
    };
}

function select_favorite(data) {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = { "access_token": access_token, "customer_access_token": customer_access_token, "data": data }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/customer_service/add_update_customer_item_list`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: ItemConstants.SELECT_FAVORITE_REQUEST } }
    function success(payload) { return { type: ItemConstants.SELECT_FAVORITE_SUCCESS, payload, data } }
    function failure(payload) { return { type: ItemConstants.SELECT_FAVORITE_FAILURE, payload } }
}

function get_item_detail_incart_edit(item_id) {

    var access_token = localStorage.getItem('access_token')

    const request_data = { "access_token": access_token, "item_id": item_id }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/product_service/get_item_detail`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: ItemConstants.GET_ITEM_DETAIL_INCART_EDIT_REQUEST } }
    function success(payload) { return { type: ItemConstants.GET_ITEM_DETAIL_INCART_EDIT_SUCCESS, payload } }
    function failure(payload) { return { type: ItemConstants.GET_ITEM_DETAIL_INCART_EDIT_FAILURE, payload } }
}

function dashboard_get_item_list() {

    var access_token = localStorage.getItem('access_token')
    var customer_access_token = localStorage.getItem('customer_access_token')

    const request_data = {
        "access_token": access_token, "customer_access_token": customer_access_token,
    }

    const requestOptions = {
        method: 'POST',
        body: JSON.stringify(request_data),
        headers: { 'Content-Type': 'application/json' }
    };

    return dispatch => {
        dispatch(request());
        fetch(`${API_URL}/product_service/dashboard_get_item_list`, requestOptions)
            .then(response => response.json())
            .then(function (response) {
                if (response.error_code === "0") {
                    dispatch(failure(response))
                }
                else {
                    dispatch(success(response))
                }
            });
    };

    function request() { return { type: ItemConstants.GET_ITEM_LIST_REQUEST } }
    function success(payload) { return { type: ItemConstants.DASHBOARD_GET_ITEM_LIST_SUCCESS, payload } }
    function failure(payload) { return { type: ItemConstants.GET_ITEM_LIST_FAILURE, payload } }
}

function clear_item_list() {
    return {
        type: ItemConstants.CLEAR_ITEM_LIST,
    };
}
