import { WalletConstants } from '../_constants/wallet.constants';

export function wallet(state = {}, action) {

    switch (action.type) {
        case WalletConstants.GET_CUSTOMER_WALLET_BALANCE_REQUEST:
            return {
                ...state,
                wallet_data: null,
            };
        case WalletConstants.GET_CUSTOMER_WALLET_BALANCE_SUCCESS:
            return {
                ...state,
                wallet_data: action.payload.data,
            };
        case WalletConstants.GET_CUSTOMER_WALLET_BALANCE_FAILURE:
            return {
                ...state,
                wallet_data: null,
            };
        case WalletConstants.SEND_MOBILENO_OTP_REQUEST:
            return {
                ...state,
                request_id: null,
                error_description: null
            };
        case WalletConstants.SEND_MOBILENO_OTP_SUCCESS:
            return {
                ...state,
                request_id: action.payload.data.request_id,
            };
        case WalletConstants.SEND_MOBILENO_OTP_FAILURE:
            return {
                ...state,
                request_id: null,
            };
        case WalletConstants.GET_CUSTOMER_ADD_MONEY_REQUEST:
            return {
                add_money_data: null
            };
        case WalletConstants.GET_CUSTOMER_ADD_MONEY_SUCCESS:
            return {
                ...state,
                add_money_data: action.payload.data,
            };
        case WalletConstants.GET_CUSTOMER_ADD_MONEY_FAILURE:
            return {
                ...state,
            };
        default:
            return state
    }
}






